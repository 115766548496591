/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ProtocolTypes,
} from 'ajs/modules/network/factories/network-profile/network-profile.item.types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';

import {
    NetworkProfileCollection,
} from 'ajs/modules/network/factories/network-profile/network-profile.collection.factory';

import {
    NetworkProfile,
} from 'ajs/modules/network/factories/network-profile/network-profile.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './network-profiles-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TNetworkProfileCollection = typeof NetworkProfileCollection;

/**
 * @description Network Profiles component to show all profiles.
 * @author Vijay
 */
@Component({
    selector: 'network-profiles-list-page',
    templateUrl: './network-profiles-list-page.component.html',
})
export class NetworkProfilesListPageComponent implements OnInit, OnDestroy {
    /**
     * Configuration to display Network Profiles.
     */
    public networkProfilesGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * NetworkProfileCollection instance.
     */
    private readonly networkProfileCollection: NetworkProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        @Inject(NetworkProfileCollection)
        NetworkProfileCollection: TNetworkProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.networkProfileCollection = new NetworkProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.networkProfileCollection;

        this.networkProfilesGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.networkProfileCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: NetworkProfile): string => row.getName(),
                },
                {
                    id: 'type',
                    label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                    transform: (row: NetworkProfile): string => this.schemaService
                        .getEnumValueLabel('ProtocolType', row.getType() as unknown as string),
                },
                {
                    id: 'auto-learn',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleAutoLearn),
                    transform: (row: NetworkProfile): string => {
                        if (row.getType() === ProtocolTypes.PROTOCOL_TYPE_TCP_PROXY &&
                            row.getConfig().profile.config.tcp_proxy_profile.config.automatic) {
                            return this.l10nService.getMessage(globalL10nKeys.yesValueLabel);
                        }
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.networkProfileCollection.destroy();
    }
}
