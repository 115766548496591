/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './waf-top-hits-logs-list-modal.less';
import * as l10n from './waf-top-hits-logs-list-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc service
 * @name wafTopHitsLogsListModal
 * @param {Object} filtersHash
 * @param {VirtualService.id} vsId
 * @param {Function} closeModal
 * @description
 *
 *     Modal window with list of logs matching passed filters.
 */
const componentName = 'waf-top-hits-logs-list-modal';

class WafTopHitsLogsListModalController {
    constructor(
        $element,
        $filter,
        $state,
        $stateParams,
        myAccount,
        LogRecordCollection,
        VsWafTopHitsDataSource,
        l10nService,
    ) {
        this.LogRecordCollection_ = LogRecordCollection;
        this.$element_ = $element;
        this.$state_ = $state;
        this.myAccount = myAccount;
        this.$stateParams = $stateParams;
        this.filterPropsOrder = VsWafTopHitsDataSource.propertiesOrder;
        this.filterPropsLabels = VsWafTopHitsDataSource.propertyLabelsByName;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        const enumFilter = $filter('enum');

        this.gridConfig = {
            fields: [{
                name: 'timestamp',
                title: l10nService.getMessage(l10nKeys.columnTitleTimestamp),
                template: '{{row.getConfig().report_timestamp | noYearTime}}',
                sortBy: 'report_timestamp',
            }, {
                name: 'waf_entry_type',
                title: 'WAF',
                transform(record) {
                    const { waf_log: wafLog } = record.getConfig();

                    return wafLog ? enumFilter(wafLog['status']) : '-';
                },
            }, {
                name: 'client_ip',
                title: l10nService.getMessage(l10nKeys.columnTitleClientIp),
                template: '{{row.getConfig().client_ip}}',
                sortBy: 'client_ip',
            }, {
                name: 'uri_path',
                title: l10nService.getMessage(l10nKeys.columnTitlePath),
                transform(record) {
                    const { uri_path: uriPath, uri_query: uriQuery } = record.getConfig();

                    let fullUrl = uriPath;

                    if (uriQuery) {
                        fullUrl += `?${uriQuery}`;
                    }

                    fullUrl = _.escape(fullUrl);

                    return `<div class="hide-text-overflow" title="${fullUrl}">${fullUrl}</div>`;
                },
                sortBy: 'uri_path',
            }, {
                name: 'req_method',
                title: l10nService.getMessage(l10nKeys.columnTitleRequest),
                template: '{{row.getConfig().method}}',
                sortBy: 'method',
            }, {
                name: 'resp_code',
                title: l10nService.getMessage(l10nKeys.columnTitleResponse),
                template: '{{row.getConfig().response_code}}',
                sortBy: 'response_code',
            }, {
                name: 'resp_length',
                title: l10nService.getMessage(l10nKeys.columnTitleLength),
                template:
                        '<avi-value value="row.getConfig().response_length"' +
                            ' type="bytes"></avi-value>',
                sortBy: 'response_length',
            }, {
                name: 'duration',
                title: l10nService.getMessage(l10nKeys.columnTitleDuration),
                template:
                        '<log-timeline record="row.getConfig()" is-http-vs="true" ' +
                            ' timeline="config.collection.timeline"' +
                            ' z-index="config.data.zIndex"></log-timeline>',
                sortBy: 'total_time',
            }, {
                name: 'total_time',
                title: l10nService.getMessage(l10nKeys.columnTitleTotalTime),
                template:
                        '<avi-value value="row.getConfig().total_time"' +
                            ' type="duration"></avi-value>',
                sortBy: 'total_time',
            }],
            layout: {
                hideSearch: true,
                hideEditColumns: true,
            },
            data: {
                zIndex: 0, //needed for logTimeline popover
            },
            defaultSorting: '-report_timestamp',
        };
    }

    $onInit() {
        this.$element_.addClass(componentName);
        this.gridConfig.data.zIndex = this.$element_.css('zIndex');

        const logCollection = new this.LogRecordCollection_({
            vsId: this.vsId,
            params: {
                filter: [
                        'eq(waf_log.status,[REJECTED,FLAGGED])',
                        'ne(waf_log.rule_logs.matches.is_internal, true)',
                ],
            },
        });

        logCollection.setFilter(this.filtersHash);

        this.logCollection = logCollection;

        this.gridConfig.collection = logCollection;

        this.gridConfig.id = componentName;

        this.orderedFilters = [];

        this.filterPropsOrder.forEach(propName => {
            if (propName in this.filtersHash) {
                this.orderedFilters.push({
                    label: this.filterPropsLabels[propName],
                    value: this.filtersHash[propName],
                });
            }
        });
    }

    /**
     * Closes modal window and redirects user to 'logs' page of the same VS.
     * @public
     */
    goToLogsPage() {
        const url = 'authenticated.application.virtualservice-detail.vs-logs';
        const { vsId } = this;
        const stateParams = {
            initFilterParams: this.filtersHash,
            vsId,
        };

        // TODO 171388 Enable for 30.1.1, once toggle reinstated for 30.1.1
        /**
         * If not new logs, then pass filter params through URL.
         */
        // if (!this.myAccount.uiProperty[SHOW_NEW_VS_LOGS_PAGE]) {
        //     delete stateParams.initFilterParams;
        //     stateParams.search = this.getLogsPageSearchParamValue_();
        // }

        this.$state_.go(url, stateParams)
            .then(this.closeModal);
    }

    // TODO 171338 delete as part of 168804 from eng once 30.1.1 has tech preview toggle
    /**
     * Generates string to be passed as "search" query param to logListController.
     * @returns {string}
     * @private
     */
    //TODO this code should be shared between this component and log list component
    getLogsPageSearchParamValue_() {
        const filtersList = [];

        _.each(this.filtersHash, (value, fieldName) => {
            let entry = `${fieldName}=`;

            if (fieldName === 'waf_log.rule_logs.rule_id') { //numeric type
                entry += `${value}`;
            } else {
                entry += `"${value}"`;
            }

            filtersList.push(entry);
        });

        return JSON.stringify(filtersList);
    }

    $onDestroy() {
        this.logCollection.destroy();
    }
}

WafTopHitsLogsListModalController.$inject = [
    '$element',
    '$filter',
    '$state',
    '$stateParams',
    'myAccount',
    'LogRecordCollection',
    'VsWafTopHitsDataSource',
    'l10nService',
];

angular.module('aviApp').component('wafTopHitsLogsListModal', {
    controller: WafTopHitsLogsListModalController,
    bindings: {
        filtersHash: '<',
        vsId: '<',
        closeModal: '&',
    },
    templateUrl: `src/components/modals/${componentName}/${componentName}.html`,
});
