/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module LogsModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import {
    VsLogsWafExceptionDialogComponent,
// eslint-disable-next-line max-len
} from 'ng/modules/logs/components/vs-logs-waf-exception-dialog/vs-logs-waf-exception-dialog.component';

import {
    WafExcludeListEntryConfigItem,
} from 'ajs/modules/waf/factories/waf-exclude-list-entry.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { DialogService } from 'ng/modules/core/services/dialog.service';
import { WafPolicyItem } from 'ajs/modules/waf/factories/waf-policy/waf-policy.item.factory';
import { VsLogsStore } from 'ng/modules/vs-logs/services/vs-logs.store';

/**
 * ID for vs logs waf exception dialog.
 */
const WAF_EXCEPTIONS_DIALOG_ID = 'waf-exceptions-dialog';

export const enum ExceptionType {
    GROUP = 'group',
    RULE = 'rule',
}

interface IWafException {
    ruleGroup: string,
    ruleName?: string,
    ruleId?: number,
    existingExceptions: Array<{
        uriPath?: string;
        matchElement?: string;
        subnet?: string;
    }>,
}

/**
 * @description Service to open/close the Waf Exceptions dialog.
 * @author Shanmukha Sarath Kondiparthi
 */
@Injectable()
export class VsLogsWafExceptionDialogService {
    /**
     * Waf exception info for exception Modal view.
     */
    public wafException: IWafException;

    /**
     * Waf exclude list for WAF rule or WAF rule group.
     */
    public wafExcludeList: RepeatedMessageItem<WafExcludeListEntryConfigItem>;

    /**
     * Error received from backend, post exception is added.
     */
    public error: string | null;

    /**
     * Waf Policy fetched from vs logs store.
     */
    private wafPolicyRefData: WafPolicyItem;

    constructor(
        private readonly dialogService: DialogService,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        this.vsLogsStore.wafPolicy$.subscribe(
            (wafPolicyRefData: WafPolicyItem) => {
                this.wafPolicyRefData = wafPolicyRefData;
            },
        );
    }

    /**
     * Saving waf policy on clicking save button.
     */
    public saveWafExceptions(uriPath: string, matchElement: string, subnet: string): void {
        this.error = null;
        this.wafExcludeList.add();

        const wafExcludeListEntry = this.wafExcludeList.at(this.wafExcludeList.count - 1);

        wafExcludeListEntry.config.uri_path = uriPath;
        wafExcludeListEntry.config.match_element = matchElement;
        wafExcludeListEntry.clientSubnet.subnet = subnet;

        this.wafPolicyRefData.save()
            .then(() => this.closeDialog())
            .catch(rsp => {
                this.error = rsp.data?.error;
                this.wafExcludeList.remove(this.wafExcludeList.count - 1);
            });
    }

    /**
     * Method to open add-exception dialog.
     */
    public openDialog(exception: ExceptionType, ruleGroup: string, ruleId?: number): void {
        this.setWafExcludeList(exception, ruleGroup, ruleId);
        this.setWafException(ruleGroup, ruleId);

        this.dialogService.add({
            id: WAF_EXCEPTIONS_DIALOG_ID,
            component: VsLogsWafExceptionDialogComponent as Type<Component>,
            componentProps: {
                onConfirm: () => {
                    this.closeDialog();
                },
                onClose: () => {
                    this.closeDialog();
                },
            },
        });
    }

    /**
     * Setter for waf exception info in waf add-exception dialog.
     */
    private setWafException(ruleGroup: string, ruleId?: number): void {
        const identifier = ruleId ?? ruleGroup;
        const ruleName = ruleId !== undefined ?
            this.wafPolicyRefData.getRuleNameByRuleId(identifier.toString(), false) :
            undefined;

        const existingExceptions = this.wafExcludeList.config.map(
            (excludeListItem: WafExcludeListEntryConfigItem) => {
                const {
                    uri_path: uriPath,
                    match_element: matchElement,
                } = excludeListItem.config ?? {};

                const {
                    ip_addr: ipAddr,
                    mask,
                } = excludeListItem.config.client_subnet?.config.flatProps ?? {};

                return {
                    uriPath,
                    matchElement,
                    subnet: ipAddr?.addr && mask ? `${ipAddr?.addr}/${mask}` : undefined,
                };
            },
        );

        this.wafException = {
            existingExceptions,
            ruleGroup,
            ruleId,
            ruleName,
        };
    }

    /**
     * Setter for existing waf exclude list items.
     */
    private setWafExcludeList(
        exceptionType: ExceptionType,
        ruleGroup: string,
        ruleId?: number,
    ): void {
        const identifier = ruleId ?? ruleGroup;

        this.wafExcludeList = this.wafPolicyRefData.getConfigItemById(
            exceptionType, identifier.toString(),
        ).excludeList;
    }

    /**
     * Method to close add-exception dialog.
     */
    private closeDialog(): void {
        this.dialogService.remove(WAF_EXCEPTIONS_DIALOG_ID);
    }
}
