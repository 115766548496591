/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';

import { ConnectedPosition } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { DialogService } from 'ng/modules/core';
import { CportalService } from 'ajs/modules/cportal';
import { L10nService } from '@vmw/ngx-vip';
import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { LicenseTierType } from 'generated-types';
import { GenerateSsoTokenDialogComponent } from '../../generate-sso-token-dialog';
import * as l10n from './user-menu-additional-menu.l10n';
import './user-menu-additional-menu.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const GENERATE_SSO_TOKEN_DIALOG_ID = 'GENERATE_SSO_TOKEN_DIALOG_ID';
const PORTAL_URL_VMW_MODE = '/login?authmode=vmw';

/**
 * @desc Component for the user menu additional menu.
 * @author alextsg
 */
@Component({
    selector: 'user-menu-additional-menu',
    templateUrl: './user-menu-additional-menu.component.html',
})
export class UserMenuAdditionalMenuComponent implements OnInit, OnDestroy {
    /**
     * Portal URL for the user to navigate to.
     */
    public aviPortalUrl = '';

    /**
     * Additional menu dropdown/tooltip position strategy.
     */
    public menuPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        },
    ];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Observable to allow subscriptions to show and hide menu.
     */
    public additionalMenuControl$: Observable<boolean>;

    /**
     * Subject to control additional menu.
     */
    private readonly additionalMenuControlSubject = new Subject<boolean>();

    constructor(
        private readonly cportalService: CportalService,
        private readonly dialogService: DialogService,
        l10nService: L10nService,
        @Inject('systemInfoService')
        private readonly systemInfoService: SystemInfoService,
        private readonly devLoggerService: DevLoggerService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.additionalMenuControl$ = this.additionalMenuControlSubject.asObservable();
    }

    /** @override */
    public ngOnInit(): void {
        this.setPortalUrl();
    }

    /**
     * Opens the Generate SSO Token dialog.
     */
    public openGenerateTokenDialog(): void {
        this.closeMenu();

        this.dialogService.add({
            id: GENERATE_SSO_TOKEN_DIALOG_ID,
            component: GenerateSsoTokenDialogComponent as Type<Component>,
            componentProps: {
                onClose: () => {
                    this.dialogService.remove(GENERATE_SSO_TOKEN_DIALOG_ID);
                },
            },
        });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeMenu();

        if (this.dialogService.has(GENERATE_SSO_TOKEN_DIALOG_ID)) {
            this.dialogService.remove(GENERATE_SSO_TOKEN_DIALOG_ID);
        }
    }

    /**
     * Fetch the portal URL used as a hyperlink.
     * Append authmode as 'VMW' to portal_url for 'ENTERPRISE', 'BASIC'
     * and 'ESSENTIALS' LicenseTierType.
     * For 'ENTERPRISE_WITH_CLOUD_SERVICES' the portal_url remains the same
     * as configured in Albservicesconfig.
     */
    private async setPortalUrl(): Promise<void> {
        try {
            const portalInfo = await this.cportalService.getPortalInfo();
            const { defaultLicenseTier } = this.systemInfoService;

            this.aviPortalUrl = portalInfo.portal_url;

            if (defaultLicenseTier !== LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES) {
                this.aviPortalUrl += PORTAL_URL_VMW_MODE;
            }
        } catch (e) {
            this.devLoggerService.error(e);
        }
    }

    /**
     * Called to close the dropdown menu.
     */
    private closeMenu(): void {
        this.additionalMenuControlSubject.next(false);
    }
}
