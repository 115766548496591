/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { OauthProvider } from 'generated-types';

/**
 * Data format from initial-data API.
 */
export interface IControllerInitialDataApiResponse {
    banner: string;
    current_time: string;
    email_configuration_is_set: boolean;
    error_message: string;
    is_aws_cloud: boolean;
    oauth_provider: OauthProvider;
    redirect_to_oauth_provider: boolean;
    redirect_uri: string;
    setup_failed: boolean;
    sso: boolean;
    sso_logged_in: boolean;
    user_initial_setup: boolean;
    version?: {
        build: number;
        Date: string;
        min_version: string;
        patch?: string;
        Product: string;
        ProductName: string;
        Tag: string;
        Version: string;
    };
    welcome_workflow_complete: boolean;
}

/**
 * Flattened data from initial-data API.
 */
export interface IControllerInitialDataState {
    banner: string;
    current_time: string;
    email_configuration_is_set: boolean;
    error_message: string;
    is_aws_cloud: boolean;
    setup_failed: boolean;
    sso: boolean;
    sso_logged_in: boolean;
    user_initial_setup: boolean;
    build: number;
    date: string;
    min_version: string;
    patch: string;
    product: string;
    product_name: string;
    tag: string;
    version: string;
    welcome_workflow_complete: boolean;
}

/**
 * Initial data initialization, for reducer.
 */
export const controllerInitialDataState: IControllerInitialDataState = {
    banner: '',
    current_time: '',
    email_configuration_is_set: false,
    error_message: '',
    is_aws_cloud: false,
    setup_failed: false,
    sso: false,
    sso_logged_in: false,
    user_initial_setup: false,
    build: 0,
    date: '',
    min_version: '',
    patch: '',
    product: '',
    product_name: '',
    tag: '',
    version: '',
    welcome_workflow_complete: false,
};
