/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NetworkModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource, ProtocolType } from 'generated-types';
import { NETWORK_PROFILE_ITEM_TOKEN } from './network-profile.tokens';

/**
 * @description NetworkProfile collection class.
 *
 * @author Ratan Kumar, Shibam Biswas
 */
export class NetworkProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'networkprofile',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_NETWORKPROFILE,
            defaults: {
                profile: {
                    type: ProtocolType.PROTOCOL_TYPE_TCP_PROXY,
                },
            },
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(NETWORK_PROFILE_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            profile: {
                type: ProtocolType.PROTOCOL_TYPE_TCP_PROXY,
            },
        };
    }
}

NetworkProfileCollection.ajsDependencies = [
    NETWORK_PROFILE_ITEM_TOKEN,
];
