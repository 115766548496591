/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    FilterOperatorType,
    IVsLogsGroupbySignificanceResultsData,
    IVsLogsSignpostUpdatableParams,
} from 'ng/modules/vs-logs/vs-logs.types';

import {
    customTemplates,
    defaultSignpostParams,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import { defaultPageSize } from 'ng/modules/vs-logs/constants/default-values.constants';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid';
import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import * as l10n from './vs-logs-signpost-custom-significance.l10n';
import './vs-logs-signpost-custom-significance.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Signpost component for significance section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-significance',
    templateUrl: './vs-logs-signpost-custom-significance.component.html',
})
export class VsLogsSignpostCustomSignificanceComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for name column.
     */
    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for column progress bar.
     */
    @ViewChild('progressBarFieldTemplateRef')
    public progressBarFieldTemplateRef: TemplateRef<HTMLElement>;

    public significanceGroupsGridConfig: IAviDataGridConfig;

    public readonly FilterOperatorType = FilterOperatorType;

    public readonly l10nKeys = l10nKeys;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const fullSignpostParams = this.getSignificanceRequestParams();

        this.vsLogsSignpostStore.getVsLogSignpostData(fullSignpostParams);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;
        const configKey = customTemplates.significance;

        this.significanceGroupsGridConfig = {
            layout: {
                hidePagination: true,
                hideCheckboxes: true,
                showFooter: false,
            },
            fields: [
                {
                    label: templatesConfiguration[configKey].name,
                    id: 'name',
                    templateRef: this.nameFieldTemplateRef,
                    expandedTemplateRef: this.nameFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(
                        l10nKeys.logsNumberLabel,
                    ),
                    id: 'logs',
                    transform: (
                        row: IVsLogsGroupbySignificanceResultsData,
                    ): number => row.count,
                    expandedTransform: (
                        row: IVsLogsGroupbySignificanceResultsData,
                    ): number => row.count,
                },
                {
                    label: l10nService.getMessage(l10nKeys.logsPercentageLabel),
                    id: 'percentage',
                    transform: (
                        row: IVsLogsGroupbySignificanceResultsData,
                    ): string => `${row.percentage}%`,
                    expandedTransform: (
                        row: IVsLogsGroupbySignificanceResultsData,
                    ): string => `${row.percentage}%`,
                },
                {
                    label: '',
                    id: 'progress-bar',
                    templateRef: this.progressBarFieldTemplateRef,
                    expandedTemplateRef: this.progressBarFieldTemplateRef,
                },
            ],
            isExpandedContentColumnBased: true,
        };
    }

    /**
     * Get request params for significance signpost.
     */
    private getSignificanceRequestParams(): IVsLogsSignpostUpdatableParams {
        const template = templatesConfiguration[customTemplates.significance];
        const { queryParams: groupby, page_size: pageSize } = template;

        const fullSignpostParams: IVsLogsSignpostUpdatableParams = {
            ...defaultSignpostParams,
            groupby,
            page_size: pageSize || defaultPageSize,
        };

        return fullSignpostParams;
    }
}
