/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { L10nService } from '@vmw/ngx-vip';

import {
    ApplicationProfileCollection,
} from 'ajs/modules/application-profile/factories/application-profile.collection.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './application-profile-list-page.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TApplicationProfileCollection = typeof ApplicationProfileCollection;

/**
 * @description Application profile's list page.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'application-profile-list-page',
    templateUrl: './application-profile-list-page.component.html',
})
export class ApplicationProfileListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the application profile collection.
     */
    public readonly applicationProfileCollection: ApplicationProfileCollection;

    /**
     * Configuration object to display application profile collection.
     */
    public applicationProfileGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        public readonly schemaService: SchemaService,
        @Inject(ApplicationProfileCollection)
        ApplicationProfileCollection: TApplicationProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.applicationProfileCollection = new ApplicationProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.applicationProfileCollection;

        this.applicationProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.applicationProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }, {
                id: 'type',
                label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                transform: row => this.schemaService.getEnumValueLabel(
                    'ApplicationProfileType',
                    row.getConfig().type,
                ),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.applicationProfileCollection.destroy();
    }
}
