/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NetworkModule
 */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import { InfraCloudState } from 'ajs/modules/vrf-context/services/infra-cloud-state.service';
import { Cloud } from 'ajs/modules/cloud/factories/cloud.item.factory';

import {
    ConfiguredNetworkCollection,
} from 'ajs/modules/network/factories/configured-network.collection.factory';

import {
    ConfiguredNetwork,
    IConfiguredNetworkSubnet,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';

import {
    CloudType,
    IVIMgrIPSubnetRuntime,
} from 'generated-types';

import * as globalL10n from 'global-l10n';
import * as l10n from './network-list-page.l10n';
import './network-list-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TConfiguredNetwork = typeof ConfiguredNetwork;
type TNetworkCollection = typeof ConfiguredNetworkCollection;

/**
 * @description Component for the Network list page.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'network-list-page',
    templateUrl: './network-list-page.component.html',
})
export class NetworkListPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection Data Grid config for Network List items.
     */
    public networkListGridConfig: IAviCollectionDataGridConfig;

    /**
     * ConfiguredNetworkCollection instance.
     */
    public networkCollection: ConfiguredNetworkCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly infraCloudState: InfraCloudState,
        @Inject(ConfiguredNetworkCollection)
        NetworkCollection: TNetworkCollection,
        @Inject(ConfiguredNetwork)
        private readonly ConfiguredNetwork: TConfiguredNetwork,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const cloud: Cloud = this.infraCloudState.getCloud();
        const { id: slug } = cloud;
        const vtype = cloud.getVtype();

        this.networkCollection = new NetworkCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
            defaults: {
                cloud_ref: cloud.getRef(),
            },
            cloudType: vtype,
        });
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.networkCollection;

        this.networkListGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.networkCollection,
            fields: [],
            defaultSorting: 'name',
            layout: {
                hideCreate: false,
                hideEdit: false,
            },
        };
        this.infraCloudState.on(
            'cloudChange',
            () => {
                this.networkCollection.setParams({
                    'cloud_ref.uuid': this.infraCloudState.getCloud().id,
                });
                this.networkCollection.setDefaultItemConfigProps({
                    cloud_ref: this.infraCloudState.getCloudRef(),
                });
                this.setNetworkListGridConfigFields();
                this.networkCollection.load();
            },
        );
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.networkListGridConfig.expandedContentTemplateRef = this.expandedContentTemplateRef;

        this.networkListGridConfig.expanderDisabled = (row: ConfiguredNetwork) => {
            const { configuredSubnets } = row;

            return !configuredSubnets.length;
        };

        this.setNetworkListGridConfigFields();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.networkCollection.destroy();
    }

    /**
     * Return the selected cloud type.
     */
    private getSelectedCloudType(): string {
        const cloud: Cloud = this.infraCloudState.getCloud();

        return cloud.getVtype();
    }

    /**
     * Set Network List Grid Config fields.
     */
    private setNetworkListGridConfigFields(): void {
        const fields = [
            {
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: ConfiguredNetwork): string => {
                    return row.getName();
                },
            }, {
                id: 'disc-subnets',
                label: this.l10nService.getMessage(l10nKeys.columnTitleDiscoveredSubnets),
                transform: (row: ConfiguredNetwork): string => {
                    const { exclude_discovered_subnets: excludeDiscoveredSubnets } =
                        row.getConfig();

                    if (excludeDiscoveredSubnets) {
                        return this.l10nService.getMessage(l10nKeys.excludedLabel);
                    }

                    const { discovery } = row.data;

                    if (discovery?.ip_subnet?.length) {
                        return discovery.ip_subnet
                            .map((s: IVIMgrIPSubnetRuntime) => getSubnetString(s.prefix))
                            .join(', ');
                    } else {
                        return this.l10nService.getMessage(globalL10nKeys.emptyLabel);
                    }
                },
            }, {
                id: 'conf-subnets',
                label: this.l10nService.getMessage(l10nKeys.columnTitleConfiguredSubnets),
                transform: (row: ConfiguredNetwork): string => {
                    const { configured_subnets: configuredSubnets } = row.getConfig();

                    if (!configuredSubnets?.length) {
                        return this.l10nService.getMessage(globalL10nKeys.emptyLabel);
                    }

                    const [subnet, ...remainingSubnets] = configuredSubnets;

                    const formattedSubnet =
                        this.ConfiguredNetwork.formatSubnetRuntimeData(subnet);

                    const { length: remainingSubnetCount } = remainingSubnets;

                    return remainingSubnets.length ?
                        this.l10nService.getMessage(
                            l10nKeys.configuredSubnetsValueText,
                            [formattedSubnet, remainingSubnetCount],
                        ) :
                        formattedSubnet;
                },
            }, {
                id: 'static-pools',
                label: this.l10nService.getMessage(l10nKeys.columnTitleStaticIpPools),
                transform: (row: ConfiguredNetwork): string => {
                    const { configured_subnets: configuredSubnets = [] } = row.getConfig();

                    const staticIpPoolsCount = configuredSubnets.reduce(
                        (staticPoolCount: number, subnet: IConfiguredNetworkSubnet) => {
                            const { static_ip_ranges: staticIpRanges = [] } = subnet;

                            return staticPoolCount + staticIpRanges.length;
                        }, 0,
                    );

                    return String(staticIpPoolsCount);
                },
            }, {
                id: 'vrf',
                label: this.l10nService.getMessage(l10nKeys.columnTitleVrfContext),
                transform: (row: ConfiguredNetwork): string => {
                    return row.getConfig().vrf_context_ref.name();
                },
            },
        ];

        const vtype = this.getSelectedCloudType() as CloudType;

        if (vtype === CloudType.CLOUD_VCENTER) {
            fields.push({
                id: 'switch-name',
                label: this.l10nService.getMessage(l10nKeys.columnTitleSwitchName),
                transform: (row: ConfiguredNetwork): string => {
                    const { discovery } = row.data;

                    return discovery?.switch_name || '-';
                },
            }, {
                id: 'managed-object-id',
                label: this.l10nService.getMessage(l10nKeys.columnTitleManagedObjectId),
                transform: (row: ConfiguredNetwork): string => {
                    const { discovery } = row.data;

                    return discovery?.managed_object_id || '-';
                },
            });
        }

        this.networkListGridConfig.fields = fields;
    }
}
