/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { VHMatch } from 'object-types';
import { VHMatchConfigItem } from 'ajs/modules/vs/factories/vh-match.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './virtual-host-grid.l10n';
import './virtual-host-grid.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Virtual Host Grid.
 * @author Abhinesh Gour
 */
@Component({
    selector: 'virtual-host-grid',
    templateUrl: './virtual-host-grid.component.html',
})
export class VirtualHostGridComponent implements AfterViewInit {
    @Input()
    public vhMatches : RepeatedMessageItem<VHMatchConfigItem>;

    /**
     * Fires on Host Grid edit action.
     */
    @Output()
    public onEdit = new EventEmitter<VHMatchConfigItem>();

    /**
     * Fires on Host Grid add action.
     */
    @Output()
    public onAdd = new EventEmitter<VHMatchConfigItem>();

    /**
     * Fires on Host Grid delete action.
     */
    @Output()
    public onDelete = new EventEmitter<VHMatchConfigItem>();

    public readonly objectType = VHMatch;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public hostsGridConfig: IAviDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.hostsGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'host-name',
                    transform: (vhMatch: VHMatchConfigItem) => vhMatch.config.host,
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (vhMatch: VHMatchConfigItem) => this.deleteVHMatch(vhMatch),
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (vhMatch: VHMatchConfigItem) => this.editVHMatch(vhMatch),
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    onClick: (vhMatches: VHMatchConfigItem[]) => {
                        vhMatches.forEach((vhMatch: VHMatchConfigItem) => {
                            this.deleteVHMatch(vhMatch);
                        });
                    },
                },
            ],
            layout: {
                withReordering: true,
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * Add VHMatch event handler.
     */
    public addVHMatch(): void {
        this.onAdd.emit();
    }

    /**
     * Edit VHMatch event handler.
     */
    public editVHMatch(vhMatch: VHMatchConfigItem): void {
        this.onEdit.emit(vhMatch);
    }

    /**
     * Delete VHMatch event handler.
     */
    public deleteVHMatch(vhMatch: VHMatchConfigItem): void {
        this.onDelete.emit(vhMatch);
    }
}
