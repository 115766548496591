/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsModule
 */

const moduleName = 'security';
const componentName = 'oauth-vs-config';
const prefix = `${moduleName}.${componentName}`;

export const authProfileLabel = `${prefix}.authProfileLabel`;
export const authProfileInputPlaceholder = `${prefix}.authProfileInputPlaceholder`;
export const createAuthProfileButtonLabel = `${prefix}.createAuthProfileButtonLabel`;
export const redirectUriInputLabel = `${prefix}.redirectUriInputLabel`;
export const logoutUriInputLabel = `${prefix}.logoutUriInputLabel`;
export const postLogoutRedirectUriInputLabel = `${prefix}.postLogoutRedirectUriInputLabel`;
export const uriInputPlaceholder = `${prefix}.uriInputPlaceholder`;
export const cookieNameInputLabel = `${prefix}.cookieNameInputLabel`;
export const cookieTimeoutInputLabel = `${prefix}.cookieTimeoutInputLabel`;
export const cookieTimeoutInputPlaceholder = `${prefix}.cookieTimeoutInputPlaceholder`;
export const scopesLabel = `${prefix}.scopesLabel`;
export const scopeNameInputPlaceholder = `${prefix}.scopeNameInputPlaceholder`;
export const addScopesLinkLabel = `${prefix}.addScopesLinkLabel`;
export const oauthResourceServerLabel = `${prefix}.oauthResourceServerLabel`;
export const jwtAccessTokenInputLabel = `${prefix}.jwtAccessTokenInputLabel`;
export const opaqueAccessTokenInputLabel = `${prefix}.opaqueAccessTokenInputLabel`;
export const audienceLabel = `${prefix}.audienceLabel`;
export const audienceInputPlaceholder = `${prefix}.audienceInputPlaceholder`;
export const serverIdLabel = `${prefix}.serverIdLabel`;
export const serverSecretLabel = `${prefix}.serverSecretLabel`;
export const serverIdInputPlaceholder = `${prefix}.serverIdInputPlaceholder`;
export const clientIdLabel = `${prefix}.clientIdLabel`;
export const clientIdInputPlaceholder = `${prefix}.clientIdInputPlaceholder`;
export const clientSecretLabel = `${prefix}.clientSecretLabel`;
export const enableOidcCheckboxLabel = `${prefix}.enableOidcCheckboxLabel`;
export const fetchProfileByProfileCheckboxLabel = `${prefix}.fetchProfileByProfileCheckboxLabel`;
export const fetchProfileFromUserinfoCheckboxLabel = `${prefix}.fetchProfileFromUserinfoCheckboxLabel`;
export const removeScopeNameTitle = `${prefix}.removeScopeNameTitle`;
export const introspectionDataTimeoutInputLabel = `${prefix}.introspectionDataTimeoutInputLabel`;
export const introspectionDataTimeoutInputPlaceholder = `${prefix}.introspectionDataTimeoutInputPlaceholder`;
export const ssoPolicyLabel = `${prefix}.ssoPolicyLabel`;
export const ssoPolicyInputPlaceholder = `${prefix}.ssoPolicyInputPlaceholder`;
export const createSsoPolicyButtonLabel = `${prefix}.createSsoPolicyButtonLabel`;

export const ENGLISH = {
    [authProfileLabel]: 'Auth Profile',
    [authProfileInputPlaceholder]: 'Select Profile',
    [createAuthProfileButtonLabel]: 'Create Profile',
    [redirectUriInputLabel]: 'Redirect URI',
    [logoutUriInputLabel]: 'Logout URI',
    [postLogoutRedirectUriInputLabel]: 'Post Logout Redirect URI',
    [uriInputPlaceholder]: 'Enter URI',
    [cookieNameInputLabel]: 'Cookie Name',
    [cookieTimeoutInputLabel]: 'Cookie Timeout',
    [cookieTimeoutInputPlaceholder]: 'Enter Cookie Timeout',
    [scopesLabel]: 'Scopes',
    [scopeNameInputPlaceholder]: 'Enter Scope Name',
    [addScopesLinkLabel]: 'Add Scopes',
    [oauthResourceServerLabel]: 'OAuth Resource Server',
    [jwtAccessTokenInputLabel]: 'JWT access token',
    [opaqueAccessTokenInputLabel]: 'Opaque access token',
    [audienceLabel]: 'Audience',
    [audienceInputPlaceholder]: 'Enter Audience',
    [serverIdLabel]: 'Server ID',
    [serverSecretLabel]: 'Server Secret',
    [serverIdInputPlaceholder]: 'Enter Server ID',
    [clientIdLabel]: 'Client ID',
    [clientIdInputPlaceholder]: 'Enter Client ID',
    [clientSecretLabel]: 'Client Secret',
    [enableOidcCheckboxLabel]: 'Enable OIDC',
    [fetchProfileByProfileCheckboxLabel]: 'Enable Fetch Profile by Profile Scope',
    [fetchProfileFromUserinfoCheckboxLabel]: 'Enable Fetch Profile from Userinfo Endpoint',
    [removeScopeNameTitle]: 'Remove Scope Name',
    [introspectionDataTimeoutInputLabel]: 'Introspection Data Timeout',
    [introspectionDataTimeoutInputPlaceholder]: 'Enter Duration',
    [ssoPolicyLabel]: 'SSO Policy',
    [ssoPolicyInputPlaceholder]: 'Select SSO Policy',
    [createSsoPolicyButtonLabel]: 'Create SSO Policy',
};
