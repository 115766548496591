/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { ERROR_PAGE_PROFILE_ITEM_TOKEN } from 'ajs/modules/error-page/error-page.tokens';

export type TErrorPageProfileCollection = typeof ErrorPageProfileCollection;

/**
 * @description ErrorPageProfile collection class.
 *
 * @author Ratan Kumar, Shibam Biswas
 */
export class ErrorPageProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'errorpageprofile',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_ERRORPAGEPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ERROR_PAGE_PROFILE_ITEM_TOKEN);
    }
}

ErrorPageProfileCollection.ajsDependencies = [
    ERROR_PAGE_PROFILE_ITEM_TOKEN,
];
