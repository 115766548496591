/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export const WAF_PROFILE_ITEM_TOKEN = 'WafProfileItem';
export const WAF_PROFILE_COLLECTION_TOKEN = 'WafCollectionItem';
export const WAF_DATA_FILE_CONFIG_ITEM_TOKEN = 'WafDataFileConfigItem';
export const WAF_POLICY_PSM_GROUP_ITEM_TOKEN = 'WafPolicyPsmGroup';
export const WAF_POLICY_ITEM_TOKEN = 'WafPolicyItem';
export const WAF_POLICY_COLLECTION_TOKEN = 'WafPolicyCollection';
export const LATEST_WAF_CRS_VERSION_SERVICE_TOKEN = 'latestWafCrsVersionService';
export const WAF_CONTENT_TYPE_MAPPING_CONFIG_ITEM_TOKEN = 'WafContentTypeMappingConfigItem';
