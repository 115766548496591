/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const STATIC_IPV4 = 'STATIC_IPv4';
const STATIC_IPV6 = 'STATIC_IPv6';

//TODO config.virtualservice_refs returned by inventory is not a part of config, hence after
// loading an SE instance in `normal` way this data is lost until next config update

function serviceEngineFactory(
    $q,
    UpdatableItem,
    Regex,
    rangeParser,
    getSubnetString,
    aviAlertService,
) {
    /**
     * @constructor
     * @extends module:avi/dataModel.UpdatableItem
     * @memberOf module:avi/serviceengine
     * @description ServiceEngine {@link module:avi/dataModel.UpdatableItem} Item class.
     * @author Alex Malitsky
     */
    class ServiceEngine extends UpdatableItem {
        constructor(args = {}) {
            args.params = {
                include_name: true,
                join: [
                    'host_ref',
                    'serviceenginegroup:se_group_ref',
                    'cloud_ref',
                ].join(),
            };

            super(args);
        }

        /**
         * List of all the modes required to create vincHash.
         * @type {string[]}
         */
        static ipModes = [
            STATIC_IPV4,
            STATIC_IPV6,
            'DHCP',
            'VIP',
            'DOCKER_HOST',
        ];

        /** @override */
        transformAfterLoad() {
            const config = this.getConfig();
            const vnics = [];

            const { mgmt_vnic: mgmtVnic } = config;

            if (mgmtVnic) {
                mgmtVnic.name = ServiceEngine.getVnicName(mgmtVnic);
                vnics.push(mgmtVnic);
            }

            const { data_vnics: dataVnics } = config;

            if (dataVnics) {
                dataVnics.forEach(
                    vnic => vnic.name = ServiceEngine.getVnicName(vnic, true),
                );

                vnics.push(...dataVnics);
            }

            const { vnic } = config;

            vnics.forEach(value => {
                const found = _.findWhere(vnic, { mac_addr: value.mac_address });

                if (found) {
                    value.state = found.state;
                }
            });

            config.data_vnics = _.sortBy(dataVnics, 'name');
        }

        /**
         * Set vincHash for data_vnics and mgmt_vnic.
         * @param {Object} vnic - vnic object from data_vnics and mgmt_vnic.
         **/
        static setVnicHash = vnic => {
            const vnicHash = {};

            ServiceEngine.ipModes.forEach(mode => {
                vnicHash[mode] = {
                    list: '',
                    prefixes: [],
                };
            });

            if (Array.isArray(vnic.vnic_networks)) {
                vnic.vnic_networks.forEach(({ mode, ip }) => {
                    const { ip_addr: ipAddr } = ip;
                    let modeVal;

                    if (mode === 'STATIC') {
                        if (ipAddr.type === 'V6') {
                            modeVal = vnicHash[STATIC_IPV6];
                        } else if (ipAddr.type === 'V4') {
                            modeVal = vnicHash[STATIC_IPV4];
                        }
                    } else {
                        modeVal = vnicHash[mode];
                    }

                    modeVal.prefixes.push(getSubnetString(ip));
                });

                ServiceEngine.ipModes.forEach(mode => {
                    const { prefixes } = vnicHash[mode];

                    if (prefixes.length > 0) {
                        vnicHash[mode].list = prefixes.join(', ');
                    }
                });
            }

            vnic.vnicHash = vnicHash;
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            const { mgmt_vnic: mgmtVnic } = config;

            if (mgmtVnic) {
                ServiceEngine.setVnicHash(mgmtVnic);
                mgmtVnic.mgmtType = true;
            }

            if (config.data_vnics) {
                config.data_vnics.forEach(ServiceEngine.setVnicHash);
            }
        }

        /**
         * Remove vincHash from data_vnics and mgmt_vnic and set vnic_networks before saving.
         * @param {Object} vnic - vinc object from data_vnics and mgmt_vnic.
         **/
        static setVnicNetworks = vnic => {
            const {
                vnicHash,
                vnic_networks: oldNetworks,
            } = vnic;

            const oldNetworksHash = {};

            if (oldNetworks) {
                oldNetworks.reduce(
                    (acc, net) => {
                        const id = getSubnetString(net.ip);

                        acc[id] = net;

                        return acc;
                    },
                    oldNetworksHash,
                );
            }

            const networks = [];

            ServiceEngine.ipModes.forEach(mode => {
                const modeVal = vnicHash[mode];
                const isStatic = mode === STATIC_IPV6 || mode === STATIC_IPV4;
                const ipMode = isStatic ? 'STATIC' : mode;

                if (_.isString(modeVal.list)) {
                    const prefixes = modeVal.list.split(/\s*,\s*/);

                    prefixes.forEach(prefix => {
                        if (Regex.anySubnet.test(prefix)) {
                            const net = {
                                ip: rangeParser.ipRange2Json(prefix),
                                mode: ipMode,
                            };

                            const oldNetwork = oldNetworksHash[prefix];

                            // restoring ctrl_alloc field value if this network was
                            // previously present
                            if (oldNetwork) {
                                net.ctlr_alloc = oldNetwork.ctlr_alloc;
                            }

                            networks.push(net);
                        }
                    });
                }
            });

            vnic.vnic_networks = networks;

            delete vnic.state;
            delete vnic.vnicHash;
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            const { mgmt_vnic: mgmtVnic } = config;

            if (mgmtVnic) {
                ServiceEngine.setVnicNetworks(mgmtVnic);
                delete mgmtVnic.state;
                delete mgmtVnic.mgmtType;
            }

            if (config.data_vnics) {
                config.data_vnics.forEach(ServiceEngine.setVnicNetworks);
            }

            delete config.cloud_ref_data;
            delete config.se_group_ref_data;
            delete config.host_ref_data;

            return config;
        }

        /**
         * This function emulates a call to the server and giving the promise
         * It is making multiple calls periodically to continuously update the object
         * that was delivered into resolve
         * @param aFields - Array of fields that has to be loaded
         * @return {ng.$q.promise} - Promise
         * @override
         */
        loadRequest(aFields) {
            return $q.all([
                this.loadConfig(aFields),
                this.loadEventsAndAlerts(aFields),
                this.loadMetrics(aFields),
            ]);
        }

        /**
         * Checks whether SE is in enabled (default) state. False when not ready.
         * @returns {boolean}
         * @public
         */
        isEnabled() {
            const { enable_state: enableState } = this.getConfig();

            return !enableState || enableState === 'SE_STATE_ENABLED';
        }

        /**
         * Return combined value of management IP's available for inventory view of service engine.
         * @returns {string}
         * @public
         */
        getManagementIpInventoryView() {
            const config = this.getConfig();
            const managementIps = [];

            if (config.mgmt_ip_address?.addr) {
                managementIps.push(config.mgmt_ip_address.addr);
            }

            if (config.mgmt_ip6_address?.addr) {
                managementIps.push(config.mgmt_ip6_address.addr);
            }

            return managementIps.join(', ');
        }

        /**
         * Return combined value of management IP's available for info view of service engine.
         * @returns {string}
         * @public
         */
        getManagementIpInfoView() {
            const vnicNetworks = this.getConfig().mgmt_vnic?.vnic_networks || [];
            const mgmtIps = vnicNetworks.map(vnic_network => vnic_network.ip.ip_addr.addr);

            return mgmtIps.join(', ');
        }

        /**
         * Checks whether SE is in disabled_for_placement state. False otherwise.
         * @returns {boolean}
         * @public
         */
        isDisabledForPlacement() {
            const { enable_state: enableState } = this.getConfig();

            return !enableState || enableState === 'SE_STATE_DISABLED_FOR_PLACEMENT';
        }

        /**
         * Makes actual config modification and calls save when needed.
         * @param {string} enableState - backend enum: SE_STATE_ENABLED, SE_STATE_DISABLED,
         *     SE_STATE_DISABLED_FOR_PLACEMENT.
         * @protected
         * @returns {ng.$q.promise}
         */
        setEnableState_(enableState) {
            return this.patch({
                replace: { enable_state: enableState },
            });
        }

        /**
         * Public method to enable/disable SE.
         * @param {number=} enableState - 0 for `SE_STATE_ENABLED`, 1 for `DISABLED_FOR_PLACEMENT`,
         *     2 for `DISABLED`. 0 used as default.
         * @returns {ng.$q.promise}
         * @public
         */
        setEnableState(enableState) {
            const states = {
                1: 'SE_STATE_DISABLED_FOR_PLACEMENT',
                2: 'SE_STATE_DISABLED',
            };

            return this.setEnableState_(states[enableState] || 'SE_STATE_ENABLED');
        }

        /**
         * We want to drop "avi_" off network interface names.
         * @param {string} name
         * @returns {string}
         * @protected
         */
        static trimSENetInterfaceName_(name) {
            if (name && name.length > 4 && /^avi_/i.test(name)) {
                return name.slice(4);
            }

            return name;
        }

        /**
         * Returns list of interfaces of specific type - vnic or vlan
         * this {Object} - UhitInfo.
         * @param {string} [type='vnic']
         * @returns {Object[]} - List of in use vnics.
         */
        getInterfaceList(type = 'vnic') {
            const result = [];
            const config = this.getConfig();

            if (config) {
                if (Array.isArray(config.data_vnics)) {
                    const vnics = [...config.data_vnics];

                    if (type === 'vnic') {
                        if (config.inband_mgmt) {
                            vnics.push(config.mgmt_vnic);
                        }

                        vnics.forEach(vnic => {
                            if (ServiceEngine.isInterfaceInUse(vnic)) {
                                result.push(vnic);
                            }
                        });
                    } else if (type === 'vlan') {
                        vnics.forEach(vnic => {
                            if (Array.isArray(vnic.vlan_interfaces)) {
                                result.push(...vnic.vlan_interfaces);
                            }
                        });
                    }
                }
            }

            return result;
        }

        /**
         * @param vnic - individual vnic to check.
         * @returns {boolean} - validity of vnic for interface list.
         */
        static isInterfaceInUse(vnic) {
            if (vnic.enabled && vnic.connected && vnic.vnic_networks &&
                vnic.vnic_networks.length) {
                return true;
            }

            return false;
        }

        /**
         * Returns network interface names this SE is connected to.
         * @param {boolean} dataOnly - If true is passed management interface will be ignored
         *     and not returned.
         * @returns {string[]}
         * @public
         */
        getNetworkInterfaces(dataOnly = false) {
            const
                {
                    data_vnics: dataVnics,
                    mgmt_vnic: mgmtVnic,
                } = this.getConfig(),
                interfaces = [];

            if (!dataOnly && mgmtVnic) {
                interfaces.push(mgmtVnic['if_name']);
            }

            if (dataVnics) {
                dataVnics.forEach(
                    ({ if_name: name, vnic_networks: nets, vlan_interfaces: vlans }) => {
                        if (nets && nets.length) {
                            interfaces.push(name);
                        }

                        if (vlans) {
                            vlans.forEach(({ if_name: name, vnic_networks: nets }) => {
                                if (nets && nets.length) {
                                    interfaces.push(name);
                                }
                            });
                        }
                    },
                );
            }

            return interfaces.map(ServiceEngine.trimSENetInterfaceName_);
        }

        /**
         * Returns the cloudId this SE belongs to.
         * @returns {string}
         * @public
         */
        getCloudId() {
            return this.getConfig()['cloud_ref'].slug();
        }

        /** @override */
        getDetailsPageStateParams_() {
            return {
                seId: this.id,
                cloudId: this.getCloudId(),
            };
        }

        /**
         * Sends HTTP request to reboot Service Engine.
         * @returns {HttpPromise} - Angular HTTP promise.
         */
        reboot() {
            return this.request('post', `/api/serviceengine/${this.id}/reboot`).catch(
                error => aviAlertService.throw(error.data),
            );
        }

        /**
         * Returns se group configuration containing this se.
         * @returns {Object|null}
         * @private
         */
        getSEGroupConfig_() {
            return this.getConfig()['se_group_ref_data'] || null;
        }

        /**
         * SE's force delete URL is different than the other items.
         * @param {boolean=} force
         * @returns {string}
         * @protected
         * @override
         */
        getUrlToDelete_(force) {
            const url = super.getUrlToDelete_(false);

            if (force) {
                return `${url}/forcedelete`;
            }

            return url;
        }

        /**
         * Returns HTTP Method to delete an SE.
         * @param {boolean=} force
         * @returns {string}
         * @protected
         * @override
         */
        getHttpMethodToDelete_(force) {
            return force ? 'POST' : 'DELETE';
        }

        /**
         * Returns true when real time metrics is switched on.
         * @returns {boolean}
         * @public
         */
        hasRealTimeMetrics() {
            const seGroupConfig = this.getSEGroupConfig_();

            return seGroupConfig && 'realtime_se_metrics' in seGroupConfig &&
                seGroupConfig['realtime_se_metrics']['enabled'] || false;
        }

        /** @override */
        hasCustomTimeFrameSettings() {
            return !this.collection && this.hasRealTimeMetrics();
        }

        /** @override */
        getCustomTimeFrameSettings(tfLabel) {
            if (this.hasCustomTimeFrameSettings() && tfLabel === 'rt') {
                return {
                    step: 5,
                    limit: 360,
                };
            }

            return null;
        }

        /**
         * Returns VNIC name. Preferably name of adapter.
         * @param {Object} vnic
         * @param {boolean} [withInterfaceName=false] - Appends interface name to adapter name
         *     when both are present.
         * @return {string}
         */
        static getVnicName(vnic, withInterfaceName = false) {
            const {
                adapter,
                if_name: interfaceName,
            } = vnic;

            const knownAdapter = adapter && adapter !== 'Unknown';

            if (knownAdapter) {
                if (withInterfaceName) {
                    return `${adapter} (${interfaceName})`;
                }

                return adapter;
            }

            return interfaceName;
        }

        /**
         * Returns VLAN name.
         * @param {Object} vlan
         * @return {string}
         */
        static getVlanName(vlan) {
            const {
                if_name: interfaceName,
            } = vlan;

            return interfaceName;
        }

        /**
         * Gets Row Id for each row in Vnic list.
         * Checks for the presence of mac_address. If not, if_name would be
         * considered as rowId.
         * @param {Object} vnic - vnic or vnic.vlan_interface.
         * @return {string}
         */
        static getVnicId(vnic) {
            const { mac_address: macAddress } = vnic;
            const name = ServiceEngine.getVnicName(vnic);

            return `${name}:${macAddress}`;
        }

        /**
         * Returns id for the vlan_interface.
         * Uses vlan_id and if_name for the id.
         * @param {Object} vlan - vnic.vlan_interface.
         * @return {string}
         */
        static getVlanId(vlan) {
            const { vlan_id: vlanId } = vlan;
            const name = ServiceEngine.getVlanName(vlan);

            return `${name}:${vlanId}`;
        }
    }

    Object.assign(ServiceEngine.prototype, {
        objectName: 'serviceengine',
        windowElement: 'infra-se-edit',
        detailsStateName_: 'authenticated.infrastructure.cloud.serviceengine-detail',
    });

    return ServiceEngine;
}

serviceEngineFactory.$inject = [
    '$q',
    'UpdatableItem',
    'Regex',
    'RangeParser',
    'getSubnetString',
    'aviAlertService',
];

angular.module('avi/serviceengine')
    .factory('ServiceEngine', serviceEngineFactory);
