/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    SEGroupCollection,
} from 'ajs/modules/service-engine-group/factories/se-group.collection.factory';

import { HaMode } from 'generated-types';
import { isEmpty } from 'underscore';
import { InfraCloudState } from 'ajs/modules/vrf-context/services/infra-cloud-state.service';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './se-group-list-page.l10n';
import './se-group-list-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSEGroupCollection = typeof SEGroupCollection;

/**
 * @description Component for Service Engine Group list page.
 * @author vgohil
 */
@Component({
    selector: 'se-group-list-page',
    templateUrl: './se-group-list-page.component.html',
})
export class SEGroupListPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('seGroupExpanderTemplate')
    public readonly seGroupExpanderTemplate: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public seGroupGridConfig: IAviCollectionDataGridConfig;

    private readonly seGroupCollection: SEGroupCollection;

    /**
     * Hash of ha_mode enum, used to display value.
     * Since, the enum doesn't have required values to show.
     */
    private haModeDisplayValueEnum: Record<HaMode, string>;

    constructor(
        private readonly l10nService: L10nService,
        private readonly infraCloudState: InfraCloudState,
        @Inject(SEGroupCollection)
        SEGroupCollection: TSEGroupCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const cloud = this.infraCloudState.getCloud();

        this.seGroupCollection = new SEGroupCollection({
            params: {
                'cloud_ref.uuid': cloud.id,
            },
            defaults: {
                cloud_ref: cloud.getRef(),
            },
            dataFields: [
                'config',
                'upgradestatus',
            ],
        });
    }

    /** @override */
    public ngOnInit(): void {
        const { l10nService } = this;

        // Define the display value for ha_mode enum.
        this.haModeDisplayValueEnum = {
            [HaMode.HA_MODE_LEGACY_ACTIVE_STANDBY]:
                l10nService.getMessage(l10nKeys.activeStandbyValue),
            [HaMode.HA_MODE_SHARED_PAIR]: l10nService.getMessage(l10nKeys.activeValue),
            [HaMode.HA_MODE_SHARED]: l10nService.getMessage(l10nKeys.nmBufferValue),
        };

        this.setCollectionGridConfig();

        this.infraCloudState.on('cloudChange', this.onCloudChange);
    }

    /**
     * @override
     * Template Refs must be set after the view is initialized.
     */
    public ngAfterViewInit(): void {
        this.seGroupGridConfig = {
            ...this.seGroupGridConfig,
            expandedContentTemplateRef: this.seGroupExpanderTemplate,
            expanderDisabled: (row: ITSEGroup) => {
                const inventory = row.getInventoryData();

                return isEmpty(inventory) ||
                    !inventory.serviceengines?.length && !inventory.virtualservices?.length;
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.seGroupCollection?.destroy();
        this.infraCloudState.unbind('cloudChange', this.onCloudChange);
    }

    /**
     * Sets the basic grid configuration for SE group collection.
     */
    private setCollectionGridConfig(): void {
        const { l10nService, seGroupCollection: { objectName }, haModeDisplayValueEnum } = this;

        this.seGroupGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.seGroupCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: ITSEGroup) => row.getName(),
                    sortBy: 'name',
                },
                {
                    id: 'version',
                    label: l10nService.getMessage(globalL10nKeys.versionLabel),
                    transform: (row: ITSEGroup) => row.getVersion(true),
                },
                {
                    id: 'numOfSe',
                    label: l10nService.getMessage(l10nKeys.columnTitleServiceEnginesCount),
                    transform: (row: ITSEGroup) => `${row.numOfSe}`,
                },
                {
                    id: 'max_se',
                    label: l10nService.getMessage(l10nKeys.columnTitleMaxNoOfServiceEngines),
                    transform: (row: ITSEGroup) => row.config.max_se,
                },
                {
                    id: 'numOfVs',
                    label: l10nService.getMessage(l10nKeys.columnTitleVirtualServicesCount),
                    transform: (row: ITSEGroup) => `${row.numOfVs}`,
                },
                {
                    id: 'max_vs_per_se',
                    label: l10nService.getMessage(l10nKeys.columnTitleMaxNoOfVsPerSe),
                    transform: (row: ITSEGroup) => row.config.max_vs_per_se,
                },
                {
                    id: 'ha_mode',
                    label: l10nService.getMessage(l10nKeys.columnTitleHaMode),
                    transform: (row: ITSEGroup) => haModeDisplayValueEnum[row.config.ha_mode],
                },
            ],
            singleactions: [
                {
                    id: 'edit',
                    label: l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    hidden: (row: ITSEGroup): boolean => !row.isEditable(),
                    onClick: (row: ITSEGroup) => row.edit(),
                },
            ],
            layout: {
                placeholderMessage: l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * Reload the SE Group collection on Cloud change.
     */
    private onCloudChange = (): void => {
        const { infraCloudState, seGroupCollection } = this;
        const cloudId = infraCloudState.getCloudId();

        seGroupCollection.setParams({
            'cloud_ref.uuid': cloudId,
        });

        seGroupCollection.setDefaultItemConfigProps({
            cloud_ref: cloudId,
        });

        seGroupCollection.load();
    };
}
