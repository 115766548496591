/**
 * Module containing Cloud components.
 * @preferred
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { SharedModule } from '../../shared/shared.module';
import { AviFormsModule } from '../avi-forms/avi-forms.module';

import {
    LicenseSelectorDirective,
    VerifyCloudConnectorUserHostModalComponent,
    VerifyCloudConnectorUserHostModalService,
} from '.';

import { DialogModule } from '../dialog/dialog.module';

import {
    licenseBasedFeaturesServiceProvider,
    openStackNetworkCollectionProvider,
    roleCollectionProvider,
    seGroupCollectionProvider,
} from './ajs-upgraded-providers';

const cloudComponents = [
    VerifyCloudConnectorUserHostModalComponent,
];

const cloudDirectives = [
    LicenseSelectorDirective,
];

@NgModule({
    declarations: [
        ...cloudComponents,
        ...cloudDirectives,
    ],
    imports: [
        CommonModule,
        DataGridModule,
        SharedModule,
        DialogModule,
        AviFormsModule,
    ],
    providers: [
        licenseBasedFeaturesServiceProvider,
        seGroupCollectionProvider,
        openStackNetworkCollectionProvider,
        roleCollectionProvider,
        VerifyCloudConnectorUserHostModalService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CloudModule {}
