/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    StringGroupCollection,
    TStringGroupCollection,
} from 'ajs/modules/groups/factories/string-group/string-group.collection.factory';

import {
    StringGroupItem,
} from 'ajs/modules/groups/factories/string-group/string-group.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { STRING_GROUP_COLLECTION_TOKEN } from 'ajs/modules/groups/groups.tokens';
import { StringGroupType } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './string-group-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description StringGroups list page.
 * @author sghare
 */
@Component({
    selector: 'string-group-list',
    templateUrl: './string-group-list.component.html',
})
export class StringGroupListComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display StringGroup collection.
     */
    public stringGroupGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the StringGroup items.
     */
    private readonly stringGroupCollection: StringGroupCollection;

    /**
     * Hash of all available String Group Types.
     */
    private readonly stringGroupTypeEnumHash = {
        SG_TYPE_KEYVAL: StringGroupType.SG_TYPE_KEYVAL,
        SG_TYPE_STRING: StringGroupType.SG_TYPE_STRING,
    };

    constructor(
        private readonly l10nService: L10nService,
        @Inject(STRING_GROUP_COLLECTION_TOKEN)
        StringGroupCollection: TStringGroupCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.stringGroupCollection = new StringGroupCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.stringGroupCollection;

        this.stringGroupGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.stringGroupCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: StringGroupItem): string => row.getName(),
                },
                {
                    id: 'type',
                    label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                    transform: (row: StringGroupItem): string => {
                        const typeLabel = row.getConfig().type ===
                            this.stringGroupTypeEnumHash.SG_TYPE_STRING ?
                            globalL10nKeys.stringLabel : globalL10nKeys.mapLabel;

                        return this.l10nService.getMessage(typeLabel);
                    },
                },
                {
                    id: 'string-length',
                    label: this.l10nService.getMessage(l10nKeys.numberOfStringsLabel),
                    transform: (row: StringGroupItem): string => row.getConfig().kv.count,
                },
            ],
            singleactions: [
                {
                    id: 'edit',
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (row: StringGroupItem): void => {
                        row.isEditable() ? row.edit() : this.stringGroupCollection.clone(row);
                    },
                    hidden: (row: StringGroupItem): boolean => {
                        return !row.isEditable() && !row.isClonable();
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.stringGroupCollection.destroy();
    }
}
