/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

import { Component, Type } from '@angular/core';

import {
    MessageItem,
} from 'ajs/modules/data-model/factories';

import {
    IErrorPage,
    IHTTPStatusRange,
    MatchOperation,
} from 'generated-types';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins';

import {
    ErrorPageProfilePreviewComponent,
} from 'ng/lazy-loaded-components/modals/error-page-profile-modal/error-page-profile-preview';

import { isNaN } from 'underscore';
import { IFullModalLayout } from 'ng/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { StringService } from 'ajs/modules/core/services/string-service';
import * as l10n from '../error-page.l10n';

/**
 * Enum to get Error page type.
 */
export enum ErrorPageType {
    ERROR_REDIRECT = 'error_redirect',
    ERROR_PAGE_BODY_REF = 'error_page_body_ref',
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description ErrorPage Message Item.
 *
 * @author Ratan Kumar, Shibam Biswas
 */
export class ErrorPageConfigItem extends withFullModalMixin(MessageItem)<IErrorPage> {
    public static ajsDependencies = [
        'l10nService',
        'stringService',
    ];

    /**
     * errorPageBodyOrUrlType will hold the type redirect URL or HTML body.
     */
    public errorPageBodyOrUrlType: ErrorPageType;

    private l10nService: L10nService;

    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'ErrorPage',
            windowElement: 'lazy-load',
            whitelistedFields: [''],
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        this.stringService = this.getAjsDependency_('stringService');

        this.errorPageBodyOrUrlType = this.config.error_redirect ?
            ErrorPageType.ERROR_REDIRECT :
            ErrorPageType.ERROR_PAGE_BODY_REF;
    }

    /**
     * Import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { ErrorPageProfileListModalComponent } = await import(
            /* webpackChunkName: "error-page-profile-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/error-page-profile-modal/error-page-profile-list-modal/error-page-profile-list-modal.component'
        );

        return ErrorPageProfileListModalComponent as Type<Component>;
    }

    /**
     * Getter for error page item index.
     */
    public get pageIndex(): number {
        return this.config.index ?? 0;
    }

    /**
     * Method to merge status code And renges into single array of string.
     */
    public getErrorPageStatusCodes(): string[] {
        let rangesAndCodes: string[] = [];

        const {
            match: {
                ranges,
                status_codes: statusCodes,
            },
        } = this.config;

        if (Array.isArray(ranges)) {
            rangesAndCodes = ranges.map(r => `${r.begin}-${r.end}`);
        }

        if (Array.isArray(statusCodes)) {
            const codes = statusCodes.map(code => `${code}`);

            rangesAndCodes = [...rangesAndCodes, ...codes];
        }

        return rangesAndCodes;
    }

    /**
     * setStatusCodesAndRanges takes string of status codes and ranges
     * and split array into statusCodes and StatusRanges.
     */
    public setStatusCodesAndRanges(codesAndRenges: string[]): void {
        const statusCodeAndRangeList = codesAndRenges;
        const statusCodes: number[] = [];
        const statusRanges: string[] = [];

        if (Array.isArray(statusCodeAndRangeList) && statusCodeAndRangeList.length) {
            statusCodeAndRangeList.forEach(item => {
                if (item?.trim()) {
                    if (item.includes('-')) {
                        statusRanges.push(item);
                    } else {
                        statusCodes.push(+item);
                    }
                }
            });
        }

        this.bindRangeAndStatusCodes(statusRanges, statusCodes);
    }

    /**
     * Method takes array of statusRanges and statusCodes update match config.
     */
    public bindRangeAndStatusCodes(statusRanges: string[], statusCodes: number[]): void {
        const ranges: IHTTPStatusRange[] = [];

        if (Array.isArray(statusRanges) && statusRanges.length) {
            statusRanges.forEach(range => {
                const subRange = range.split('-');

                if (subRange.length === 2) {
                    const begin = parseInt(subRange[0], 10);
                    const end = parseInt(subRange[1], 10);

                    if (!isNaN(begin) && !isNaN(end)) {
                        ranges.push({
                            begin,
                            end,
                        });
                    }
                }
            });
        }

        this.config.match.ranges = ranges;
        this.config.match.status_codes = statusCodes;
    }

    /**
     * Getter to check if error page type is 'error_redirect'.
     */
    public get isTypeErrorRedirect(): boolean {
        return this.errorPageBodyOrUrlType === ErrorPageType.ERROR_REDIRECT;
    }

    /**
     * Method to delete error_page_body_ref or error_redirect
     * based on selection type.
     */
    public setErrorPageRedirectOrBodyRefType(): void {
        if (this.isTypeErrorRedirect) {
            delete this.config.error_page_body_ref;
        } else {
            delete this.config.error_redirect;
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        const pageBreadcrumbTitle = this.isTypeErrorRedirect ?
            l10nKeys.errorPageUrlBreadcrumbTitle :
            l10nKeys.errorPageBodyBreadcrumbTitle;

        return this.l10nService.getMessage(pageBreadcrumbTitle);
    }

    /** @override */
    protected async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        return {
            ...props,
            previewComponent: ErrorPageProfilePreviewComponent as Type<Component>,
        };
    }

    /**
     * Getter function for the config to override the message default.
     */
    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IErrorPage> {
        return {
            match: {
                match_criteria: MatchOperation.IS_IN,
                status_codes: [],
                ranges: [],
            },
        };
    }
}
