/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-page';
const prefix = `${moduleName}.${componentName}`;

export const loadingMessageLabel = `${prefix}.loadingMessageLabel`;
export const timeoutMessageLabel = `${prefix}.timeoutMessageLabel`;
export const refreshLogsLabel = `${prefix}.refreshLogsLabel`;
export const errMessageLabel = `${prefix}.errMessageLabel`;
export const reloadPageLabel = `${prefix}.reloadPageLabel`;
export const legacyViewBannerDialog = `${prefix}.legacyViewBannerDialog`;
export const provideFeedbackLabel = `${prefix}.provideFeedbackLabel`;
export const learnMoreLink = `${prefix}.learnMoreLink`;

export const ENGLISH = {
    [loadingMessageLabel]: 'Please wait, logs are being retrieved.',
    [timeoutMessageLabel]: 'We were unable to fetch all the logs. Please reduce the timeframe or refresh logs.',
    [refreshLogsLabel]: 'Refresh Logs',
    [errMessageLabel]: 'An error occurred while loading logs, please try again.',
    [reloadPageLabel]: 'Reload Page',
    [legacyViewBannerDialog]: 'Thanks for trying out the new logs view. To revert to legacy view please go to My Account settings.',
    [provideFeedbackLabel]: 'Provide Feedback',
    [learnMoreLink]: 'Learn More',
};
