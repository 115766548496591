/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service';

import {
    HealthMonitorCollection,
} from 'ajs/modules/healthmonitor/factories/health-monitor.collection.factory';

import {
    HealthMonitorItem,
} from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import * as l10n from './health-monitor-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type THealthMonitorCollection = typeof HealthMonitorCollection;

/**
 * @description Health Monitors list page to show all monitors
 * @author Vijay
 */
@Component({
    selector: 'health-monitor-list-page',
    templateUrl: './health-monitor-list-page.component.html',
})
export class HealthMonitorListPageComponent implements OnInit, OnDestroy {
    /**
     * Configuration to display Health Monitors.
     */
    public healthMonitorGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * HealthMonitorCollection instance.
     */
    private readonly healthMonitorCollection: HealthMonitorCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        @Inject(HealthMonitorCollection)
        HealthMonitorCollection: THealthMonitorCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.healthMonitorCollection = new HealthMonitorCollection({
            params: {
                is_federated: undefined, // need to get both types here
            },
        });
    }

    /** @override */
    public ngOnInit(): void {
        const healthMonitoryTypeEnums =
            this.schemaService.getEnumValueLabelsHash('HealthMonitorType');
        const { objectName } = this.healthMonitorCollection;

        this.healthMonitorGridConfig = {
            id: `${objectName}-page`,
            collection: this.healthMonitorCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: HealthMonitorItem): string => row.getName(),
                },
                {
                    id: 'type',
                    label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                    transform: (row: HealthMonitorItem): string =>
                        healthMonitoryTypeEnums[row.getConfig().type],
                },
                {
                    id: 'federated',
                    label: this.l10nService.getMessage(globalL10nKeys.federatedLabel),
                    transform: (row: HealthMonitorItem): string => {
                        return row.getConfig().is_federated ?
                            this.l10nService.getMessage(globalL10nKeys.yesValueLabel) :
                            this.l10nService.getMessage(globalL10nKeys.noValueLabel);
                    },
                },
                {
                    id: 'send-interval',
                    label: this.l10nService.getMessage(l10nKeys.sendIntervalLabel),
                    transform: (row: HealthMonitorItem): string =>
                        `${row.getConfig().send_interval} ${this.l10nService
                            .getMessage(globalL10nKeys.secondsLabel)}`,
                },
                {
                    id: 'receive-timeout',
                    label: this.l10nService.getMessage(l10nKeys.receiveTimeoutLabel),
                    transform: (row: HealthMonitorItem): string =>
                        `${row.getConfig().receive_timeout} ${this.l10nService
                            .getMessage(globalL10nKeys.secondsLabel)}`,
                },
                {
                    id: 'successful-checks',
                    label: this.l10nService.getMessage(l10nKeys.successfulChecksLabel),
                    transform: (row: HealthMonitorItem): string =>
                        String(row.getConfig().successful_checks),
                },
                {
                    id: 'failed-checks',
                    label: this.l10nService.getMessage(l10nKeys.failedChecksLabel),
                    transform: (row: HealthMonitorItem): string =>
                        String(row.getConfig().failed_checks),
                },

            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.healthMonitorCollection.destroy();
    }
}
