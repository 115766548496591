/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-pages';
const componentName = 'logged-out-page';
const prefix = `${moduleName}.${componentName}`;

export const oauthSessionExpiredLabel = `${prefix}.oauthSessionExpiredLabel`;
export const oauthReloginMessageLabel = `${prefix}.oauthReloginMessageLabel`;
export const samlLoggedOutMessageLabel = `${prefix}.samlLoggedOutMessageLabel`;
export const samlReloginMessageLabel = `${prefix}.samlReloginMessageLabel`;

export const ENGLISH = {
    [oauthSessionExpiredLabel]: 'Your session has expired and have been logged out of NSX-ALB.',
    [oauthReloginMessageLabel]: 'Please re-login through <a class="notification-page__link" href="{0}">CSP</a> to start a new session',
    [samlLoggedOutMessageLabel]: 'You have been logged out from Avi Vantage.',
    [samlReloginMessageLabel]: 'You may <a class="notification-page__link" href="#!/{0}">log in</a> again or close this browser window.',
};
