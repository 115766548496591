/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-list';
const prefix = `${moduleName}.${componentName}`;

export const timestampLabel = `${prefix}.timestampLabel`;
export const wafLabel = `${prefix}.wafLabel`;
export const clientPortLabel = `${prefix}.clientPortLabel`;
export const serverPortLabel = `${prefix}.serverPortLabel`;
export const uriLabel = `${prefix}.uriLabel`;
export const dnsRequestTypeLabel = `${prefix}.dnsRequestTypeLabel`;
export const domainNameLabel = `${prefix}.domainNameLabel`;
export const lengthLabel = `${prefix}.lengthLabel`;
export const totalBytesLabel = `${prefix}.totalBytesLabel`;
export const durationLabel = `${prefix}.durationLabel`;
export const ednsSubnetLabel = `${prefix}.ednsSubnetLabel`;
export const detailIconLabel = `${prefix}.detailIconLabel`;
export const recommendationLabel = `${prefix}.recommendationLabel`;
export const errorMessageLabel = `${prefix}.errorMessageLabel`;

export const ENGLISH = {
    [timestampLabel]: 'Timestamp',
    [wafLabel]: 'WAF',
    [clientPortLabel]: 'Client Port',
    [serverPortLabel]: 'Server Port',
    [uriLabel]: 'URI',
    [dnsRequestTypeLabel]: 'DNS Request Type',
    [domainNameLabel]: 'Domain Name',
    [lengthLabel]: 'Length',
    [totalBytesLabel]: 'Total Bytes',
    [durationLabel]: 'Duration',
    [ednsSubnetLabel]: 'EDNS Subnet',
    [detailIconLabel]: 'Log detail',
    [recommendationLabel]: 'Recommendation',
    [errorMessageLabel]: 'An error occurred while loading the table, please try again.',
};
