/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const ignorePeerChainCheckboxLabel = `${prefix}.ignorePeerChainCheckboxLabel`;
export const enableCrlCheckCheckboxLabel = `${prefix}.enableCrlCheckCheckboxLabel`;
export const leafValidationCheckboxLabel = `${prefix}.leafValidationCheckboxLabel`;
export const certificateAuthorityHeader = `${prefix}.certificateAuthorityHeader`;
export const certificateAuthoritiesLabel = `${prefix}.certificateAuthoritiesLabel`;
export const certificateRevocationListHeader = `${prefix}.certificateRevocationListHeader`;
export const allowedPKIErrors = `${prefix}.allowedPKIErrors`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit PKI Profile',
    [modalTitleNew]: 'New PKI Profile',
    [ignorePeerChainCheckboxLabel]: 'Ignore Peer Chain',
    [enableCrlCheckCheckboxLabel]: 'Enable CRL Check',
    [leafValidationCheckboxLabel]: 'Leaf Certificate CRL validation only',
    [certificateAuthorityHeader]: 'Certificate Authority (CA)',
    [certificateAuthoritiesLabel]: 'Certificate Authorities ({0})',
    [certificateRevocationListHeader]: 'Certificate Revocation List (CRL)',
    [allowedPKIErrors]: 'Allowed PKI Errors',
};
