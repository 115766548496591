/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'dns-resolver-nameservers-grid';
const prefix = `${moduleName}.${componentName}`;

export const addressInputPlaceholder = `${prefix}.addressInputPlaceholder`;
export const columnTitleAddress = `${prefix}.columnTitleAddress`;

export const ENGLISH = {
    [addressInputPlaceholder]: 'Enter Name Server IP address',
    [columnTitleAddress]: 'Name Server IP',
};
