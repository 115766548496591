/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { IAwsAvailabilityZone }
    from 'ajs/modules/ipam/factories/ipam-dns-aws-profile.config-item.factory';
import { IAwsZoneNetwork } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './ipam-dns-aws-usable-networks-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @descriptionGrid
 *     Component for AWS UsableNetworks (IpamDnsAwsProfile#zones) grid configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-aws-usable-networks-grid',
    templateUrl: './ipam-dns-aws-usable-networks-grid.component.html',
})
export class IpamDnsAwsUsableNetworksGridComponent implements OnInit, OnChanges, AfterViewInit {
    /**
     * Binding for IpamDnsAwsProfileConfigItem#config#zones.
     */
    @Input()
    public zones: RepeatedMessageItem<MessageItem<IAwsZoneNetwork>>;

    /**
     * List of AWS AvailabilityZones.
     */
    @Input()
    public availablilityZoneList: IAwsAvailabilityZone[];

    /**
     * True if credentials are connected.
     */
    @Input()
    public connected = false;

    /**
     * Fires on new add zone.
     */
    @Output()
    public onAddZone: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on delete of zones.
     */
    @Output()
    public onDeleteZone = new EventEmitter<MessageItem<IAwsZoneNetwork>>();

    /**
     * TemplateRef for Availability-Zone dropdown.
     */
    @ViewChild('availabilityZoneTemplateRef')
    public availabilityZoneTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for Usable-Network dropdown.
     */
    @ViewChild('usableNetworkTemplateRef')
    public usableNetworkTemplateRef: TemplateRef<HTMLElement>;

    /**
     * AWS UsableNetwork Grid configuration.
     */
    public awsUsableNetworksGridConfig: IAviDataGridConfig;

    /**
     * Prefix for Availability-Zone dropdown.
     */
    public readonly availabilityZoneRowPrefix = 'availability_zone_';

    /**
     * Prefix for Usable-Network dropdown.
     */
    public readonly usableNetworkRowPrefix = 'usable_network_';

    /**
     * Availability-Zone dropdown options.
     */
    public availabilityZoneDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Usable-Network dropdown options.
     * Hash of availability-zone and its subnet options.
     */
    public usableNetworkDropdownOptionsHash: Record<string, IAviDropdownOption[]> = {};

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setAvailabilityZoneDropdownOptions();
        this.setUsableNetworkDropdownOptionsHash();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { availablilityZoneList } = changes;

        if (availablilityZoneList && !availablilityZoneList.firstChange) {
            this.setAvailabilityZoneDropdownOptions();
            this.setUsableNetworkDropdownOptionsHash();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.awsUsableNetworksGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleAvailabilityZone),
                id: 'availaility_zone',
                templateRef: this.availabilityZoneTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleUsableNetwork),
                id: 'usable_network_uuids',
                templateRef: this.usableNetworkTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (zones: Array<MessageItem<IAwsZoneNetwork>>) => {
                    zones.forEach((zone: MessageItem<IAwsZoneNetwork>) => {
                        this.deleteZone(zone);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (zone: MessageItem<IAwsZoneNetwork>) => {
                    this.deleteZone(zone);
                },
            }],
        };
    }

    /**
     * Emits Add Zone event.
     */
    public addZone(): void {
        this.onAddZone.emit();
    }

    /**
     * Emits Delete Zone event.
     */
    private deleteZone(zone: MessageItem<IAwsZoneNetwork>): void {
        this.onDeleteZone.emit(zone);
    }

    /**
     * Sets usableNetworkDropdownOptionsHash.
     */
    private setUsableNetworkDropdownOptionsHash(): void {
        if (!Array.isArray(this.availablilityZoneList)) {
            return;
        }

        // Resetting it.
        this.usableNetworkDropdownOptionsHash = {};

        this.availablilityZoneList.forEach(
            ({ availability_zone: availabilityZone, subnets = [] }) => {
                const subnetOptions = subnets.map(({ id, name }) => createDropdownOption(id, name));

                this.usableNetworkDropdownOptionsHash[availabilityZone] = subnetOptions;
            },
        );
    }

    /**
     * Sets availabilityZoneDropdownOptions.
     */
    private setAvailabilityZoneDropdownOptions(): void {
        if (!Array.isArray(this.availablilityZoneList)) {
            return;
        }

        this.availabilityZoneDropdownOptions =
            this.availablilityZoneList.map(({ availability_zone: availabilityZone }) => {
                return createDropdownOption(availabilityZone);
            });
    }
}
