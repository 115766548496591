/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing cloud configuration components shared between cloud modals.
 *
 * Before CloudSharedModule, CloudModalComponent contained child components like
 * IpamDnsConfigurationComponent and DnsResolversGridComponent used to configure fields on the Cloud
 * object.
 *
 * VmcDeploymentNsxtCloudModalComponent was added as a cloud modal that shows a limited number of
 * settings based on VMC deployment. VmcDeploymentNsxtCloudModalComponent is lazy-loaded, and in
 * order to avoid importing the massive CloudModule for IpamDnsConfigurationComponent,
 * CloudSharedModule was created to be importable by both.
 *
 * @module CloudSharedModule
 * @author alextsg
 * @preferred
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    DnsResolverGridComponent,
    DnsResolverModalComponent,
    DnsResolverNameserversGridComponent,
    IpamDnsConfigurationComponent,
} from './components';

const cloudSharedComponents = [
    DnsResolverGridComponent,
    DnsResolverModalComponent,
    DnsResolverNameserversGridComponent,
    IpamDnsConfigurationComponent,
];

@NgModule({
    declarations: [
        ...cloudSharedComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        DataGridModule,
        SharedModule,
    ],
    exports: [
        DnsResolverGridComponent,
        DnsResolverModalComponent,
        IpamDnsConfigurationComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class CloudSharedModule {}
