/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    AfterViewInit,
    Component,
    forwardRef,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import {
    DropdownModelSingleValue,
} from 'ng/shared/components/avi-dropdown/avi-dropdown.types';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';

import * as globalL10n from 'global-l10n';

const { ...globalL10nKeys } = globalL10n;

export type TCollectionDropdownRow = {
    value: DropdownModelSingleValue;
};

/**
 * @description
 *     Component that retrieves options from a Collection and pass the data to AviDataGrid.
 *
 * @example
 *     <avi-repeated-collection-dropdowns-grid
 *         gridTitle="Avi Repeated Collection Dropdowns Grid"
 *         name="avirepeatedcollectiondropdownsgrid"
 *         columnLabel="Column Title"
 *         placeholder="Select Value"
 *         [collection]="collection"
 *         hideSearch="false"
 *         multiple="false"
 *         readonly="false"
 *         exclude="['item#1', 'item#2']"
 *         maxRows="10"
 *     ></avi-repeated-collection-dropdowns-grid>
 *
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'avi-repeated-collection-dropdowns-grid',
    templateUrl: './avi-repeated-collection-dropdowns-grid.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedCollectionDropdownsGridComponent),
        },
    ],
})
// eslint-disable-next-line max-len
export class AviRepeatedCollectionDropdownsGridComponent implements AfterViewInit, ControlValueAccessor {
    /**
     * Placeholder for Dropdown.
     */
    @Input()
    public placeholder = '';

    /**
     * AviDataGrid column label.
     */
    @Input()
    public columnLabel = '';

    /**
     * Collection instance.
     */
    @Input()
    public collection: Collection;

    /**
     * Check for allowing duplicate values.
     */
    @Input()
    public multiple = false;

    /**
     * Option to Hide Search in dropdown.
     */
    @Input()
    public hideSearch = false;

    /**
     * Grid Title to be passed to datagrid.
     */
    @Input()
    public gridTitle = '';

    /**
     * Flag to show a value as readonly. Differs from disabled in that the input field will be
     * displayed similarly to a label.
     */
    @Input()
    public readonly = false;

    /**
     * Maximum rows allowed in grid.
     */
    @Input()
    public maxRows = Infinity;

    /**
     * Optional, Exclude the selected values from the options.
     */
    @Input()
    public excludeSelected = false;

    /**
     * This will be added as prefix for each dropdown row.
     */
    @Input()
    public name = 'repeated-collection-dropdown';

    /**
     * Template ref for dropdown input row.
     */
    @ViewChild('collectionDropdownTemplateRef')
    public readonly collectionDropdownTemplateRef: TemplateRef<HTMLElement>;

    /**
     * dropdownRows, it is passed to the AviDataGrid rows.
     */
    public dropdownRows: TCollectionDropdownRow[] = [];

    /**
     * Repeated Dropdowns Grid Config.
     */
    public repeatedDropdownsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * To check for maximum allowed values in grid.
     */
    public get hasMaxRows(): boolean {
        return this.dropdownRows.length >= this.maxRows;
    }

    /**
     * The ngModel value.
     */
    private modelValue: DropdownModelSingleValue[];

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) { }

    /**
     * @override
     * Setting Repeated Dropdown Grid Config.
     */
    public ngAfterViewInit(): void {
        this.repeatedDropdownsGridConfig = {
            fields: [{
                label: this.columnLabel,
                id: 'repeated-collection-dropdown',
                templateRef: this.collectionDropdownTemplateRef,
            }],
            multipleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (dropdownRows: TCollectionDropdownRow[]) => {
                    dropdownRows.forEach((dropdownRow: TCollectionDropdownRow) => {
                        this.deleteDropdownRow(dropdownRow);
                    });
                },
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * DropdownRow addition to AviDataGrid.
     */
    public addDropdown(): void {
        this.dropdownRows.push({ value: undefined });

        this.updateModelValue(this.dropdownRows);
    }

    /**
     * DropdownRow deletion from AviDataGrid.
     */
    public deleteDropdownRow(dropdownRow: TCollectionDropdownRow): void {
        const index = this.dropdownRows.indexOf(dropdownRow);

        this.dropdownRows.splice(index, 1);
        this.updateModelValue(this.dropdownRows);
    }

    /**
     * Modal Value Update on Modal Change.
     */
    public handleRowModelChange(): void {
        this.updateModelValue(this.dropdownRows);
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: DropdownModelSingleValue[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(values: DropdownModelSingleValue[]): void {
        this.modelValue = values;

        this.setRepeatedDropdowns(values);
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private onChange = (value: DropdownModelSingleValue[]): void => { };

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => { };

    /*************************************************************************/

    /**
     * Set Dropdown Rows to be passed to AviDataGrid rows.
     */
    private setRepeatedDropdowns(values: DropdownModelSingleValue[]): void {
        values = values || [];

        this.dropdownRows = values.map((item: DropdownModelSingleValue): TCollectionDropdownRow => {
            return { value: item };
        });
    }

    /**
     * Updates modelValue and emits model change event.
     */
    private updateModelValue(dropdownRows: TCollectionDropdownRow[]): void {
        this.modelValue = dropdownRows.length ? dropdownRows.map(item => item.value) : [];

        if (this.excludeSelected) {
            const selectedValues = this.modelValue
                .filter(value => Boolean(value))
                .map((value: string) => this.stringService.slug(value));

            this.collection.setParams({
                'uuid.in': selectedValues.join(),
                exclude: 'uuid.in',
            });
        }

        this.onChange(this.modelValue);
        this.onTouched();
    }
}
