/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
    IRowReorder,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { AuthorizationRuleConfigItem }
    from 'ajs/modules/sso-policy/factories/authorization-rule.config-item.factory';
import * as l10n from './authorization-rule-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc Authorization Rules Grid component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'authorization-rule-grid',
    templateUrl: './authorization-rule-grid.component.html',
})
export class AuthorizationRuleGridComponent implements AfterViewInit {
    /**
     * AuthorizationRule RepeatedMessageItem instance.
     */
    @Input()
    public authRules: RepeatedMessageItem<AuthorizationRuleConfigItem>;

    /**
     * Fires on new Authorization rule.
     */
    @Output()
    public onAddAuthRule: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit Authorization rule.
     */
    @Output()
    public onEditAuthRule = new EventEmitter<AuthorizationRuleConfigItem>();

    /**
     * Fires on delete/deleteAll Authorization rule.
     */
    @Output()
    public onDeleteAuthRule = new EventEmitter<AuthorizationRuleConfigItem>();

    /**
     * Fires on drag-drop of a rule.
     */
    @Output()
    public onRowOrderChange = new EventEmitter<IRowReorder>();

    /**
     * Match Expander templateRef.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * AuthorizationRules Grid config.
     */
    public authorizationRuleGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.authorizationRuleGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleName),
                id: 'name',
                transform(rule: AuthorizationRuleConfigItem) {
                    return rule.config.name;
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (rules: AuthorizationRuleConfigItem[]) => {
                    rules.forEach((rule: AuthorizationRuleConfigItem) => {
                        this.deleteAuthRule(rule);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (rule: AuthorizationRuleConfigItem) => this.editAuthRule(rule),
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (rule: AuthorizationRuleConfigItem) => this.deleteAuthRule(rule),
            }],
            layout: {
                withReordering: true,
            },
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    /**
     * Emits Add new rule event.
     */
    public addAuthRule(): void {
        this.onAddAuthRule.emit();
    }

    /**
     * Emits rule reorder event.
     */
    public handleRowReorder(reorderInfo: IRowReorder): void {
        this.onRowOrderChange.emit(reorderInfo);
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number, row: AuthorizationRuleConfigItem): number {
        return row.config.index;
    }

    /**
     * Emits Edit rule event.
     */
    private deleteAuthRule(rule: AuthorizationRuleConfigItem): void {
        this.onDeleteAuthRule.emit(rule);
    }

    /**
     * Emits Delete rule event.
     */
    private editAuthRule(rule: AuthorizationRuleConfigItem): void {
        this.onEditAuthRule.emit(rule);
    }
}
