/**
 * @module CloudSharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import {
    DnsResolverConfigItem,
} from 'ajs/modules/cloud/factories/dns-resolver.config-item.factory';
import * as globalL10n from 'global-l10n';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *
 *   DNS Resolver grid component.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'dns-resolver-grid',
    templateUrl: './dns-resolver-grid.component.html',
})
export class DnsResolverGridComponent implements OnInit {
    /**
     * DNS Resolver repeated message item.
     */
    @Input()
    public dnsResolvers: RepeatedMessageItem<DnsResolverConfigItem>;

    /**
     * Fires on add DNS Resolver.
     */
    @Output()
    public onAddDnsResolver = new EventEmitter<void>();

    /**
     * Fires on edit DNS Resolver.
     */
    @Output()
    public onEditDnsResolver = new EventEmitter<DnsResolverConfigItem>();

    /**
     * DNS Resolver grid config.
     */
    public dnsResolverGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {}

    /**
     * @override
     */
    public ngOnInit(): void {
        const { l10nService, globalL10nKeys } = this;

        this.dnsResolverGridConfig = {
            fields: [{
                label: l10nService.getMessage(globalL10nKeys.nameLabel),
                id: 'name',
                transform(dnsResolver: DnsResolverConfigItem) {
                    return dnsResolver.getName() || '-';
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (dnsResolverList: DnsResolverConfigItem[]) => {
                    dnsResolverList.forEach((dnsResolver: DnsResolverConfigItem) => {
                        this.deleteDnsResolver(dnsResolver);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (dnsResolver: DnsResolverConfigItem) => {
                    this.editDnsResolver(dnsResolver);
                },
            }, {
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (dnsResolver: DnsResolverConfigItem) => {
                    return this.deleteDnsResolver(dnsResolver);
                },
            }],
        };
    }

    /**
     * Handler for DNS Resolver add operation.
     */
    public addDnsResolver(): void {
        this.onAddDnsResolver.emit();
    }

    /**
     * Handler for DNS Resolver edit operation.
     */
    private editDnsResolver(dnsResolver: DnsResolverConfigItem): void {
        this.onEditDnsResolver.emit(dnsResolver);
    }

    /**
     * Handler for DNS Resolver delete operation.
     */
    private deleteDnsResolver(dnsResolver: DnsResolverConfigItem): void {
        this.dnsResolvers.removeByMessageItem(dnsResolver);
    }
}
