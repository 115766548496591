/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ErrorPageModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ErrorPageConfigItem } from 'ajs/modules/error-page';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './error-page-profile-preview.l10n';
import './error-page-profile-preview.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Component to show the index of the Error Page in the full modal preview.
 * @author Ratan Kumar
 */
@Component({
    selector: 'error-page-profile-preview',
    templateUrl: './error-page-profile-preview.component.html',
})
export class ErrorPageProfilePreviewComponent implements OnInit {
    /**
     * Error page Item currently opened in Error page profile modal.
     */
    @Input()
    public readonly editable: ErrorPageConfigItem;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    // Get Index of error page which is opened.
    public index: number;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.index = this.editable.pageIndex;
    }
}
