/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { GeoDbMappingElementConfigItem } from 'ajs/modules/geo-db';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import * as l10n from './geo-db-mapping-element-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Geo DB mapping Element Grid component.
 *
 * @author Rachit Aggarwal
 */

@Component({
    selector: 'geo-db-mapping-element-grid',
    templateUrl: './geo-db-mapping-element-grid.component.html',
})
export class GeoDbMappingElementGridComponent implements OnInit {
    /**
     * GeoDbMappingElement RepeatedMessageItem instance.
     */
    @Input()
    public geoDbMappingElements: RepeatedMessageItem<GeoDbMappingElementConfigItem>;

    /**
     * Fires on add Geo Db Mapping.
     */
    @Output()
    public onAddGeoDbMappingElement = new EventEmitter();

    /**
     * Fires on remove Geo Db Mapping Element.
     */
    @Output()
    public onRemoveGeoDbMappingElement = new EventEmitter<GeoDbMappingElementConfigItem>();

    /**
     * Fires on edit Geo Db Mapping Element.
     */
    @Output()
    public onEditGeoDbMappingElement = new EventEmitter<GeoDbMappingElementConfigItem>();

    /**
     * Geo Db mapping Element grid config.
     */
    public geoDbMappingElementGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        const { l10nService, l10nKeys, schemaService } = this;

        this.geoDbMappingElementGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.geoDbMappingElementColTitleAttribute),
                    id: 'attribute',
                    transform(element: GeoDbMappingElementConfigItem) {
                        return schemaService.getEnumValue('GeoMappingAttribute',
                            element.getConfig().attribute).label;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.geoDbMappingElementColTitleValues),
                    id: 'values',
                    transform(element: GeoDbMappingElementConfigItem) {
                        return `${element.getConfig().values.length}`;
                    },
                },
            ],
            multipleactions: [{
                label: this.l10nService.getMessage(
                    this.l10nKeys.geoDbMappingElementRemoveButtonLabel,
                ),
                onClick: (geoDbMappingElementList: GeoDbMappingElementConfigItem[]) => {
                    geoDbMappingElementList
                        .forEach((geoDbMappingElementItem: GeoDbMappingElementConfigItem) => {
                            this.removeGeoDbMappingElementList(geoDbMappingElementItem);
                        });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.geoDbMappingElementEditButtonLabel),
                shape: 'pencil',
                onClick: (geoDbMappingItem: GeoDbMappingElementConfigItem) => {
                    this.editGeoDbMappingElementList(geoDbMappingItem);
                },
            }, {
                label: this.l10nService.getMessage(
                    this.l10nKeys.geoDbMappingElementRemoveButtonLabel,
                ),
                shape: 'trash',
                onClick: (geoDbMappingItem: GeoDbMappingElementConfigItem) => {
                    this.removeGeoDbMappingElementList(geoDbMappingItem);
                },
            }],
        };
    }

    /**
     * Handler for Geo Db mapping Element add operation.
     */
    public addGeoDbMappingElementList(): void {
        this.onAddGeoDbMappingElement.emit();
    }

    /**
     * Handler for Geo Db mapping Element remove operation.
     */
    private removeGeoDbMappingElementList(
        geoDbMappingElementItem: GeoDbMappingElementConfigItem,
    ): void {
        this.onRemoveGeoDbMappingElement.emit(geoDbMappingElementItem);
    }

    /**
     * Handler for Geo Db mapping Element edit operation.
     */
    private editGeoDbMappingElementList(
        geoDbMappingElementItem: GeoDbMappingElementConfigItem,
    ): void {
        this.onEditGeoDbMappingElement.emit(geoDbMappingElementItem);
    }
}
