/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid';

import {
    WafProfileCollection,
} from 'ajs/modules/waf/factories/waf-profile/waf-profile.collection.factory';

import * as l10n from './waf-profile-list.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafProfileCollection = typeof WafProfileCollection;

/**
 * @description Waf Profile list page.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'waf-profile-list',
    templateUrl: './waf-profile-list.component.html',
})
export class WafProfileListComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Waf Profile Collection Object.
     */
    public readonly wafProfileCollection: WafProfileCollection;

    /**
     * Waf Profile grid config object.
     */
    public wafProfileGridConfig: IAviCollectionDataGridConfig;

    constructor(
        public readonly l10nService: L10nService,
        @Inject(WafProfileCollection)
        WafProfileCollection: TWafProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.wafProfileCollection = new WafProfileCollection();
    }

    /**
     * @override
     * Setting wafProfileGridConfig for Waf Profile List.
     */
    public ngOnInit(): void {
        const { objectName } = this.wafProfileCollection;

        this.wafProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.wafProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                transform: (row: IAviCollectionDataGridRow) => row.getName(),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.gridPlaceholder),
            },
        };
    }

    /**
     * @override
     * Destroying wafProfile Collection.
     */
    public ngOnDestroy(): void {
        this.wafProfileCollection.destroy();
    }
}
