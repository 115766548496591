/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { PKIProfileCollection }
    from 'ajs/modules/security/factories/pki-profile/pki-profile.collection.factory';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
    IAviDataGridConfig,
    IAviDataGridRow,
} from 'ng/modules/data-grid';

import * as globalL10n from 'global-l10n';
import * as l10n from './pki-profile-list-page.l10n';
import './pki-profile-list-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TPKIProfileCollection = typeof PKIProfileCollection;

/**
 * PKI Profile List Page.
 * @author Manideep Kotha
 */

@Component({
    selector: 'pki-profile-list-page',
    templateUrl: './pki-profile-list-page.component.html',
})
export class PkiProfileListPageComponent {
    /**
     * PKI profile expander template.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Cert Text template inside avi-data-grid
     */
    @ViewChild('certTextTemplateRef')
    public certTextTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the PKI profile collection.
     */
    public pkiProfileCollection: PKIProfileCollection;

    /**
     * Configuration object to display PKI profile collection.
     */
    public pkiProfileListPageGridConfig: IAviCollectionDataGridConfig;

    /**
     * DataGrid config for the CA Certificate.
     */
    public caGridConfig: IAviDataGridConfig;

    /**
     * DataGrid config for the Crl Certificate.
     */
    public crlGridConfig: IAviDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(PKIProfileCollection)
        private readonly PKIProfileCollection: TPKIProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.pkiProfileCollection = new PKIProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.pkiProfileCollection;

        this.pkiProfileListPageGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.pkiProfileCollection,
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.placeholderMessage),
            },
        };
    }

    /**
     * Creates the attributes of the grid
     */
    public getPkiChildGridConfig(type: string): IAviDataGridConfig {
        const tempConfig: IAviDataGridConfig = {
            fields: [],
            layout: {
                hideSearch: true,
            },
            expandedContentTemplateRef: this.certTextTemplateRef,
        };

        switch (type) {
            case 'ca':
                tempConfig.fields.push({
                    id: 'subject_name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviDataGridRow) => row.config.subject.config.common_name,
                }, {
                    id: 'issuer_name',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleIssuedBy),
                    transform: (row: IAviDataGridRow) => row.config.issuer.config.common_name,
                }, {
                    id: 'not_after',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleExpirationDate),
                    transform: (row: IAviDataGridRow) => row.config.not_after,
                });

                tempConfig.getUniqueRowId = (row: IAviDataGridRow) => [
                    row.config.subject.config.common_name,
                    row.config.issuer.config.common_name,
                    row.config.not_after,
                ].join();

                break;
            case 'crl':
                tempConfig.fields.push({
                    id: 'common_name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviDataGridRow) => row.config.distinguished_name,
                }, {
                    id: 'next_update',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleExpirationDate),
                    transform: (row: IAviDataGridRow) => row.config.next_update,
                });

                tempConfig.getUniqueRowId = (row: IAviDataGridRow) => [
                    row.config.distinguished_name,
                    row.config.next_update,
                ].join();

                break;
        }

        return tempConfig;
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.pkiProfileListPageGridConfig = {
            ...this.pkiProfileListPageGridConfig,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                },
                {
                    id: 'ca',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleCaCertification),
                    transform: (row: IAviCollectionDataGridRow) => row.getConfig().ca_certs.count,
                },
                {
                    id: 'crl',
                    label: this.l10nService.getMessage(
                        l10nKeys.columnTitleCertificationRevocationList,
                    ),
                    transform: (row: IAviCollectionDataGridRow) => {
                        const { crls } = row.getConfig();
                        const { config } = crls;

                        return config?.length;
                    },

                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };

        this.caGridConfig = this.getPkiChildGridConfig('ca');
        this.crlGridConfig = this.getPkiChildGridConfig('crl');
    }

    /** @override */
    public ngOnDestroy(): void {
        this.pkiProfileCollection.destroy();
    }
}
