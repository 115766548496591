/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import moment from 'moment';
import { StringService } from 'ajs/modules/core/services/string-service';
import {
    IIPReputationDBFile,
    IPReputationDB,
} from 'ajs/modules/ip-reputation-db';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { AviDatePipe } from 'ng/modules/core/pipes';
import * as l10n from './ip-reputation-db-list-page-card.l10n';
import './ip-reputation-db-list-page-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for a card containing information about an IP Reputation DB Item.
 * @author alextsg
 */
@Component({
    selector: 'ip-reputation-db-list-page-card',
    templateUrl: './ip-reputation-db-list-page-card.component.html',
})
export class IPReputationDBListPageCardComponent implements OnInit {
    /**
     * IP Reputation DB item.
     */
    @Input()
    public ipReputationDB: IPReputationDB;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Grid config for the datagrid containing a list of files.
     */
    public readonly filesGridConfig: IAviDataGridConfig;

    /**
     * List of files to be shown in the datagrid.
     */
    public files: IIPReputationDBFile[] = [];

    /**
     * Timestamp of the date and time last updated.
     */
    public lastUpdate: string;

    constructor(
        l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly aviDatePipe: AviDatePipe,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.filesGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.filenameLabel),
                    id: 'filename',
                    transform(file: IIPReputationDBFile) {
                        return stringService.name(file.ref);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.fileTypeLabel),
                    id: 'type',
                    transform(file: IIPReputationDBFile) {
                        return stringService.enumeration(file.type);
                    },
                },
            ],
            getUniqueRowId(row: IIPReputationDBFile) {
                return row.ref;
            },
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngOnInit(): void {
        this.files = this.ipReputationDB.files;
        this.lastUpdate = this.getLastUpdate();
    }

    /**
     * Returns a human-readable version of the vendor.
     */
    public getVendor(): string {
        return this.stringService.enumeration(
            this.ipReputationDB.config.vendor,
            'IP_REPUTATION_VENDOR_',
        );
    }

    /**
     * Returns the lastUpdate string. If lastUpdate does not exist on the IPReputationDB item,
     * returns undefined.
     */
    private getLastUpdate(): string | undefined {
        const { lastUpdate } = this.ipReputationDB;

        if (!lastUpdate) {
            return;
        }

        const lastUpdateStr = moment(lastUpdate).toISOString();

        return this.aviDatePipe.transform(lastUpdateStr);
    }
}
