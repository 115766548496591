/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHttpResponse } from 'angular';

import {
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';

import { FormatBytesPipe } from 'ng/shared/pipes';
import { AviDatePipe } from 'ng/modules/core/pipes';

import {
    IAviDataGridConfig,
    IAviDataGridConfigField,
    IAviDataGridRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { Case } from 'ajs/modules/cportal';
import { StringService } from 'ajs/modules/core/services/string-service';
import { L10nService } from '@vmw/ngx-vip';

import {
    FileType,
    IFileServiceAttachment,
} from '../add-attachments-grid';

import {
    CaseAttachmentsService,
    IFileServiceApiErrorResponse,
    IFileServiceApiResponse,
} from '../../services';

import './attachments-selection-dialog.component.less';
import * as l10n from './attachments-selection-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Attachments selection dialog component to select the attachments
 *      present on the controller for given file type to be attached to the case.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'attachments-selection-dialog',
    templateUrl: './attachments-selection-dialog.component.html',
    providers: [CaseAttachmentsService],
})
export class AttachmentsSelectionDialogComponent implements OnInit {
    /**
     * Fires on clicking add button on the dialog.
     */
    @Output()
    public onConfirm = new EventEmitter<void>();

    /**
     * Fires on clicking cancel button on the dialog.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on dialog close.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Case Item currently opened in case modal.
     */
    public editable: Case;

    /**
     * File type for which attachments present on the controller to be fetched.
     */
    public fileType: FileType;

    /**
     * Attachments selection dialog header.
     */
    public attachmentsSelectionDialogHeader: string;

    /**
     * DataGrid config to show available attachments present on the controller.
     */
    public attachmentsGridConfig: IAviDataGridConfig;

    /**
     * Attachments present on the controller system.
     */
    public availableAttachments: IFileServiceAttachment[] = [];

    /**
     * Set to true while loading the attachments data.
     * Used to show the spinner while loading the attachments data.
     */
    public isAttachmentsLoading = false;

    /**
     * Used to show error message if any.
     */
    public error: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Selected attachments from the data grid.
     */
    private selectedAttachments: IFileServiceAttachment[] = [];

    /**
     * Date column field for attachmentsGridConfig.
     */
    private dateColumnField: IAviDataGridConfigField;

    /**
     * Size column field for attachmentsGridConfig.
     */
    private sizeColumnField: IAviDataGridConfigField;

    /**
     * Name column field for attachmentsGridConfig.
     */
    private nameColumnField: IAviDataGridConfigField;

    constructor(
        private readonly l10nService: L10nService,
        private readonly aviDatePipe: AviDatePipe,
        private readonly formatBytesPipe: FormatBytesPipe,
        private readonly stringService: StringService,
        private readonly caseAttachmentsService: CaseAttachmentsService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setAvailableAttachments();

        this.setAttachmentsGridConfig();
    }

    /**
     * Returns the count of available attachments.
     */
    public get availableAttachmentsCount(): number {
        return this.availableAttachments?.length || 0;
    }

    /**
     * Handler for add button click.
     */
    public handleAddFilesAttachments(): void {
        // Get the attachments which are already added to the add attachments grid.
        const { filesToAttach } = this.editable;

        filesToAttach.push(...this.selectedAttachments);

        // Update filesToAttach with latest selected attachments.
        this.editable.filesToAttach = filesToAttach;

        this.onConfirm.emit();
        this.onClose.emit();
    }

    /**
     * Handler for cancel and close(X) button click.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.onClose.emit();
    }

    /**
     * Listen to attachment selection change event in the grid row.
     * Used to set selected attachments.
     */
    public onAttachmentsSelectionChange(selectedRows: IAviDataGridRow[]): void {
        this.selectedAttachments = selectedRows;
    }

    /**
     * Set available attachments present on the controller system for the given file type.
     */
    private setAvailableAttachments(): void {
        this.isAttachmentsLoading = true;

        this.caseAttachmentsService.loadAvailableAttachmentsList(this.fileType)
            .then(({ data: { results } }: IHttpResponse<IFileServiceApiResponse>) => {
                // Set availableAttachments with the attachments,
                // which are not added to filesToAttach.
                this.availableAttachments = results
                    .filter(({ url }: IFileServiceAttachment) => {
                        return !this.editable.isAttachmentAdded(url);
                    });
            })
            .catch(({ error }: IFileServiceApiErrorResponse) => {
                this.error = error;
            })
            .finally(() => {
                this.isAttachmentsLoading = false;
            });
    }

    /**
     * Set attachments grid config.
     */
    private setAttachmentsGridConfig(): void {
        const { l10nService } = this;

        this.attachmentsGridConfig = {
            fields: [],
        };

        // dateColumnField, nameColumnField and sizeColumnField will be
        // reused in grid of different file types.
        this.dateColumnField = {
            label: l10nService.getMessage(l10nKeys.dateColumnLabel),
            id: 'date',
            transform: ({ modified }: IFileServiceAttachment) => {
                return this.aviDatePipe.transform(modified);
            },
        };

        this.nameColumnField = {
            label: l10nService.getMessage(l10nKeys.nameColumnLabel),
            id: 'name',
            transform: ({ url }: IFileServiceAttachment) => {
                return this.stringService.slug(url) || '-';
            },
        };

        this.sizeColumnField = {
            label: l10nService.getMessage(l10nKeys.sizeColumnLabel),
            id: 'size',
            transform: ({ size }: IFileServiceAttachment) => {
                return this.formatBytesPipe.transform(size);
            },
        };

        switch (this.fileType) {
            case FileType.TECH_SUPPORT:
                this.setAttachmentsGridConfigFieldsForTechSupport();
                break;
            case FileType.VS_PCAP:
            case FileType.ARCHIVE:
            case FileType.UPLOADS:
                this.setAttachmentsGridConfigFields();
                break;
        }
    }

    /**
     * Set attachments grid config fields for tech support file type.
     */
    private setAttachmentsGridConfigFieldsForTechSupport(): void {
        const { l10nService } = this;

        this.attachmentsGridConfig.fields = [
            {
                ...this.dateColumnField,
            },
            {
                label: l10nService.getMessage(l10nKeys.typeColumnLabel),
                id: 'type',
                transform: ({ status: { level } }: IFileServiceAttachment) => {
                    return this.stringService.capitalize(level);
                },
            },
            {
                ...this.nameColumnField,
            },
            {
                label: l10nService.getMessage(l10nKeys.durationColumnLabel),
                id: 'duration',
                transform({ status: { duration } }: IFileServiceAttachment) {
                    return duration;
                },
            },
            {
                ...this.sizeColumnField,
            },
        ];
    }

    /**
     * Set attachments grid config fields for Traffic Capture, Core and Uploads file types.
     */
    private setAttachmentsGridConfigFields(): void {
        this.attachmentsGridConfig.fields = [
            this.dateColumnField,
            this.nameColumnField,
            this.sizeColumnField,
        ];
    }
}
