/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import {
    IHttpPromise,
    IHttpResponse,
    IHttpService,
    IIntervalService,
    IPromise,
    IRootScopeService,
    ITimeoutService,
    IWindowService,
} from 'angular';

import { Subject } from 'rxjs';

import {
    findWhere,
    isEmpty,
    isString,
    throttle,
} from 'underscore';

import {
    StateService,
    TargetState,
    TransitionPromise,
} from '@uirouter/core';

import {
    ActiveUserProfileService,
    DefaultValues,
    HorizonIframeService,
    IActiveUserProfile,
    IHorIframeInitialState,
    InitialDataService,
    SchemaService,
    StateAccessMapService,
    StringService,
} from 'ajs/modules/core/services';

import {
    DevLoggerService,
    StatePermissionTreeService,
    TenantService,
} from 'ng/modules/core';

import {
    IPermission,
    ITenant,
    Privilege,
} from 'generated-types';

import { utc } from 'moment';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { appStates } from 'ajs/js/constants/app-config/app-state.constants';
import { IAppStatePermissionTree } from 'ajs/js/services/appStates.types';
import { NgZone } from '@angular/core';

import {
    ADDRESSED_RELOAD_REQUESTS,
    ADMIN_TENANT_NAME,
    ADMIN_USER_SETUP_URL,
    LOGIN_URL,
    LOGOUT_URL,
    PASS_CHANGE_REQ_URL,
    PASS_CHANGE_URL,
    SESSION_REFRESH_URL,
    SET_TENANT_URL,
    STORAGE_LAST_ACTIVE_STATE,
    STORAGE_LAST_USER_ACTIVITY,
    STORAGE_USER_PROFILE_LOCAL_STORAGE,
    USER_ACTIVITY_STEP,
} from '../../core.constants';

import {
    IAdminUserSetupPayload,
    IAuthContext,
    IAuthInitialState,
    ILastActiveState,
    ILoginPayload,
    IStateParams,
    TAddressedRequestsHash,
    TPrivilegeAccessMap,
} from './auth.types';

import { ACTIVE_USER_PROFILE_SERVICE_TOKEN } from '../active-user-profile';

const {
    DEFAULT_STATE,
    LOGGED_OUT_STATE,
    LOGIN_STATE,
    PLEASE_RELOAD_STATE,
    WELCOME_STATE,
} = appStates;

const stringServiceToken = 'stringService';

/**
 * Ajs dependency token for Auth service.
 */
export const AUTH_SERVICE_TOKEN = 'Auth';

/**
 * Local storage properties to be reset by auth service.
 */
const localStorageProps = [
    STORAGE_USER_PROFILE_LOCAL_STORAGE,
    STORAGE_LAST_USER_ACTIVITY,
    ADDRESSED_RELOAD_REQUESTS,
    'controllerFaults', // FIXME move to FaultService
];

/**
 * Access privilege levels.
 */
const objectAccessLevels = {
    [Privilege.NO_ACCESS]: 0,
    [Privilege.READ_ACCESS]: 1,
    [Privilege.WRITE_ACCESS]: 2,
};

/**
 * @description
 *
 *     Authentication, permissions and app initialization service.
 *     Handles login/logout, user permissions (aka context),
 *     list of tenants avail and user profile.
 *
 *     Important part of authService's state is current user's "profile". Such profile is an
 *     object returned by login API call which includes:
 *      - corresponding user config object along with the list of tenants available for this user
 *        (also available via api/user-tenant-list API)
 *      - system configuration (api/systemconfiguration)
 *      - controller.api_idle_timeout property
 *      - version information (api/initial-data)
 *      - and a little bit more
 *
 *     Having user's profile set is not sufficient for proper UI functioning since it is laking
 *     user's permissions mapping. Since permissions are defined within user&tenant scope before
 *     loading permissions we need to figure which one of user's tenants should be selected as
 *     active.
 *
 *     Hence full authentication cycle can be described as:
 *      - get user's profile (most often involves POST on /login) and set it on this.profile
 *      - set sessionId and crsfToken HTTP call headers. This step is taken care of by angularJs
 *        itself, see app-config.js file
 *      - figure out what tenant should be selected as currently active for the user
 *      - set 'X-Avi-Tenant' header value to active tenant
 *      - make switch-to-tenant?tenantName=<activeTenantName> API call to get permissions mapping
 *      - put active tenant and permission on this.context and do some computation based on the
 *        mapping received and notify third-party services of potentially changed user's
 *        permissions
 *
 *     We have four logging in/authentication procedures:
 *      - regular login via POST with username & password
 *      - When user "profile" is stored in the localStorage UI would pick it up and would try to
 *        use it to authenticate. If switch-to-tenant API call with profile stored and
 *        sessionId/crsfToken from cookies fails UI will remove stored "profile" from localStorage.
 *        This case is used when user opens up new browser tab.
 *      - "UI-only" login after SAML authentication via redirect and setting cookie(?)
 *        with sessionId and crsfToken, UI picks those up from cookies and sets headers accordingly.
 *        POST on login is made but no payload is necessary
 *      - iframe (embedded) mode - sessionId, crsfToken, readOnly & tenantName are passed as query
 *        params on the initial UI url and login API POST call is made with those as a payload.
 *
 *     Logout state also depends on app mode and environment:
 *       - for "normal" mode it is login screen
 *       - for SAML authentication it is message "you've been logged out"
 *       - for OS horizon (iframe) it is "please reload this page"
 *
 *      Session duration control
 *        In non UI case backend session expires after api/controllerproperties.api_idle_timeout
 *        minutes. Basically after api_idle_timeout minutes elapsed after the most recent API call
 *        session will be marked as expired and user would have to establish the new one.
 *
 *        However potentially UI user might be spending significant time on single UI page and
 *        interacting with it while there will be no ongoing API calls on the background. Wo UI
 *        taking control of the session timeout user could get logged out during his otherwise
 *        active session.
 *
 *        We have an opposite case as well - let's say user opened up a page with live updates,
 *        such as VS analytics page. Since UI would trigger API call every 30 seconds back-end
 *        would never invalidate session due to the timeout. Even if user opened the page and
 *        left for a day session will remain active forever (not really - but that's not
 *        important here).
 *
 *        Hence UI has to take control of the session activity and it is done by:
 *         - send refresh-session GET request every `api_idle_timeout * 60 - 5` sec so that
 *           backend won't ever invalidate UI session due to absence of API calls
 *         - call authService.logout when api_idle_timeout minutes + (from 0 to 30) sec
 *           elapsed since the last user activity (mouse or keydown events).
 *         - do none of above when api_idle_timeout is set to zero which is a special value
 *           for the infinite session length.
 *
 *      In local storage we have:
 *       - user's profile so that new tab (or refresh) could pick it up wo the login call
 *       - addressed reload requests to refresh permissions list
 *       - last user's activity timestamp
 *       - user name before the logout event
 *
 * @todo Split into multiple services:
 *   - localStorageService
 *   - sessionActivityService
 *   - refresh session headers handling
 *   - createPrivilegeMap & buildParentPrivileges
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export class Auth extends AjsDependency {
    /**
     * Subject that emits when logging out.
     */
    public readonly triggerLogout$ = new Subject<void>();

    private readonly $http: IHttpService;
    private readonly $interval: IIntervalService;
    private readonly $state : StateService;
    private readonly $timeout: ITimeoutService;
    private readonly $window : IWindowService;
    private readonly horizonIframeService: HorizonIframeService;
    private readonly initialDataService: InitialDataService;
    private readonly stringService: StringService;
    private readonly devLoggerService: DevLoggerService;
    private readonly activeUserProfileService: ActiveUserProfileService;
    /**
     * Keeps current tenant roles as context
     */
    private context: IAuthContext | null = null;

    /**
     * Hashmap for mapping privilege resource to its type.
     * @example {'PERMISSION_INTERNAL': 'WRITE_ACCESS'}
     */
    private privilegeAccessMap: TPrivilegeAccessMap | null = null;

    /**
     * We allow local authentication for SAML setups via passing "local" query param with "1"
     * as value. Capturing that value here.
     * Originally was supported by loginController only on login page only, hence we can scope
     * it further if needed.
     */
    private readonly localAuthForSamlRequested: boolean;

    /**
     * Timeout set to kick off the user logout in the event of prolonged inactivity.
     * Applicable only when user is logged in and api_idle_timeout is greater than zero.
     */
    private autoLogoutTimeout: IPromise<void> | null = null;

    /**
     * Interval set to send refresh session request to the backend.
     * Applicable only when user is logged in and api_idle_timeout is greater than zero.
     */
    private sessionProlongationInterval: IPromise<void> | null = null;

    constructor() {
        super();

        this.$http = this.getAjsDependency_('$http');
        this.$state = this.getAjsDependency_('$state');

        const $window = this.getAjsDependency_('$window');

        this.$interval = this.getAjsDependency_('$interval');
        this.$timeout = this.getAjsDependency_('$timeout');
        this.$window = this.getAjsDependency_('$window');
        this.initialDataService = this.getAjsDependency_('initialDataService');
        this.stringService = this.getAjsDependency_(stringServiceToken);
        this.devLoggerService = this.getAjsDependency_('devLoggerService');
        this.activeUserProfileService = this.getAjsDependency_(ACTIVE_USER_PROFILE_SERVICE_TOKEN);

        const $location = this.getAjsDependency_('$location');
        const queryParams = $location.search();
        const horizonIframeService = this.getAjsDependency_('horizonIframeService');
        const ngZone: NgZone = this.getAjsDependency_('NgZone');

        horizonIframeService.init(queryParams);

        this.horizonIframeService = horizonIframeService;
        this.localAuthForSamlRequested = queryParams.local === '1';

        /**
         * Event handler to track user's activity.
         */
        const userActivityHandler = throttle(this.userActivityHandler, USER_ACTIVITY_STEP);

        // Attaching events
        // These events trigger unnecessary change detection cycles in angular
        // environments. So running them outside of zone.
        ngZone.runOutsideAngular(() => {
            $window.addEventListener('storage', this.storageListener);
            $window.addEventListener('mousemove', userActivityHandler);
            $window.addEventListener('keydown', userActivityHandler);
        });
    }

    /**
     * Checks whether a reload request is new or already addressed.
     * Returns true if the passed hash is unaddressed.
     * @param reloadRequestKey - value of `reload-permissions` in response headers
     * @return True, if the request is not addressed.
     */
    public static isNewReloadPermissionsRequest(reloadRequestKey: string): boolean {
        const addressedReloadRequests = localStorage.getItem(ADDRESSED_RELOAD_REQUESTS);

        if (addressedReloadRequests) {
            const addressedReloadRequestsHash = JSON.parse(addressedReloadRequests);

            return !addressedReloadRequestsHash[reloadRequestKey];
        }

        return true;
    }

    /**
     * Updates `addressedReloadRequests` of localStorage with a reload request key.
     * @param reloadRequestKey - value of `reload-permissions` in response headers
     * @param isAddressed - True if the request is addressed, false if not.
     */
    public static updateReloadPermissionsRequestHash(
        reloadRequestKey: string,
        isAddressed = true,
    ): void {
        const addressedReloadRequests: string = localStorage.getItem(ADDRESSED_RELOAD_REQUESTS);

        const addressedReloadRequestsHash: TAddressedRequestsHash =
            addressedReloadRequests ? JSON.parse(addressedReloadRequests) : {};

        addressedReloadRequestsHash[reloadRequestKey] = isAddressed;

        localStorage.setItem(
            ADDRESSED_RELOAD_REQUESTS,
            JSON.stringify(addressedReloadRequestsHash),
        );
    }

    /**
     * Returns stored state data if present.
     */
    private static getLastActiveState(): ILastActiveState | null {
        let state: ILastActiveState = null;

        try {
            const json: string = sessionStorage.getItem(STORAGE_LAST_ACTIVE_STATE);

            state = JSON.parse(json);
        } catch (e) {
            this.removeLastActiveState();
        }

        return state;
    }

    /**
     * Saves state info into the session storage.
     */
    private static saveLastActiveState(
        username: string,
        stateName: string,
        stateParams: IStateParams = {} as any as IStateParams,
    ): void {
        const state: ILastActiveState = {
            username,
            name: stateName,
            params: stateParams,
        };

        sessionStorage.setItem(
            STORAGE_LAST_ACTIVE_STATE,
            JSON.stringify(state),
        );
    }

    /**
     * Remove last user state from local storage.
     */
    private static removeLastActiveState(): void {
        localStorage.removeItem(STORAGE_LAST_ACTIVE_STATE);
    }

    /**
     * Exposed for "chunking" upload services only. In effect we are faking user activity which is
     * obviously no good.
     * @todo Replace with methods to switch auto logout off and on by request.
     */
    public emulateUserActivity(): void {
        this.userActivityHandler();
    }

    /**
     * Returns current tenant reference
     */
    public getCurrentTenantRef(): string {
        return this.activeUserProfileService.isUserProfileSet() ? this.context.tenant_ref : '';
    }

    /**
     * Take user to proper "logout" state,
     */
    public goToLogoutState(): TransitionPromise {
        const { $state } = this;

        const logoutState = this.getLogoutState();

        return $state.go(logoutState);
    }

    /**
     * Checks privileges for the given object
     * @param  category - Privilege resource.
     * @param  privilege - Can be 'r' for read and/or 'w' for write
     */
    public isAllowed(
        category: string,
        privilege = 'r',
    ): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }

        if (!this.privilegeAccessMap || !(category in this.privilegeAccessMap)) {
            const schemaService: SchemaService = this.getAjsDependency_('schemaService');

            try {
                category = schemaService.getPermissionCategory(category);
            } catch (err) {
                this.devLoggerService.warn(err);
            }
        }

        return this.isCategoryAllowed(category, privilege);
    }

    /**
     * Loads user's profile and permissions for the current tenant.
     * Used by httpInterceptorService.
     */
    public reloadUserProfileAndPermissions(): IPromise<void> {
        return this.activeUserProfileService.loadUserProfile()
            .then(() => {
                const { defaultTenantRef } = this.activeUserProfileService;

                const defaultTenantName = defaultTenantRef ?
                    this.stringService.name(defaultTenantRef) : '';

                return this.loadUserPermissionsAndSetTenant(defaultTenantName);
            });
    }

    /**
     * Reloads tenant-list for the current user.
     * So if any change happened in tenant configuration, that will get reflected in
     * tenant selector. This flow is not needed for admin user since when admin user adds/removes
     * a tenant, app gets reloaded (ex 419 flow, special header), so we dont have to do it manually.
     * See User Item#onUserSave_
     * @todo Remove once AV-70050 is resolved.
     */
    public onCurrentUserChange(): void {
        const { $state } = this;

        // admin user profile update is handled by http interceptor
        if (this.activeUserProfileService.isAdminUser()) {
            return;
        }

        this.reloadUserProfileAndPermissions()
            .then(() => $state.go(DEFAULT_STATE))
            .catch((error: any) => {
                throw error;
            });
    }

    /**
     * Checks if specified privilege resource has read or write access.
     * @param privilege - Privilege  resource.
     * @param type - Optional parameter privilege type. If not provided any access
     *     besides NO_ACCESS qualifies.
     * @returns True if Privilege resource has read or write access type or its
     *     type matches specified type.
     */
    public isPrivilegeAllowed(privilege: string, type?: Privilege): boolean {
        if (type === Privilege.NO_ACCESS) {
            return false;
        }

        const privilegeMap = this.createPrivilegeMap();

        if (!privilegeMap || !(privilege in privilegeMap)) {
            return false;
        }

        const existentType = privilegeMap[privilege];

        if (existentType === Privilege.NO_ACCESS) {
            return false;
        }

        // expecting an exact match
        if (type) {
            return type === existentType;
        }

        // since it is better than NO_ACCESS and there is nothing to match with
        return true;
    }

    /**
     * Checks privileges for the given object
     * @param category - Category key.
     * @param privilegeType - Privilege string. Possible values are 'r', 'w', 'rw'
     *     or 'wr'. 'wr' and 'rw' mean read OR write.
     */
    public isCategoryAllowed(category: string, privilegeType = 'r'): boolean {
        const privilegeMap = this.createPrivilegeMap();

        if (!privilegeMap || !(category in privilegeMap)) {
            return false;
        }

        // Find privilege type related to the category
        const existentType: Privilege = privilegeMap[category];

        if (existentType === Privilege.NO_ACCESS) {
            return false;
        }

        switch (privilegeType.toLowerCase()) {
            case 'r':
            case 'wr':
            case 'rw':
                return true;

            case 'w':
                return existentType === Privilege.WRITE_ACCESS;
        }

        return false;
    }

    /**
     * Returns target state after admin setup.
     */
    public getAfterAdminSetupTargetState(): string {
        const { isWelcomeWorkflowCompleted } = this.initialDataService;

        return !isWelcomeWorkflowCompleted ? WELCOME_STATE : DEFAULT_STATE;
    }

    /**
     * Initializes transition to after-login state and returns corresponding promise. Called by
     * login page only.
     * @return {TargetState}
     * Logs user out. We have three types of logout - by click, autologout initiated by UI
     * and on getting 401 response code from backend.
     * @param withRedirect
     * @param uiOnly - Pass true to skip API call but perform the clean up.
     * @todo Save MyAccount data if we have a pending saveDelayed
     */
    public logout(
        withRedirect = false,
        uiOnly = false,
        isLogoutDueToUserInactivity = false,
    ): IPromise<void> {
        const logoutApiCall: IPromise<void> = uiOnly ?
            Promise.resolve() :
            this.$http.post(LOGOUT_URL, undefined).then(() => undefined);

        const promise = logoutApiCall.then(() => this.onLogout());

        if (withRedirect) {
            const { redirectUri } = this.initialDataService;

            if (redirectUri && !uiOnly && !isLogoutDueToUserInactivity) {
                window.location.href = redirectUri;
            }

            promise.then(() => {
                this.goToLogoutState();
            });
        }

        return promise;
    }

    /**
     * Log user out. If logout API call fails (i.e. had been performed already)
     * falls back to UI only logout. Fail proof.
     */
    public failSafeLogout(): IPromise<void> {
        return this.logout(false)
            .catch(() => {
                // fallback so that non-auth pages are always accessible (aka safe ground)
                return this.logout(false, true);
            });
    }

    /**
     * Returns true in case of all tenant mode
     */
    public allTenantsMode(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }

        const tenantRef = this.getCurrentTenantRef();

        if (!tenantRef) {
            throw new Error('tenantRef is not available');
        }

        return tenantRef === '*';
    }

    /**
     * Return name of the currently active tenant.
     */
    public getTenantName(): string {
        if (this.context) {
            const tenantRef = this.getCurrentTenantRef();
            const { stringService } = this;

            return stringService.name(tenantRef) || tenantRef;
        }

        return '';
    }

    /**
     * Updates admin credentials.
     */
    public updateAdministratorCredentials(payload: IAdminUserSetupPayload): IHttpPromise<void> {
        return this.$http.put(ADMIN_USER_SETUP_URL, payload);
    }

    /**
     * Used by SAML when user comes back to UI already authenticated.
     * @return {Promise<void>}
     */
    public loginWithActiveSamlSession(): IPromise<void> {
        return this.login_();
    }

    /**
     * Used within iframe (horizon, etc) where session id and token are passed via query params.
     */
    public horizonIframeLogin(): IPromise<void> {
        const { horizonIframeService } = this;

        if (!horizonIframeService.active) {
            return Promise.reject(new Error('app is not running in iframe mode'));
        }

        return this.login_(horizonIframeService.loginPayload);
    }

    /**
     * Requests password reset from server.
     */
    public passwordChangeRequest(email: string): IHttpPromise<void> {
        return this.$http.post(PASS_CHANGE_REQ_URL, { email });
    }

    /**
     * Api Call to change the password.
     */
    public passwordChange(
        token: string,
        username: string,
        password: string,
    ): IPromise<void | IHttpResponse<void>> {
        return this.$http.post(PASS_CHANGE_URL, {
            token,
            username,
            password,
        });
    }

    /**
     * Updates user profile according to the selected tenant.
     * Called from UI when full user profile had been loaded and set prior to the call.
     */
    public setTenant(tenantName: string): IPromise<void> {
        return this.loadUserPermissionsAndSetTenant(tenantName);
    }

    /**
     * Set user profile based on the value picked up from the local storage.
     * If successful and user session is still active login API/method call won't happen.
     * Main use case: page reload and new browser tab opening.
     * @param tenantName - Expected to be passed from the URL (transition).
     */
    public setUserProfileFromLocalStorage(tenantName = ''): IPromise<void> {
        if (!this.activeUserProfileService.haveStoredProfile()) {
            throw Error('no stored profile found');
        }

        // generally this should not happen since URL should have tenant as state param
        if (!tenantName) {
            tenantName = this.getLastActiveStateTenantName();
        }

        const promise = this.setUserProfileAndTenant(
            this.activeUserProfileService.getStoredProfile(),
            tenantName,
        );

        promise.catch(
            () => this.activeUserProfileService.removeStoredProfile(),
        );

        return promise;
    }

    /**
     * Initializes transition to after-login state and returns corresponding promise. Called by
     * login page only.
     */
    public getAfterLoginTargetState(): TargetState {
        const {
            name,
            params,
        } = this.getInitialState();

        return this.$state.target(name, params);
    }

    /**
     * Removes last active user state from local storage.
     */
    public removeLastActiveState(): void {
        Auth.removeLastActiveState();
    }

    /**
     * Saves state info into the session storage.
     */
    public saveLastActiveState(stateName: string, stateParams = {} as any as IStateParams): void {
        const { username } = this.activeUserProfileService;

        Auth.saveLastActiveState(username, stateName, stateParams);
    }

    /**
     * Returns true if the user is logged in and his profile is set.
     */
    public isLoggedIn(): boolean {
        return this.activeUserProfileService.isUserProfileSet();
    }

    /**
     * Logs user in via username and password.
     */
    public login(username: string, password: string): IPromise<void> {
        const payload: ILoginPayload = {
            username,
            password,
        };

        return this.login_(payload);
    }

    /**
     * Returns true for admin tenant, beacuse admin tenant has always full access to VRF.
     * Returns false for all tenant mode
     * Returns true for non-admin tenant with tenant_vrf set to true
     * Returns false for non-admin tenant with tenant_vrf set to false
     */
    public get hasTenantVrf(): boolean {
        const activeTenantName = this.getTenantName();

        if (activeTenantName === ADMIN_TENANT_NAME) {
            return true;
        }

        if (this.allTenantsMode()) {
            return false;
        }

        const { config_settings: { tenant_vrf: tenantVrf } } = this.tenantConfiguration;

        return tenantVrf;
    }

    /**
     * Returns tenant object for the active tenant
     */
    private get tenantConfiguration(): ITenant {
        const activeTenantName = this.getTenantName();

        const tenants = this.activeUserProfileService.getTenants();

        const activeTenant = findWhere(tenants, { name: activeTenantName });

        if (!activeTenant) {
            throw new Error(`${activeTenantName} is not available in tenants list.`);
        }

        return activeTenant;
    }

    /**
     * Use regex to get tenant name from cookie.
     * Tenant name is stored in cookie as 'avi_tenant=<orgID>:<sddcID>'.
     */
    private get tenantNameFromCookie(): string {
        const tenantName = document.cookie.split(/(?:avi_tenant=)(\S*)/s)[1];

        return tenantName?.replace(';', '');
    }

    /**
     * Logs user in, supports three payload formats
     * @see {@link Auth#login}
     * @see {@link Auth#loginWithActiveSamlSession}
     * @see {@link Auth#horizonIframeLogin}
     */
    // eslint-disable-next-line no-underscore-dangle
    private login_(credentials: ILoginPayload = {}): IPromise<void> {
        return this.$http.post(LOGIN_URL, credentials)
            .then(
                ({ data: response }: IHttpResponse<IActiveUserProfile>) => {
                    return this.onLogin(credentials, response);
                },
            ).catch(({ data: response, status }) => {
                throw Object.assign(response, { status });
            });
    }

    /**
     * Return last active state object if user is the same and has access to the tenant used by
     * that state.
     */
    private getLastActiveState(): ILastActiveState | null {
        const lastActiveState = Auth.getLastActiveState();

        if (!lastActiveState) {
            return null;
        }

        const {
            username,
            params = {} as any,
        } = lastActiveState;

        const { tenantName } = params;

        if (username !== this.activeUserProfileService.username ||
            tenantName && !this.activeUserProfileService.hasAccessToTenant(tenantName)) {
            return null;
        }

        return lastActiveState;
    }

    /**
     * Returns last active tenant if relevant info had been stored in local storage.
     */
    private getLastActiveStateTenantName(): string {
        const lastActiveState = this.getLastActiveState();

        if (!lastActiveState) {
            return '';
        }

        const { params = {} as any } = lastActiveState;

        return params.tenantName || '';
    }

    /**
     * Process successful login API response.
     */
    private onLogin(credentials: ILoginPayload, profile: IActiveUserProfile): IPromise<void> {
        const prevUserName = this.activeUserProfileService.getStoredUsername();

        this.activeUserProfileService.removeStoredUsername();

        if (prevUserName && (isEmpty(credentials) || prevUserName !== credentials.username)) {
            this.emptyLocalStorage();
        }

        // Backend exposes version information only when user is authenticated.
        // So we load the entire initial-data again.
        this.initialDataService.loadData(true);

        return this.setUserProfileAndTenant(profile);
    }

    /**
     * Set initial(!) user profile (tenants list, active tenant, permissions) after login or
     * upon page reload when session is still alive and profile had been stored in local storage.
     * Called only once(!) per user session.
     * @param profile
     * @param tenantName - Tenant to select upon user profile update. Default one will
     *    be selected if not passed.
     */
    private setUserProfileAndTenant(profile: IActiveUserProfile, tenantName = ''): IPromise<void> {
        if (this.activeUserProfileService.isUserProfileSet()) {
            return Promise.reject(new Error('profile is already set'));
        }

        if (!profile) {
            return Promise.reject(new Error('profile is not passed'));
        }

        this.activeUserProfileService.userProfile = profile;

        this.activeUserProfileService.saveUserProfile(profile);

        // getTenantNameToActivate has to be called AFTER profile is set
        tenantName = tenantName || this.getTenantNameToActivate();

        const promise = this.loadUserPermissionsAndSetTenant(tenantName);

        // handlers below are separate cause they don't affect promise being returned
        promise.catch(() => {
            this.activeUserProfileService.userProfile = null;
        });

        promise
            .then(this.userActivityHandler)
            .then(this.initSessionProlongationInterval);

        return promise;
    }

    /**
     * Load permissions list, update current tenant and call onUserPermissionsLoad.
     */
    private loadUserPermissionsAndSetTenant(tenantName: string): IPromise<void> {
        const reqConfig = {
            params: { tenant_name: tenantName },
            paramSerializer: 'httpParamFullSerializer',
        };

        return this.$http.get(SET_TENANT_URL, reqConfig)
            .then(({ data: permissions }: any) => {
                this.onUserPermissionsLoad(tenantName, permissions);
            });
    }

    /**
     * Save permissions (aka privileges) list on 'this' and notify third-party services
     * to react on potential user's active tenant or permissions changes.
     * Last step of session instantiation.
     */
    private onUserPermissionsLoad(tenantName: string, permissions: IPermission[]): void {
        const tenantRef = this.activeUserProfileService.getTenantRefByName(tenantName);

        this.context = {
            tenant_ref: tenantRef,
            privileges: permissions,
        };

        this.privilegeAccessMap = null;

        const stateAccessMapService: StateAccessMapService =
            this.getAjsDependency_('stateAccessMapService');

        stateAccessMapService.generateMap();

        // Set global header into http provider
        this.$http.defaults.headers.common['X-Avi-Tenant'] = tenantName;

        const tenantService: TenantService = this.getAjsDependency_('TenantService');

        tenantService.changeContext(tenantRef);

        const defaultValues: DefaultValues = this.getAjsDependency_('defaultValues');

        // to get the correct references of system objects redefined on the tenant level
        defaultValues.load(true);

        const $rootScope: IRootScopeService = this.getAjsDependency_('$rootScope');

        $rootScope.$broadcast('setContext');
    }

    /**
     * Returns logout state according to "UI app mode" and SAML/SSO configuration.
     */
    private getLogoutState(): string {
        const { initialDataService } = this;

        if (!initialDataService.hasData()) {
            console.error('initialData is not loaded yet');
        }

        const ssoEnabled = initialDataService.hasData() ? initialDataService.isSsoEnabled : true;

        let logoutState;

        switch (true) {
            case this.initialDataService.isOauthRedirection:
                logoutState = LOGIN_STATE;
                break;

            case ssoEnabled:
                logoutState = LOGGED_OUT_STATE;
                break;

            case this.horizonIframeService.active:
                logoutState = PLEASE_RELOAD_STATE;
                break;

            default:
                logoutState = LOGIN_STATE;
        }

        return logoutState;
    }

    /**
     * Returns the 'default' tenant name to be activated after setting user's profile.
     * Profile has to be available.
     *
     * Use cases:
     *   - new browser tab opening/reload: picking up tenant from the URL (method wont be called)
     *   - normal/SAML login with last active state avail: picking up tenant from last active state
     *   - iframe (OpenStack Horizon) case - tenant comes via specific query param of the URL
     *   - normal/SAML login wo last active state avail: picking up default/admin/first tenant
     *
     */
    private getTenantNameToActivate(): string | undefined {
        const {
            activeUserProfileService,
            horizonIframeService,
        } = this;

        if (horizonIframeService.active) {
            const { tenantName } = horizonIframeService;

            if (activeUserProfileService.hasAccessToTenant(tenantName)) {
                return tenantName;
            } else {
                console.error(
                    `tenant ${tenantName} requested via URL is not available for the user`,
                );
            }
        }

        const { tenantNameFromCookie } = this;

        if (tenantNameFromCookie &&
            activeUserProfileService.hasAccessToTenant(tenantNameFromCookie)
        ) {
            return tenantNameFromCookie;
        }

        const lastActiveTenantName = this.getLastActiveStateTenantName();

        if (lastActiveTenantName &&
                activeUserProfileService.hasAccessToTenant(lastActiveTenantName)) {
            return lastActiveTenantName;
        }

        const { userData } = activeUserProfileService;
        const { default_tenant_ref: defaultTenantRef } = userData;

        if (defaultTenantRef) {
            const { stringService } = this;
            const defaultTenantName = stringService.name(defaultTenantRef);

            // TODO figure with Webapp team why do we need to check this
            if (activeUserProfileService.hasAccessToTenant(defaultTenantName)) {
                return stringService.name(defaultTenantRef) || defaultTenantRef;
            }
        }

        if (activeUserProfileService.hasAccessToTenant(ADMIN_TENANT_NAME)) {
            return ADMIN_TENANT_NAME;
        }

        const tenants = activeUserProfileService.getTenants();

        return tenants[0].name;
    }

    /**
     * Returns state object to head to after successful login.
     * Use cases:
     *  - iframe - path is passed in URL but in some weird from - need to read and apply that one
     *  - new browser tab opening/full page reload - state is passed in URL and this method
     *    is NOT called
     *  - normal/SAML login - check if last active state is avail and use it if applicable
     */
    private getInitialState(): IAuthInitialState | IHorIframeInitialState {
        const {
            initialDataService,
            horizonIframeService,
        } = this;

        if (
            !initialDataService.isWelcomeWorkflowCompleted &&
            this.isAllowed('systemconfiguration', 'w')
        ) {
            return {
                name: WELCOME_STATE,
            };
        }

        if (horizonIframeService.active) {
            return horizonIframeService.initialAppState;
        }

        let name = DEFAULT_STATE;
        let params: Record<string, any> = {};

        const lastActiveState = this.getLastActiveState();

        if (lastActiveState) {
            ({
                name,
                params = {},
            } = lastActiveState);
        }

        if (!params.tenantName) {
            params.tenantName = this.getTenantName();
        }

        return {
            name,
            params,
        };
    }

    /**
     * Does on logout clean up and forwards user to the after logout state.
     */
    private onLogout(): void {
        const { $timeout, $interval } = this;

        $interval.cancel(this.sessionProlongationInterval);
        this.sessionProlongationInterval = null;

        $timeout.cancel(this.autoLogoutTimeout);
        this.autoLogoutTimeout = null;

        const $rootScope: IRootScopeService = this.getAjsDependency_('$rootScope');

        $rootScope.$broadcast('userLoggedOut');

        if (this.isLoggedIn()) {
            this.activeUserProfileService.storeActiveUsername();
        }

        this.context = null;
        this.activeUserProfileService.userProfile = null;
        this.privilegeAccessMap = null;

        this.$window.jsErrors = [];
        this.$window.jsErrorsHash = {};

        this.emptyLocalStorage();
        delete this.$http.defaults.headers.common['X-Avi-Tenant'];

        this.triggerLogout$.next();
    }

    /**
     * Returns true if local authentication was requested for SAML setups.
     */
    public get localAuthRequestedForSaml(): boolean {
        return this.localAuthForSamlRequested;
    }

    /**
     * Return allowed idle time in milliseconds. Set in controller properties.
     * Unlimited when set to zero. Not applicable when user is not logged in.
     */
    private get apiIdleTimeout(): number {
        const { controller } = this.activeUserProfileService.userProfile;

        return controller.api_idle_timeout * 60 * 1000 || 0;
    }

    /**
     * Clears stored profile data from localStorage.
     */
    private emptyLocalStorage(): void {
        localStorageProps.forEach((propName: string) => localStorage.removeItem(propName));
    }

    /**
     * Creates or returns privilege hashmap with privilege resource as key and privilege type
     * as value.
     */
    private createPrivilegeMap(): TPrivilegeAccessMap | null {
        if (this.privilegeAccessMap) {
            return this.privilegeAccessMap;
        }

        if (!this.context) {
            return null;
        }

        this.privilegeAccessMap = {} as unknown as TPrivilegeAccessMap;

        const privilegeMap = this.privilegeAccessMap;
        const { privileges } = this.context;

        for (const privilege of privileges) {
            privilegeMap[privilege.resource] = privilege.type;
        }

        const statePermissionTreeService: StatePermissionTreeService =
            this.getAjsDependency_('StatePermissionTreeService');

        const statePermissionTree = statePermissionTreeService.getStatePermissionTree();

        this.buildParentPrivileges(statePermissionTree);

        const { horizonIframeService } = this;

        if (horizonIframeService.active) {
            // todo: make sure custom permissions are equal or lower than ones
            //     returned by the backend
            const { permissions: customPermissions } = horizonIframeService;

            Object.assign(privilegeMap, customPermissions);
        }

        return privilegeMap;
    }

    /**
     * Assigns privilege type to parent privilege based on its child privileges.
     * @param permissionStructure - Parent privilege based on {@link privilegeResource}
     *    constant data structure.
     * @param parentPermission - Optional reference to the parent
     *    permission where {@param permissionStructure} is its child permissions structure.
     *    If specified, it will be added to {@link privilegeAccessMap} with access level set
     *    based on its children.
     */
    private buildParentPrivileges(
        permissionStructure: IAppStatePermissionTree,
        parentPermission?: string,
    ): void {
        const permissionMap = this.privilegeAccessMap;

        Object.keys(permissionStructure).forEach((permission: string) => {
            const permissionsOrAccessLevel = permissionStructure[permission];

            if (!(permission in permissionMap)) {
                if (Array.isArray(permissionsOrAccessLevel)) {
                    for (const p of permissionsOrAccessLevel) {
                        if (p in permissionMap && objectAccessLevels[permissionMap[p]] > 0) {
                            permissionMap[permission] = permissionMap[p];
                        } else if (typeof p === 'object') {
                            this.buildParentPrivileges(p, permission);
                        }
                    }

                    if (parentPermission && permissionMap[permission]) {
                        permissionMap[parentPermission] = permissionMap[permission];
                    }
                } else if (isString(permissionsOrAccessLevel)) {
                    permissionMap[permission] = permissionsOrAccessLevel;
                }
            }
        });
    }

    /**
     * Set last user activity timestamp on localStorage and resets auto logout timeout.
     */
    private userActivityHandler = (): void => {
        if (!this.isLoggedIn()) {
            return;
        }

        const date = utc().valueOf();

        // save to localStorage in case user is active in a different tab.
        localStorage.setItem(STORAGE_LAST_USER_ACTIVITY, `${date}`);

        this.resetAutoLogoutTimeout();
    };

    /**
     * Log out user when no user activity is detected within defined time frame.
     */
    private autoLogout = (): void => {
        if (!this.isLoggedIn()) {
            return;
        }

        this.logout(true, undefined, true);
    };

    /**
     * Resets session timer and starts new countdown.
     */
    private resetAutoLogoutTimeout(): void {
        const { $timeout } = this;

        $timeout.cancel(this.autoLogoutTimeout);

        this.autoLogoutTimeout = null;

        if (!this.isLoggedIn()) {
            return;
        }

        const { apiIdleTimeout: timeoutDuration } = this;

        // infinite session case
        if (!timeoutDuration) {
            return;
        }

        this.autoLogoutTimeout = $timeout(this.autoLogout, timeoutDuration);
    }

    /**
     * Kick off ongoing refresh session API calls.
     * @todo Call this on api idle timeout change from controller props / user modal.
     */
    private initSessionProlongationInterval = (): void => {
        const { $interval } = this;

        $interval.cancel(this.sessionProlongationInterval);

        this.sessionProlongationInterval = null;

        if (!this.isLoggedIn()) {
            return;
        }

        const { apiIdleTimeout: timeoutDuration } = this;

        // infinite session case
        if (!timeoutDuration) {
            return;
        }

        const timeBuffer = 5000; // 5 sec, want to be a little earlier than BE timeout

        this.sessionProlongationInterval = $interval(
            this.prolongBackendSession,
            Math.max(timeBuffer, timeoutDuration - timeBuffer),
        );
    };

    /**
     * Make an API call to prolong session on the backend.
     * Need this so that if user is stuck on some page wo any ongoing API calls backend won't logout
     * the user.
     */
    private prolongBackendSession = (): void => {
        if (!this.isLoggedIn()) {
            return;
        }

        this.$http.get(SESSION_REFRESH_URL);
    };

    /**
     * Handles localStorage change event. Currently only logout from other tab is supported.
     */
    private storageListener = (event: StorageEvent): void => {
        if (!this.isLoggedIn()) {
            return;
        }

        const { key, newValue } = event;
        const valueRemoved = newValue === null;

        switch (key) {
            case STORAGE_USER_PROFILE_LOCAL_STORAGE:
                if (valueRemoved) {
                    this.onLogout();
                }

                break;

            case STORAGE_LAST_USER_ACTIVITY:
                if (!valueRemoved) {
                    this.resetAutoLogoutTimeout();
                }

                break;
        }
    };
}

Auth.ajsDependencies = [
    '$q',
    '$http',
    '$timeout',
    '$interval',
    '$window',
    'schemaService',
    '$rootScope',
    '$state',
    'StatePermissionTreeService',
    '$location',
    'defaultValues',
    'TenantService',
    'stateAccessMapService',
    'initialDataService',
    'horizonIframeService',
    stringServiceToken,
    'devLoggerService',
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
    'NgZone',
];
