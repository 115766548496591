/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { uniq } from 'underscore';
import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import type {
    INatActionIpAddrRange,
} from 'ajs/modules/policies/factories/nat-policy/nat-rule.config-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './nat-action-ip-addr-range-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for IP addresses and range in NAT Rule modal.
 *
 * @author Guru Prasad H K
 */
@Component({
    selector: 'nat-action-ip-addr-range-grid',
    templateUrl: './nat-action-ip-addr-range-grid.component.html',
})
export class NatActionIpAddrRangeComponent implements AfterViewInit {
    /**
     * List of IP Addresses and IP Ranges in IpAddrRange grid.
     */
    @Input()
    public ipAddrStrings: INatActionIpAddrRange[];

    /**
     * Fires on Add IP address.
     */
    @Output()
    public onAddIpAddr = new EventEmitter();

    /**
     * Fires on Remove IP addresses.
     */
    @Output()
    public onRemoveIps = new EventEmitter<INatActionIpAddrRange[]>();

    /**
     * TemplateRef for ip input element.
     */
    @ViewChild('ipAddrTemplateRef')
    public ipAddrTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for ip range input element.
     */
    @ViewChild('ipAddrRangeTemplateRef')
    public ipAddrRangeTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Grid config for IP Address in IP Group.
     */
    public ipAddrGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * List of duplicate IP addresses from grid.
     */
    public duplicateIps: string[] = [];

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.ipAddrGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleIpAddress),
                    id: 'ip-addr',
                    templateRef: this.ipAddrTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleIpAddressRange),
                    id: 'ip-range',
                    templateRef: this.ipAddrRangeTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (ipList: INatActionIpAddrRange[]) => {
                    this.onRemoveIps.emit(ipList);
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (ipAddr: INatActionIpAddrRange) => {
                    this.onRemoveIps.emit([ipAddr]);
                },
            }],
        };
    }

    /**
     * Handler for IP Address add operation.
     */
    public addNatAction(): void {
        this.onAddIpAddr.emit();
    }

    /**
     * Handler for IP Address change operation.
     * Checks if any IP is already present in grid and store those duplicate IPs
     * to show in error message.
     */
    public handleIpAddressChange(): void {
        const flattenArr: string[] = [];

        this.ipAddrStrings.forEach((item: INatActionIpAddrRange) => {
            if (item.addr) {
                flattenArr.push(item.addr);
            }
        });

        this.duplicateIps = uniq(flattenArr.filter(
            (item: string, index: number) => flattenArr.indexOf(item) !== index,
        ));
    }

    /**
     * Checks for duplicate error.
     */
    public get hasDuplicateIps(): boolean {
        return this.duplicateIps.length > 0;
    }
}
