/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { SSLProfile }
    from 'ajs/modules/security/factories/ssl-profile/ssl-profile.item.factory';

import { SSLProfileCollection }
    from 'ajs/modules/security/factories/ssl-profile/ssl-profile.collection.factory';

import { IAviCollectionDataGridConfig }
    from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { SSL_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/security/security.tokens';
import * as globalL10n from 'global-l10n';
import * as l10n from './ssl-profile-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSSLProfileCollection = typeof SSLProfileCollection;

/**
 * SSL Profile List.
 * @author Manideep Kotha
 */

@Component({
    selector: 'ssl-profile-list',
    templateUrl: './ssl-profile-list.component.html',
})
export class SslProfileListComponent {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display SSL profile collection.
     */
    public sslProfileListGridConfig: IAviCollectionDataGridConfig;

    /**
     * Collection object to store the SSL profile collection.
     */
    private sslProfileCollection: SSLProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(SSL_PROFILE_COLLECTION_TOKEN)
        private readonly SSLProfileCollection: TSSLProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.sslProfileCollection = new SSLProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.sslProfileCollection;

        this.sslProfileListGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.sslProfileCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: SSLProfile) => row.getName(),
                    sortBy: 'name',
                }, {
                    id: 'versions',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleAcceptedVersions),
                    transform: (row: SSLProfile) => row.getAcceptedVersionsString(),
                },
            ],
            singleactions: [
                {
                    id: 'edit',
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (row): void => {
                        row.isEditable() ? row.edit() : this.sslProfileCollection.clone(row);
                    },
                    hidden: (row): boolean => {
                        return !row.isEditable() && !row.isClonable();
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
                hideDelete: true,
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileCollection.destroy();
    }
}
