/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    ISamlIdentityProviderSettings,
    ISamlSettings,
} from 'generated-types';

import { SamlSettings } from 'object-types';
import { SamlServiceProviderSettingsConfigItem } from '.';

type TISamlSettingsPartial = Omit<ISamlSettings, 'sp' | 'idp'>;

interface ISamlSettingsConfig extends TISamlSettingsPartial {
    sp?: SamlServiceProviderSettingsConfigItem;
    idp?: MessageItem<ISamlIdentityProviderSettings>
}

/**
 * @description SAML Message Item.
 * @author Suraj Kumar
 */
export class SamlSettingsConfigItem extends MessageItem<ISamlSettingsConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: SamlSettings,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Handle IDP metadata source type change. Delete unnecessary property.
     */
    public handleIdpMetadataSourceTypeChange(useIdpMetadataUrl: boolean): void {
        const { config } = this.config.idp;

        if (useIdpMetadataUrl) {
            delete config.metadata;
        } else {
            delete config.metadata_url;
        }
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const useIdpMetadataUrl = Boolean(this.config.idp.config.metadata_url);

        this.handleIdpMetadataSourceTypeChange(useIdpMetadataUrl);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'sp',
            'idp',
        ];
    }
}
