/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const errorPagesLabel = `${prefix}.errorPagesLabel`;
export const totalPagesLabel = `${prefix}.totalPagesLabel`;
export const statusCodesLabel = `${prefix}.statusCodesLabel`;

export const ENGLISH = {
    [headerLabel]: 'Error Page Profiles',
    [errorPagesLabel]: 'Error Pages',
    [totalPagesLabel]: 'Total Pages: {0}',
    [statusCodesLabel]: 'Status Codes: {0}',
};
