/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module LogsModule
 */

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

import {
    VsLogsWafExceptionDialogService,
} from 'ng/modules/logs/services/vs-logs-waf-exception-dialog.service';

import { subnet, wafExclusionMatchElement } from 'ng/shared/utils/regex.utils';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import './vs-logs-waf-exception-dialog.component.less';
import * as l10n from './vs-logs-waf-exception-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component used for showing WAF logs Exceptions.
 * @author Shanmukha Sarath Kondiparthi
 */
@Component({
    selector: 'vs-logs-waf-exception-dialog',
    templateUrl: 'vs-logs-waf-exception-dialog.component.html',
})
export class VsLogsWafExceptionDialogComponent {
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Subnet for new WAF exception modal.
     */
    public subnet: string;

    /**
     * URI path for new WAF exception modal.
     */
    public uriPath: string;

    /**
     * Match element for new WAF exception modal.
     */
    public matchElement: string;

    public isAddingNewExceptionAllowed = true;

    public isNewExceptionValid = false;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Old WAF exceptions fetched from WAF policy.
     */
    public readonly wafException = this.vsLogsWafExceptionDialogService.wafException;

    constructor(
        l10nService: L10nService,
        public readonly vsLogsWafExceptionDialogService: VsLogsWafExceptionDialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.validateNewException();
    }

    /**
     * Dismiss the confirmtation modal.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }

    /**
     * Save WAF policy after new WAF exception added.
     */
    public handleSubmit(): void {
        this.vsLogsWafExceptionDialogService.saveWafExceptions(
            this.uriPath, this.matchElement, this.subnet,
        );
    }

    /**
     * Remove new exception.
     */
    public removeNewException(): void {
        this.isAddingNewExceptionAllowed = false;
    }

    /**
     * Check if new exception is valid.
     */
    public validateNewException(): void {
        const areAllFieldsEmpty = ![this.subnet, this.uriPath, this.matchElement].some(val => val);
        const isValidSubnet = this.subnet ? subnet.test(this.subnet) : true;
        const isValidMatchElement =
            this.matchElement ? wafExclusionMatchElement.test(this.matchElement) : true;
        const areRegexPattersMatching = isValidMatchElement && isValidSubnet;

        this.isNewExceptionValid =
            this.isAddingNewExceptionAllowed && !areAllFieldsEmpty && areRegexPattersMatching;
    }
}
