/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-profile-certificate-revocation-list-grid';
const prefix = `${moduleName}.${componentName}`;

export const certRevocationListColTitleExpDate = `${prefix}.certRevocationListColTitleExpDate`;
export const certRevocationListColTitleRefresh = `${prefix}.certRevocationListColTitleRefresh`;
export const crlMinutesLabel = `${prefix}.crlMinutesLabel`;
export const certificateRevocationListLabel = `${prefix}.certificateRevocationListLabel`;

export const ENGLISH = {
    [certRevocationListColTitleExpDate]: 'Expiration Date',
    [certRevocationListColTitleRefresh]: 'Refresh',
    [crlMinutesLabel]: '{0} Minutes',
    [certificateRevocationListLabel]: 'Certificate Revocation List ({0})',
};
