/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module ErrorPageModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { withFullModalMixin } from 'ajs/js/utilities/mixins';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    AviPermissionResource,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { ErrorPageConfigItem } from './error-page.config-item.factory';

import * as l10n from '../error-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const ERROR_PAGES = 'error_pages';

/**
 * @description Error page profile ObjectTypeItem class.
 *
 * @author Ratan Kumar, Shibam Biswas
 */
export class ErrorPageProfile extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'errorpageprofile',
            objectType: 'ErrorPageProfile',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_ERRORPAGEPROFILE,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { ErrorPageProfileModalComponent } = await import(
            /* webpackChunkName: "error-page-profile-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/error-page-profile-modal/error-page-profile-modal.component'
        );

        return ErrorPageProfileModalComponent as Type<Component>;
    }

    /**
     * To get errorPages from error page profile config.
     */
    public get errorPageList(): RepeatedMessageItem<ErrorPageConfigItem> {
        const { error_pages: errorPages } = this.getConfig();

        return errorPages;
    }

    /**
     * Method to add new error page item.
     */
    public addErrorPage(): void {
        this.addChildMessageItem({
            field: ERROR_PAGES,
        });
    }

    /**
     * Method to edit error pages.
     */
    public updateErrorPage(errorPage: ErrorPageConfigItem): void {
        this.editChildMessageItem({
            field: ERROR_PAGES,
            messageItem: errorPage,
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.errorPageProfileModalBreadcrumbTitle);
    }
}
