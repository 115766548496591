/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    signpostsWithRange,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import { IVsLogsDefaultSignpostData } from 'ng/modules/vs-logs/vs-logs.types';
import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import './vs-logs-signpost-default-view-log-list.component.less';
import * as l10n from './vs-logs-signpost-default-view-log-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Signpost Logs list component, displays grid for default template.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-default-view-log-list',
    templateUrl: './vs-logs-signpost-default-view-log-list.component.html',
})
export class VsLogsSignpostDefaultViewLogListComponent implements AfterViewInit {
    /**
     * Selected signpost config key.
     */
    @Input()
    public configKey: string;

    /**
     * Datagrid template for field name.
     */
    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for field with range.
     */
    @ViewChild('rangeFieldTemplateRef')
    public rangeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for field percentage bar.
     */
    @ViewChild('progressBarFieldTemplateRef')
    public progressBarFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for field percentage bar.
     */
    @ViewChild('percentageFieldTemplateRef')
    public percentageFieldTemplateRef: TemplateRef<HTMLElement>;

    public logListGridConfig: IAviDataGridConfig;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            configKey,
        } = this;

        this.logListGridConfig = {
            layout: {
                hidePagination: true,
                hideCheckboxes: true,
                showFooter: false,
            },
            fields: [
                {
                    label: templatesConfiguration[configKey].name,
                    id: 'name',
                    templateRef: signpostsWithRange.has(configKey) ?
                        this.rangeFieldTemplateRef : this.nameFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.logsNumberLabel),
                    id: 'count',
                    transform: (row: IVsLogsDefaultSignpostData): number => row.count,
                },
                {
                    label: l10nService.getMessage(l10nKeys.logsPercentageLabel),
                    id: 'percentage',
                    templateRef: this.percentageFieldTemplateRef,
                },
                {
                    label: '',
                    id: 'percentage-bar',
                    templateRef: this.progressBarFieldTemplateRef,
                },
            ],
        };
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
