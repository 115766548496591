/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { StateParams } from '@uirouter/core';
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { Timeframe } from 'ajs/js/services/Timeframe';
import {
    calculateStep,
    convertTimeToTTimestampFormatFromApi,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.utils';
import {
    VirtualServiceStateService,
} from 'ng/modules/virtual-service/services/virtual-service-state.service';
import {
    TVsLogsStateTypes,
    VsLogsStore,
} from '../../services/vs-logs.store';
import {
    LogsTimeframeOptions,
    VsLogsTimeframesService,
} from '../../services/vs-logs-timeframes.service';
import { VsLogsRefreshService } from '../../services/vs-logs-refresh.service';
import { VsLogsDownloadService } from '../../services/vs-logs-download.service';
import {
    FilterOperatorType,
    IVsLogsStateParams,
} from '../../vs-logs.types';
import {
    defaultPageNumber,
    defaultPageSize,
    defaultShowNonSignificantLogs,
    defaultShowSignificantLogs,
} from '../../constants/default-values.constants';
import { getVsConfig } from '../../utils/vs-logs.utils';
import { convertFilterObjectToQueryString } from '../../utils/vs-logs-filters.utils';

import * as l10n from './vs-logs-page.l10n';
import './vs-logs-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *  Main logs page for VS.
 *  Children components use shared data from module level VsLogsStore.
 * @author Akul Aggarwal, Alex Klyuev, Zhiqian Liu
 */
@Component({
    selector: 'vs-logs-page',
    templateUrl: './vs-logs-page.component.html',
    providers: [VsLogsRefreshService, VsLogsDownloadService],
})
export class VsLogsPageComponent implements OnInit, OnDestroy {
    public readonly l10nKeys = l10nKeys;

    /**
     * Email address for users to send feedback to.
     */
    public readonly feedbackEmailAddress = 'avi-product-feedback@vmware.com';

    /**
     * Documentation link to learn more about timeouts.
     */
    public readonly timeoutsDocLink = 'https://avinetworks.com/' +
        'docs/22.1/architectural-overview/applications/virtual-services/vs-logs/#log-navigation';

    /**
     * Control whether to show banner about tech-preview/legacy view.
     */
    public showRevertPageBanner = true;

    /**
     * Currently selected VS.
     * TODO: change "any" to the VirtualService class once it's been converted to Typescript.
     */
    private virtualService: any;

    constructor(
        l10nService: L10nService,
        virtualServiceStateService: VirtualServiceStateService,
        public readonly vsLogsStore: VsLogsStore,
        @Inject('Timeframe')
        private readonly timeframe: Timeframe,
        private readonly vsLogsTimeframesService: VsLogsTimeframesService,
        vsLogsDownloadService: VsLogsDownloadService,
        public readonly vsLogsRefreshService: VsLogsRefreshService,
        @Inject('$stateParams')
        private readonly $stateParams: StateParams,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.virtualService = virtualServiceStateService.virtualService;

        // Subscribe to changes in the currently selected VS.
        this.virtualService.on('itemSaveSuccess', this.setVsProps);

        // Temporary workaround to "custom" being in the url until Timeframe
        // component is upgraded
        if (this.timeframe.selected().key === LogsTimeframeOptions.CUSTOM) {
            this.timeframe.set(LogsTimeframeOptions.FIFTEEN_MINUTES);
        }

        // Subscribe to the timeframe service
        // @ts-expect-error will be handled when timeframe upgraded to angular
        this.timeframe.on('change', this.setDurationAndStepApiParams);

        // Subscribe to Download service for download all logs action.
        vsLogsDownloadService.downloadAllLogs$.subscribe(() => vsLogsStore.downloadAllLogs());
    }

    /** @override */
    public ngOnInit(): void {
        this.initVsLogsStore();
    }

    /**
     * @override Manually unsubscribe where necessary.
     */
    public ngOnDestroy(): void {
        this.virtualService.unbind('itemSaveSuccess', this.setVsProps);
        // @ts-expect-error will be handled when timeframe upgraded to angular
        this.timeframe.unbind('change', this.setDurationAndStepApiParams);
    }

    /**
     * Hide banner upon close action.
     */
    public hideRevertPageBanner(): void {
        this.showRevertPageBanner = false;
    }

    /**
     * Hide timeout message upon close action.
     */
    public hideTimeoutBanner(): void {
        this.vsLogsStore.setisTimeoutBannerEnabled(false);
    }

    /**
     * Hide error message upon close action.
     */
    public hideErrorBanner(): void {
        this.vsLogsStore.setisErrorBannerEnabled(false);
    }

    /**
     * Reload vs-logs page.
     */
    // eslint-disable-next-line class-methods-use-this
    public reloadPage(): void {
        window.location.reload();
    }

    /**
     * Get the duration value from the timeframe service.
     */
    private getDuration(): number {
        const timeframeKey = this.timeframe.selected().key;

        if (timeframeKey === 'custom') {
            return 0;
        }

        // TODO: remove when VS Logs timeframe only uses default
        // Must perform this check to avoid a console error until Timeframe Component is updated
        return this.vsLogsTimeframesService.getTimeframeProps(timeframeKey)?.range || 900;
    }

    /**
     * Set the duration and step in the component store upon timeframe change.
     */
    private setDurationAndStepApiParams = (): void => {
        if (this.getDuration() === 0) {
            return;
        }

        this.vsLogsStore.setDurationAndStepApiParams(
            this.getDuration(),
            calculateStep(this.getDuration()),
        );
    };

    /**
     * Set state props that are based on the VS.
     */
    private setVsProps = (): void => {
        this.vsLogsStore.setVsProps(this.virtualService);
    };

    /**
     * Load page with pre-populated search filters, if any exist.
     */
    private initFilters = (): string[] => {
        const { initFilterParams } = this.$stateParams;

        const queryStrings: string[] = [];

        Object.entries(initFilterParams).forEach(([property, value]: [string, string]) => {
            queryStrings.push(convertFilterObjectToQueryString({
                property,
                operator: FilterOperatorType.EQUAL_TO,
                value,
            }));
        });

        return queryStrings;
    };

    /**
     * Initialize the state for the VS Logs module.
     */
    private initVsLogsStore(): void {
        const vsConfig = getVsConfig(this.virtualService);
        const filters = this.initFilters();

        const apiParams: IVsLogsStateParams = {
            type: vsConfig.vsLogsType,
            adf: defaultShowSignificantLogs,
            udf: defaultShowNonSignificantLogs,
            nf: defaultShowNonSignificantLogs,
            // TODO: update to 15 min default when timeframe service is upgraded
            duration: this.getDuration(),
            end: convertTimeToTTimestampFormatFromApi(Date.now()),
            step: calculateStep(this.getDuration()),
            page: defaultPageNumber,
            page_size: defaultPageSize,
            virtualservice: this.virtualService.id,
            filters,
        };

        const initState: TVsLogsStateTypes = {
            apiParams,
            totalLogsCount: 0,
            tableProps: {
                customEndTime: String(),
                customDuration: Number(),
                isLoading: false,
                selectedLogs: [],
            },
            vsConfig,
            filterProps: {
                hasFilters: Boolean(filters.length),
                savedSearches: {},
                isCurrentFilterSaved: false,
            },
            apiLoopsInProgressStatus: {
                isGraphApiLoopInProgress: false,
                isTableApiLoopInProgress: false,
            },
            customTimeframeStatus: {
                isPageCustomTimeframe: false,
                isTableCustomTimeframe: false,
            },
            errorStates: {
                hasError: false,
                isErrorBannerEnabled: true,
                isTimedOut: false,
                isTimeoutBannerEnabled: true,
            },
        };

        this.vsLogsStore.initVsLogsState(initState);
    }
}
