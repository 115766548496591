/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { isUndefined } from 'underscore';

/**
 * SSL Config type returned from component store.
 */
interface IVsLogsSslConfig {
    ssl_version: string,
    sni_hostname: string,
    ocsp_status_resp_sent: boolean,
    ssl_cipher: string,
}

/**
 * SSL config type as a grid row.
 */
interface ISslGridRow {
    sslVersion: string;
    sslCertType: string;
    sniHostname: string;
    sslPfs: string;
    sslEnc: string;
    oscpStatus: string;
}

/**
 * @description
 *     Pipe for transforming SSL config of VS logs to list of rows,
 *     of which properties corresponds to SSL grid fields.
 * @author Kondiparthi Shanmukha Sarath
 */
@Pipe({
    name: 'logSslGridRows',
})
export class LogSslGridRowsPipe implements PipeTransform {
    /**
     * sslCipher has its contents as stringified KEY:VALUE pairs,
     * and regex /(?<=KEY:)(\S*)/s is used to extract VALUE from the string.
     */
    // eslint-disable-next-line class-methods-use-this
    public transform(vsLogsSslConfig: IVsLogsSslConfig): ISslGridRow[] {
        if (!vsLogsSslConfig) {
            return [];
        }

        const {
            ssl_version: sslVersion,
            sni_hostname: sniHostname,
            ocsp_status_resp_sent: oscpStatusRespSent,
            ssl_cipher: sslCipher,
        } = vsLogsSslConfig;

        const sslCertType = sslCipher?.split(/(?:auth:)(\S*)/s)[1];
        const sslPfs = sslCipher?.split(/(?:PFS:)(\S*)/s)[1];
        const sslEnc = sslCipher?.split(/(?:enc:)(\S*)/s)[1];
        const oscpStatus = oscpStatusRespSent?.toString();

        const sslGridRow: ISslGridRow = {
            sslVersion,
            sslCertType,
            sniHostname,
            sslPfs,
            sslEnc,
            oscpStatus,
        };

        const rowValues = Object.values(sslGridRow);
        const isEveryValUndefined = rowValues.every(val => isUndefined(val));

        return isEveryValUndefined ? [] : [sslGridRow];
    }
}
