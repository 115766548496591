/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @description Included repeated strings across all l10n.(js/ts) files in this file to avoid duplication.
 *
 * @author Shahab Hashmi, Zhiqian Liu, Alex Klyuev, vgohil
 */

const moduleName = 'global';
const prefix = `${moduleName}`;

export const nameLabel = `${prefix}.nameLabel`;
export const valueLabel = `${prefix}.valueLabel`;
export const editLabel = `${prefix}.editLabel`;
export const createLabel = `${prefix}.createLabel`;
export const addLabel = `${prefix}.addLabel`;
export const namePlaceholder = `${prefix}.namePlaceholder`;
export const valuePlaceholder = `${prefix}.valuePlaceholder`;
export const syslogLabel = `${prefix}.syslogLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const descriptionPlaceholder = `${prefix}.descriptionPlaceholder`;
export const controlScriptLabel = `${prefix}.controlScriptLabel`;
export const deleteLabel = `${prefix}.deleteLabel`;
export const removeLabel = `${prefix}.removeLabel`;
export const emailLabel = `${prefix}.emailLabel`;
export const wafProfileLabel = `${prefix}.wafProfileLabel`;
export const wafPolicyLabel = `${prefix}.wafPolicyLabel`;
export const virtualServicesLabel = `${prefix}.virtualServicesLabel`;
export const serviceEngineLabel = `${prefix}.serviceEngineLabel`;
export const serviceEngineGroupLabel = `${prefix}.serviceEngineGroupLabel`;
export const serversLabel = `${prefix}.serversLabel`;
export const serverLabel = `${prefix}.serverLabel`;
export const analyticsLabel = `${prefix}.analyticsLabel`;
export const healthLabel = `${prefix}.healthLabel`;
export const alertsLabel = `${prefix}.alertsLabel`;
export const httpMethodLabel = `${prefix}.httpMethodLabel`;
export const pathLabel = `${prefix}.pathLabel`;
export const queryLabel = `${prefix}.queryLabel`;
export const httpVersionLabel = `${prefix}.httpVersionLabel`;
export const pathMatchLabel = `${prefix}.pathLabel`;
export const queryMatchLabel = `${prefix}.queryLabel`;
export const headersLabel = `${prefix}.headersLabel`;
export const cookieLabel = `${prefix}.cookieLabel`;
export const hostHeaderLabel = `${prefix}.hostHeaderLabel`;
export const ipReputationLabel = `${prefix}.ipReputationLabel`;
export const ipReputationDbPlaceholder = `${prefix}.ipReputationDbPlaceholder`;
export const createRuleLabel = `${prefix}.createRuleLabel`;
export const menuLabel = `${prefix}.menuLabel`;
export const geoDbLabel = `${prefix}.geoDbLabel`;
export const matchingRulesLabel = `${prefix}.matchingRulesLabel`;
export const actionLabel = `${prefix}.actionLabel`;
export const portLabel = `${prefix}.portLabel`;
export const portPlaceholder = `${prefix}.portPlaceholder`;
export const emptyLabel = `${prefix}.emptyLabel`;
export const rbacLabel = `${prefix}.rbacLabel`;
export const sectionTitleRbac = `${prefix}.sectionTitleRbac`;
export const typeLabel = `${prefix}.typeLabel`;
export const searchLabel = `${prefix}.searchLabel`;
export const indexLabel = `${prefix}.indexLabel`;
export const clientIpAddressLabel = `${prefix}.clientIpAddressLabel`;
export const clientIpAddressPlaceholder = `${prefix}.clientIpAddressPlaceholder`;
export const healthScoreLabel = `${prefix}.healthScoreLabel`;
export const cancelLabel = `${prefix}.cancelLabel`;
export const saveLabel = `${prefix}.saveLabel`;
export const notSetLabel = `${prefix}.notSetLabel`;
export const tenantLabel = `${prefix}.tenantLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const yesValueLabel = `${prefix}.yesValueLabel`;
export const noValueLabel = `${prefix}.noValueLabel`;
export const generalLabel = `${prefix}.generalLabel`;
export const keyLabel = `${prefix}.keyLabel`;
export const keyPlaceholder = `${prefix}.keyPlaceholder`;
export const macAddressLabel = `${prefix}.macAddressLabel`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const ipAddressPlaceholder = `${prefix}.ipAddressPlaceholder`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const cloudPlaceholder = `${prefix}.cloudPlaceholder`;
export const poolGroupLabel = `${prefix}.poolGroupLabel`;
export const poolLabel = `${prefix}.poolLabel`;
export const createPoolLabel = `${prefix}.createPoolLabel`;
export const vendorLabel = `${prefix}.vendorLabel`;
export const vendorPlaceholder = `${prefix}.vendorPlaceholder`;
export const millisecondsLabel = `${prefix}.millisecondsLabel`;
export const msLabel = `${prefix}.msLabel`;
export const secLabel = `${prefix}.secLabel`;
export const secondsLabel = `${prefix}.secondsLabel`;
export const minutesLabel = `${prefix}.minutesLabel`;
export const authenticationLabel = `${prefix}.authenticationLabel`;
export const changeCredentialsButtonLabel = `${prefix}.changeCredentialsButtonLabel`;
export const setCredentialsButtonLabel = `${prefix}.setCredentialsButtonLabel`;
export const passwordLabel = `${prefix}.passwordLabel`;
export const passwordPlaceholder = `${prefix}.passwordPlaceholder`;
export const downloadLabel = `${prefix}.downloadLabel`;
export const dateLabel = `${prefix}.dateLabel`;
export const usernameLabel = `${prefix}.usernameLabel`;
export const usernamePlaceholder = `${prefix}.usernamePlaceholder`;
export const noItemsFoundLabel = `${prefix}.noItemsFoundLabel`;
export const notApplicableLabel = `${prefix}.notApplicableLabel`;
export const readLabel = `${prefix}.readLabel`;
export const writeLabel = `${prefix}.writeLabel`;
export const mapLabel = `${prefix}.mapLabel`;
export const stringLabel = `${prefix}.stringLabel`;
export const federatedLabel = `${prefix}.federatedLabel`;
export const anyLabel = `${prefix}.anyLabel`;
export const activeLabel = `${prefix}.activeLabel`;
export const daysLabel = `${prefix}.daysLabel`;
export const bytesLabel = `${prefix}.bytesLabel`;
export const lowerCaseBytesLabel = `${prefix}.lowerCaseBytesLabel`;
export const shortBytesLabel = `${prefix}.shortBytesLabel`;
export const issuerLabel = `${prefix}.issuerLabel`;
export const endpointsLabel = `${prefix}.endpointsLabel`;
export const mandatoryLabel = `${prefix}.mandatoryLabel`;
export const subnetLabel = `${prefix}.subnetLabel`;
export const customLabel = `${prefix}.customLabel`;
export const clientLabel = `${prefix}.clientLabel`;
export const appLabel = `${prefix}.appLabel`;
export const rolesLabel = `${prefix}.rolesLabel`;
export const rolesPlaceholder = `${prefix}.rolesPlaceholder`;
export const deactivatedLabel = `${prefix}.deactivatedLabel`;
export const credentialsLabel = `${prefix}.credentialsLabel`;
export const networkLabel = `${prefix}.networkLabel`;
export const eventsLabel = `${prefix}.eventsLabel`;
export const localLabel = `${prefix}.localLabel`;
export const statusLabel = `${prefix}.statusLabel`;
export const reasonLabel = `${prefix}.reasonLabel`;
export const ipv4Label = `${prefix}.ipv4Label`;
export const ipv6Label = `${prefix}.ipv6Label`;
export const networkPlaceholderLabel = `${prefix}.networkPlaceholderLabel`;
export const ipv4AddressInputLabel = `${prefix}.ipv4AddressInputLabel`;
export const ipv4AddressPlaceholderLabel = `${prefix}.ipv4AddressPlaceholderLabel`;
export const ipv6AddressInputLabel = `${prefix}.ipv6AddressInputLabel`;
export const ipv6AddressPlaceholderLabel = `${prefix}.ipv6AddressPlaceholderLabel`;
export const minimumLabel = `${prefix}.minimumLabel`;
export const ratioLabel = `${prefix}.ratioLabel`;
export const priorityLabel = `${prefix}.priorityLabel`;
export const deploymentStateLabel = `${prefix}.deploymentStateLabel`;
export const statusCodeLabel = `${prefix}.statusCodeLabel`;
export const uploadFileLabel = `${prefix}.uploadFileLabel`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const httpLabel = `${prefix}.httpLabel`;
export const httpsLabel = `${prefix}.httpsLabel`;
export const hostLabel = `${prefix}.hostLabel`;
export const hostPlaceholder = `${prefix}.hostPlaceholder`;
export const queryPlaceholder = `${prefix}.queryPlaceholder`;
export const pathPlaceholder = `${prefix}.pathPlaceholder`;
export const addressesLabel = `${prefix}.addressesLabel`;
export const connectLabel = `${prefix}.connectLabel`;
export const requestLabel = `${prefix}.requestLabel`;
export const responseLabel = `${prefix}.responseLabel`;
export const unknownLabel = `${prefix}.unknownLabel`;
export const megabyteLabel = `${prefix}.megabyteLabel`;
export const amLabel = `${prefix}.amLabel`;
export const logLabel = `${prefix}.logLabel`;
export const logsLabel = `${prefix}.logsLabel`;
export const matchLabel = `${prefix}.matchLabel`;
export const summaryLabel = `${prefix}.summaryLabel`;
export const serverIpLabel = `${prefix}.serverIPLabel`;
export const clientIpLabel = `${prefix}.clientIpLabel`;
export const serverNameLabel = `${prefix}.serverNameLabel`;
export const totalTimeLabel = `${prefix}.totalTimeLabel`;
export const poolNameLabel = `${prefix}.poolNameLabel`;
export const sourcePortLabel = `${prefix}.sourcePortLabel`;
export const serversTriedLabel = `${prefix}.serversTriedLabel`;
export const uriPathLabel = `${prefix}.uriPathLabel`;
export const sentToServerLabel = `${prefix}.sentToServerLabel`;
export const receivedFromServerLabel = `${prefix}.receivedFromServerLabel`;
export const requestLengthLabel = `${prefix}.requestLengthLabel`;
export const responseLengthLabel = `${prefix}.responseLengthLabel`;
export const responseCodeLabel = `${prefix}.responseCodeLabel`;
export const loadBalancerLabel = `${prefix}.loadBalancerLabel`;
export const messageLabel = `${prefix}.messageLabel`;
export const botLabel = `${prefix}.botLabel`;
export const notesLabel = `${prefix}.notesLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const totalLabel = `${prefix}.totalLabel`;
export const reloadLabel = `${prefix}.reloadLabel`;
export const isFederatedLabel = `${prefix}.isFederatedLabel`;
export const healthMonitorPortInputLabel = `${prefix}.healthMonitorPortInputLabel`;
export const healthMonitorPortInputPlaceholder = `${prefix}.healthMonitorPortInputPlaceholder`;
export const criteriaLabel = `${prefix}.criteriaLabel`;
export const hostClusterScopeServiceEngineWithinLabel = `${prefix}.hostClusterScopeServiceEngineWithinLabel`;
export const hostsPlaceholder = `${prefix}.hostsPlaceholder`;
export const clusterLabel = `${prefix}.clusterLabel`;
export const clustersPlaceholder = `${prefix}.clustersPlaceholder`;
export const includeLabel = `${prefix}.includeLabel`;
export const excludeLabel = `${prefix}.excludeLabel`;
export const dataStoreScopeForSeVmLabel = `${prefix}.dataStoreScopeForSeVmLabel`;
export const sharedLabel = `${prefix}.sharedLabel`;
export const dataStoreLabel = `${prefix}.dataStoreLabel`;
export const datastoresPlaceholder = `${prefix}.datastoresPlaceholder`;
export const percentLabel = `${prefix}.percentLabel`;
export const gigabytesLabel = `${prefix}.gigabytesLabel`;
export const maximumLabel = `${prefix}.maximumLabel`;
export const megabytesLabel = `${prefix}.megabytesLabel`;
export const serviceEnginesLabel = `${prefix}.serviceEnginesLabel`;
export const dnsLabel = `${prefix}.dnsLabel`;
export const kilobytesLabel = `${prefix}.kilobytesLabel`;
export const ruleNameLabel = `${prefix}.ruleNameLabel`;
export const controllerLabel = `${prefix}.controllerLabel`;
export const cloneLabel = `${prefix}.cloneLabel`;
export const trueLabel = `${prefix}.trueLabel`;
export const falseLabel = `${prefix}.falseLabel`;
export const noHttpHeadersToShowPlaceholder = `${prefix}.noHttpHeadersToShowPlaceholder`;
export const primaryLabel = `${prefix}.primaryLabel`;
export const doneLabel = `${prefix}.doneLabel`;
export const viewLabel = `${prefix}.viewLabel`;
export const defaultLabel = `${prefix}.defaultLabel`;

export const ENGLISH = {
    [nameLabel]: 'Name',
    [valueLabel]: 'Value',
    [editLabel]: 'Edit',
    [createLabel]: 'Create',
    [addLabel]: 'Add',
    [valuePlaceholder]: 'Enter Value',
    [namePlaceholder]: 'Enter Name',
    [syslogLabel]: 'Syslog',
    [descriptionLabel]: 'Description',
    [descriptionPlaceholder]: 'Enter Description',
    [controlScriptLabel]: 'Control Script',
    [deleteLabel]: 'Delete',
    [removeLabel]: 'Remove',
    [emailLabel]: 'Email',
    [wafProfileLabel]: 'WAF Profile',
    [wafPolicyLabel]: 'WAF Policy',
    [virtualServicesLabel]: 'Virtual Services',
    [serviceEngineLabel]: 'Service Engine',
    [serviceEngineGroupLabel]: 'Service Engine Group',
    [serversLabel]: 'Servers',
    [serverLabel]: 'Server',
    [analyticsLabel]: 'Analytics',
    [healthLabel]: 'Health',
    [alertsLabel]: 'Alerts',
    [httpMethodLabel]: 'HTTP Method',
    [pathLabel]: 'Path',
    [queryLabel]: 'Query',
    [httpVersionLabel]: 'HTTP Version',
    [pathMatchLabel]: 'Path',
    [queryMatchLabel]: 'Query',
    [headersLabel]: 'Headers',
    [cookieLabel]: 'Cookie',
    [hostHeaderLabel]: 'Host Header',
    [ipReputationLabel]: 'IP Reputation',
    [ipReputationDbPlaceholder]: 'Select IP Reputation DB',
    [createRuleLabel]: 'Create rule',
    [menuLabel]: 'Menu',
    [geoDbLabel]: 'Geo DB',
    [matchingRulesLabel]: 'Matching Rules',
    [actionLabel]: 'Action',
    [portLabel]: 'Port',
    [portPlaceholder]: 'Enter Port',
    [emptyLabel]: 'None',
    [sectionTitleRbac]: 'RBAC',
    [rbacLabel]: 'Role-Based Access Control (RBAC)',
    [typeLabel]: 'Type',
    [searchLabel]: 'Search',
    [indexLabel]: 'Index',
    [clientIpAddressLabel]: 'Client IP Address',
    [clientIpAddressPlaceholder]: 'Select IP Group',
    [healthScoreLabel]: 'Health Score',
    [cancelLabel]: 'Cancel',
    [saveLabel]: 'Save',
    [notSetLabel]: 'Not Set',
    [tenantLabel]: 'Tenant',
    [enabledLabel]: 'Enabled',
    [yesValueLabel]: 'Yes',
    [noValueLabel]: 'No',
    [generalLabel]: 'General',
    [keyLabel]: 'Key',
    [keyPlaceholder]: 'Enter Key',
    [macAddressLabel]: 'Mac Address',
    [ipAddressLabel]: 'IP Address',
    [ipAddressPlaceholder]: 'Enter IP Address',
    [cloudLabel]: 'Cloud',
    [cloudPlaceholder]: 'Select Cloud',
    [poolLabel]: 'Pool',
    [createPoolLabel]: 'Create Pool',
    [poolGroupLabel]: 'Pool Group',
    [vendorLabel]: 'Vendor',
    [vendorPlaceholder]: 'Select Vendor',
    [millisecondsLabel]: 'milliseconds',
    [msLabel]: 'ms',
    [secLabel]: 'sec',
    [secondsLabel]: 'Seconds',
    [minutesLabel]: 'Minutes',
    [authenticationLabel]: 'Authentication',
    [changeCredentialsButtonLabel]: 'Change Credentials',
    [setCredentialsButtonLabel]: 'Set Credentials',
    [passwordLabel]: 'Password',
    [passwordPlaceholder]: 'Enter Password',
    [downloadLabel]: 'Download',
    [dateLabel]: 'Date',
    [usernameLabel]: 'Username',
    [usernamePlaceholder]: 'Enter Username',
    [noItemsFoundLabel]: 'No Items Found',
    [notApplicableLabel]: 'N/A',
    [readLabel]: 'Read',
    [writeLabel]: 'Write',
    [mapLabel]: 'Map',
    [stringLabel]: 'String',
    [federatedLabel]: 'Federated',
    [anyLabel]: 'Any',
    [activeLabel]: 'Active',
    [daysLabel]: 'Days',
    [bytesLabel]: 'Bytes',
    [lowerCaseBytesLabel]: 'bytes',
    [shortBytesLabel]: 'B',
    [issuerLabel]: 'Issuer',
    [endpointsLabel]: 'Endpoints',
    [mandatoryLabel]: 'Mandatory',
    [subnetLabel]: 'Subnet',
    [customLabel]: 'Custom',
    [clientLabel]: 'Client',
    [appLabel]: 'App',
    [rolesLabel]: 'Roles',
    [rolesPlaceholder]: 'Select Roles',
    [deactivatedLabel]: 'Deactivated',
    [credentialsLabel]: 'Credentials',
    [networkLabel]: 'Network',
    [eventsLabel]: 'Events',
    [localLabel]: 'Local',
    [statusLabel]: 'Status',
    [reasonLabel]: 'Reason',
    [ipv4Label]: 'IPv4',
    [ipv6Label]: 'IPv6',
    [networkPlaceholderLabel]: 'Select Network',
    [ipv4AddressInputLabel]: 'IPv4 Address',
    [ipv4AddressPlaceholderLabel]: 'Enter IPv4 Address',
    [ipv6AddressInputLabel]: 'IPv6 Address',
    [ipv6AddressPlaceholderLabel]: 'Enter IPv6 Address',
    [minimumLabel]: 'Minimum',
    [ratioLabel]: 'Ratio',
    [priorityLabel]: 'Priority',
    [deploymentStateLabel]: 'Deployment State',
    [statusCodeLabel]: 'Status Code',
    [uploadFileLabel]: 'Upload File',
    [protocolLabel]: 'Protocol',
    [httpLabel]: 'HTTP',
    [httpsLabel]: 'HTTPS',
    [hostLabel]: 'Host',
    [hostPlaceholder]: 'Enter Host',
    [queryPlaceholder]: 'Enter Query',
    [pathPlaceholder]: 'Enter Path',
    [addressesLabel]: 'Addresses',
    [connectLabel]: 'Connect',
    [requestLabel]: 'Request',
    [responseLabel]: 'Response',
    [unknownLabel]: 'Unknown',
    [megabyteLabel]: 'MB',
    [amLabel]: 'AM',
    [logLabel]: 'Log',
    [logsLabel]: 'Logs',
    [matchLabel]: 'Match',
    [summaryLabel]: 'Summary',
    [serverIpLabel]: 'Server IP',
    [clientIpLabel]: 'Client IP',
    [serverNameLabel]: 'Server Name',
    [totalTimeLabel]: 'Total Time',
    [poolNameLabel]: 'Pool Name',
    [sourcePortLabel]: 'Source Port',
    [serversTriedLabel]: 'Server Tried',
    [uriPathLabel]: 'URI Path',
    [sentToServerLabel]: 'Sent to Sever',
    [receivedFromServerLabel]: 'Received from Server',
    [requestLengthLabel]: 'Request Length',
    [responseLengthLabel]: 'Response Length',
    [responseCodeLabel]: 'Response Code',
    [loadBalancerLabel]: 'Load Balancer',
    [messageLabel]: 'Message',
    [botLabel]: 'Bot',
    [notesLabel]: 'Notes',
    [versionLabel]: 'Version',
    [totalLabel]: 'Total',
    [reloadLabel]: 'Reload',
    [isFederatedLabel]: 'Is Federated',
    [healthMonitorPortInputLabel]: 'Health Monitor Port',
    [healthMonitorPortInputPlaceholder]: 'Enter Server Port',
    [criteriaLabel]: 'Criteria',
    [hostClusterScopeServiceEngineWithinLabel]: 'Host/Cluster Scope Service Engine Within',
    [hostsPlaceholder]: 'Select Hosts',
    [clusterLabel]: 'Cluster',
    [clustersPlaceholder]: 'Select Clusters',
    [includeLabel]: 'Include',
    [excludeLabel]: 'Exclude',
    [dataStoreScopeForSeVmLabel]: 'Data Store Scope for Service Engine Virtual Machine',
    [sharedLabel]: 'Shared',
    [dataStoreLabel]: 'Data Store',
    [datastoresPlaceholder]: 'Select Data Stores',
    [percentLabel]: 'Percent',
    [gigabytesLabel]: 'Gigabytes',
    [maximumLabel]: 'Maximum',
    [megabytesLabel]: 'Megabytes',
    [serviceEnginesLabel]: 'Service Engines',
    [dnsLabel]: 'DNS',
    [kilobytesLabel]: 'Kilobytes',
    [ruleNameLabel]: 'Rule Name',
    [controllerLabel]: 'Controller',
    [cloneLabel]: 'Clone',
    [trueLabel]: 'True',
    [falseLabel]: 'False',
    [noHttpHeadersToShowPlaceholder]: 'No HTTP Headers to Show!',
    [primaryLabel]: 'Primary',
    [doneLabel]: 'Done',
    [viewLabel]: 'View',
    [defaultLabel]: 'Default',
};
