/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { Cloud } from 'ajs/modules/cloud/factories/cloud.item.factory';
import { CLOUD_ITEM_TOKEN } from 'ajs/modules/cloud/cloud.tokens';

import {
    NetworkModalComponent,
} from 'ng/modules/network/components/network-modal/network-modal.component';

type TCloud = typeof Cloud;

const dataSources = {
    inventory: {
        source: 'InventoryCollDataSource',
        transformer: 'NetworkInventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
            'runtime',
            'discovery',
        ],
    },
};

/**
 * @desc
 *
 *     Contains configured networks only. Creation is available for this collection.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export class ConfiguredNetworkCollection extends Collection {
    public readonly cloudType: string;

    /** @constructor */
    constructor(args: Record<string, any> = {}) {
        const extendedArgs = {
            objectName: 'network-inventory',
            windowElement: NetworkModalComponent,
            defaultDataSources: 'inventory',
            allDataSources: dataSources,
            restrictCreateOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('ConfiguredNetwork');
        // TODO use params.cloud_uuid instead
        this.cloudType = args.cloudType || '';
    }

    /** @override */
    public isCreatable(): boolean {
        const Cloud: TCloud = this.getAjsDependency_(CLOUD_ITEM_TOKEN);

        return Cloud.allowsNetCreation(this.cloudType) && super.isCreatable();
    }
}

ConfiguredNetworkCollection.ajsDependencies = [
    CLOUD_ITEM_TOKEN,
    'ConfiguredNetwork',
];
