/** @module BotModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import {
    BotIpReputationTypeMappingItem,
} from 'ajs/modules/bot-detection-policy/factories/bot-ip-reputation-type-mapping.item.factory';
import { ClrFormLayout } from '@clr/angular';
import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import { IpReputationTypeMappingConfigItem } from
    'ajs/modules/bot-detection-policy/factories/ip-reputation-type-mapping.config-item.factory';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import * as l10n from './bot-ip-reputation-type-mapping-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Bot IP Reputation Type Mapping modal.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-ip-reputation-type-mapping-modal',
    templateUrl: './bot-ip-reputation-type-mapping-modal.component.html',
})
export class BotIpReputationTypeMappingModalComponent implements OnInit {
    @Input()
    public editable: BotIpReputationTypeMappingItem;

    @ViewChild('expandedIpReputationTypeMatchRef')
    public expandedIpReputationTypeMatchRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Is 'BotIpReputationTypeMapping'.
     */
    public objectType: string;

    /**
     * avi-data-grid rows for iPReputationTypeMappingGrid
     */
    public ipReputationTypeMappingGridRows: IpReputationTypeMappingConfigItem[] = [];

    /**
     * avi-data-grid config for iPReputationTypeMappingGrid
     */
    public ipReputationTypeMappingGridConfig: IAviDataGridConfig;

    constructor(
        public l10nService: L10nService,
        public readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.setIpReputationTypeMappingList();
        this.setIpReputationTypeMappingConfig();
    }

    /**
     * Handles user clicking 'Add'.
     */
    public createTypeMapping(): void {
        this.editable.createIpReputationMappingConfigItem();
    }

    /**
     * Convenience method for ip_reputation_mappings.
     */
    public get ipReputationMappings(): RepeatedMessageItem<IpReputationTypeMappingConfigItem> {
        return this.editable.getConfig().ip_reputation_mappings;
    }

    /**
     * Sets rows for avi-data-grid.
     */
    private setIpReputationTypeMappingList(): void {
        this.ipReputationTypeMappingGridRows = this.ipReputationMappings.getConfig();
    }

    /**
     * Sets config for avi-data-grid.
     */
    private setIpReputationTypeMappingConfig(): void {
        this.ipReputationTypeMappingGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.typeLabel),
                    id: 'type',
                    transform: (mapping: IpReputationTypeMappingConfigItem) => {
                        return this.schemaService.getEnumValueLabel(
                            'IPReputationType',
                            mapping.config.ip_reputation_type,
                        );
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.botClientClassLabel),
                    id: 'class',
                    transform: (mapping: IpReputationTypeMappingConfigItem) => {
                        return this.schemaService.getEnumValueLabel(
                            'BotClientClass',
                            mapping.botIdentificationConfig.class,
                        );
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.botClientTypeLabel),
                    id: 'bot-client-type',
                    transform: (mapping: IpReputationTypeMappingConfigItem) => {
                        return this.schemaService.getEnumValueLabel(
                            'BotClientType',
                            mapping.botIdentificationConfig.type,
                        );
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.identifierLabel),
                    id: 'identifier',
                    transform: (mapping: IpReputationTypeMappingConfigItem) => {
                        return mapping.botIdentificationConfig.identifier;
                    },
                },
            ],
            layout: {
                hideCheckboxes: true,
                withReordering: true,
            },
            singleactions: [
                {
                    label: this.l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (mapping: IpReputationTypeMappingConfigItem) => {
                        this.editable.editIpReputationMappingConfigItem(mapping);
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (mapping: IpReputationTypeMappingConfigItem) => {
                        this.ipReputationMappings.removeByMessageItem(mapping);
                    },
                },
            ],
        };
    }
}
