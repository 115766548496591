/**
 * Module containing Common UI components and Core services.
 * @preferred
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ClrAlertModule,
    ClrCheckboxModule,
    ClrDatalistModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrProgressBarModule,
    ClrRadioModule,
    ClrSpinnerModule,
} from '@clr/angular';
import { VIPModule } from '@vmw/ngx-vip';
import { TooltipModule } from 'ng/modules/tooltip/tooltip.module';
import { RightRailModule } from 'ng/modules/right-rail/right-rail.module';

import {
    BooleanColorDirective,
    InputFieldDirective,
} from './directives';

import {
    AsyncFileUploadComponent,
    AsyncFileUploadWithUploadServiceComponent,
    AviAlertGroupComponent,
    AviAppLevelAlertComponent,
    AviAutoCompleteComponent,
    AviCardComponent,
    AviCheckboxContainerGroupComponent,
    AviCollectionDropdownComponent,
    AviCollectionDropdownSelectedValuesContainerComponent,
    AviCustomCollectionDropdownComponent,
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviDropdownMenuComponent,
    AviDropdownOptionComponent,
    AviDropdownOptionsComponent,
    AviDropdownSelectedValuesContainerComponent,
    AviDropdownSingleSelectedValueComponent,
    AviIconDirective,
    AviInputContainerComponent,
    AviInputContainerErrorComponent,
    AviLabelWithTooltipComponent,
    AviPageHeaderComponent,
    AviRepeatedStringsComponent,
    AviSliderComponent,
    AviStatePageComponent,
    AviTextareaContainerComponent,
    BackendErrorMsgPipe,
    BooleanLabelPipe,
    ButtonLoaderComponent,
    CapitalizePipe,
    CopyrightComponent,
    ExpanderActionConfirmationComponent,
    ExpanderActionRowComponent,
    ExpanderButtonComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FileUploadComponent,
    FileUploadTextareaComponent,
    FormatBytesPipe,
    FormatJsonPipe,
    FullModalBreadcrumbComponent,
    FullModalBreadcrumbsComponent,
    FullModalComponent,
    FullModalConfigComponent,
    FullModalConfigErrorsComponent,
    FullModalConfigFooterComponent,
    FullModalConfigHeaderComponent,
    FullModalConfigHeaderTabsComponent,
    FullModalConfigViewerComponent,
    FullModalPreviewComponent,
    FullModalTabSectionComponent,
    GeoLocationStringPipe,
    GradientLoaderComponent,
    HealthScoreClassNamePipe,
    HelperTextTooltipIconComponent,
    ItemPreviewDefaultsComponent,
    ItemPreviewWrapperComponent,
    NamePipe,
    PreviewFieldComponent,
    SlugPipe,
    ToStringPipe,
    TriSwitchComponent,
} from '.';

const sharedComponents = [
    AsyncFileUploadComponent,
    AsyncFileUploadWithUploadServiceComponent,
    AviAppLevelAlertComponent,
    AviCardComponent,
    AviAlertGroupComponent,
    AviCheckboxContainerGroupComponent,
    AviCollectionDropdownComponent,
    AviCollectionDropdownSelectedValuesContainerComponent,
    AviCustomCollectionDropdownComponent,
    AviPageHeaderComponent,
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviDropdownMenuComponent,
    AviDropdownOptionComponent,
    AviDropdownOptionsComponent,
    AviDropdownSelectedValuesContainerComponent,
    AviDropdownSingleSelectedValueComponent,
    AviInputContainerComponent,
    AviInputContainerErrorComponent,
    AviLabelWithTooltipComponent,
    AviRepeatedStringsComponent,
    AviSliderComponent,
    AviStatePageComponent,
    AviTextareaContainerComponent,
    AviAutoCompleteComponent,
    ButtonLoaderComponent,
    CopyrightComponent,
    ExpanderActionConfirmationComponent,
    ExpanderActionRowComponent,
    ExpanderButtonComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FileUploadComponent,
    FileUploadTextareaComponent,
    FullModalBreadcrumbComponent,
    FullModalBreadcrumbsComponent,
    FullModalComponent,
    FullModalConfigComponent,
    FullModalConfigErrorsComponent,
    FullModalConfigFooterComponent,
    FullModalConfigHeaderComponent,
    FullModalConfigHeaderTabsComponent,
    FullModalConfigViewerComponent,
    FullModalPreviewComponent,
    PreviewFieldComponent,
    ItemPreviewDefaultsComponent,
    ItemPreviewWrapperComponent,
    FullModalTabSectionComponent,
    GradientLoaderComponent,
    HelperTextTooltipIconComponent,
    TriSwitchComponent,
];

const sharedDirectives = [
    AviIconDirective,
    BooleanColorDirective,
    InputFieldDirective,
];

const sharedPipes = [
    BackendErrorMsgPipe,
    BooleanLabelPipe,
    CapitalizePipe,
    FormatBytesPipe,
    FormatJsonPipe,
    GeoLocationStringPipe,
    HealthScoreClassNamePipe,
    NamePipe,
    SlugPipe,
    ToStringPipe,
];

@NgModule({
    declarations: [
        ...sharedComponents,
        ...sharedDirectives,
        ...sharedPipes,
    ],
    exports: [
        ...sharedPipes,
        AsyncFileUploadComponent,
        AsyncFileUploadWithUploadServiceComponent,
        AviAutoCompleteComponent,
        AviAlertGroupComponent,
        AviCardComponent,
        AviCheckboxContainerGroupComponent,
        AviCollectionDropdownComponent,
        AviCustomCollectionDropdownComponent,
        AviPageHeaderComponent,
        AviDropdownButtonComponent,
        AviDropdownComponent,
        AviDropdownMenuComponent,
        AviDropdownOptionComponent,
        AviDropdownOptionsComponent,
        AviIconDirective,
        AviInputContainerComponent,
        AviLabelWithTooltipComponent,
        AviRepeatedStringsComponent,
        AviSliderComponent,
        AviStatePageComponent,
        AviTextareaContainerComponent,
        BooleanColorDirective,
        ButtonLoaderComponent,
        CopyrightComponent,
        ExpanderComponent,
        ExpanderWithActionsComponent,
        FileUploadComponent,
        FileUploadTextareaComponent,
        FullModalComponent,
        FullModalConfigComponent,
        FullModalConfigViewerComponent,
        FullModalPreviewComponent,
        FullModalTabSectionComponent,
        GradientLoaderComponent,
        HelperTextTooltipIconComponent,
        InputFieldDirective,
        ItemPreviewDefaultsComponent,
        ItemPreviewWrapperComponent,
        PreviewFieldComponent,
        TooltipModule,
        TriSwitchComponent,
        VIPModule,
    ],
    imports: [
        BrowserAnimationsModule,
        ClrAlertModule,
        ClrCheckboxModule,
        ClrDatalistModule,
        ClrDropdownModule,
        ClrIconModule,
        ClrInputModule,
        ClrProgressBarModule,
        ClrRadioModule,
        ClrSpinnerModule,
        CommonModule,
        FormsModule,
        OverlayModule,
        RightRailModule,
        ScrollingModule,
        TooltipModule,
        VIPModule,
    ],
    providers: [
        BooleanLabelPipe,
        FormatBytesPipe,
        BackendErrorMsgPipe,
        CapitalizePipe,
        NamePipe,
        SlugPipe,
        FormatJsonPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
