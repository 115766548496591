/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-child-mode-setter';
const prefix = `${moduleName}.${componentName}`;

export const wafPolicyModeLabel = `${prefix}.wafPolicyModeLabel`;
export const modeDelegationMessage = `${prefix}.modeDelegationMessage`;
export const useParentModeValueLabel = `${prefix}.useParentModeValueLabel`;
export const modeUsedByMessage = `${prefix}.modeUsedByMessage`;

export const ENGLISH = {
    [wafPolicyModeLabel]: '{0} mode',
    [modeDelegationMessage]: 'Mode delegation is disabled for the policy.',
    [useParentModeValueLabel]: 'Use policy mode ({0})',
    [modeUsedByMessage]: 'mode is used by the {0}',
};
