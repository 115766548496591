/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { HSMSafenetLunaServerConfigItem } from 'ajs/modules/security';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group-safenet-luna-server-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Thales Luna HSM Server Grid component.
 * @author vgohil
 */
@Component({
    selector: 'hsm-group-safenet-luna-server-grid',
    templateUrl: './hsm-group-safenet-luna-server-grid.component.html',
})
export class HsmGroupSafenetLunaServerGridComponent implements AfterViewInit {
    /**
     * Thales Luna HSM Server RepeatedMessageItem instance.
     */
    @Input()
    public safenetLunaServerList: RepeatedMessageItem<HSMSafenetLunaServerConfigItem>;

    /**
     * Fires on Add Thales Luna HSM Server item.
     */
    @Output()
    public onAddSafenetLunaServer = new EventEmitter();

    /**
     * Fires on Remove Thales Luna HSM Server item.
     */
    @Output()
    public onRemoveSafenetLunaServer = new EventEmitter<HSMSafenetLunaServerConfigItem>();

    /**
     * Fires on Edit Thales Luna HSM Server item.
     */
    @Output()
    public onEditSafenetLunaServer = new EventEmitter<HSMSafenetLunaServerConfigItem>();

    /**
     * Thales Luna HSM Server grid config.
     */
    public safenetLunaServerGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @Override
     */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.safenetLunaServerGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleHsmAddress),
                    id: 'remote_ip',
                    transform(safenetLunaServer: HSMSafenetLunaServerConfigItem) {
                        return safenetLunaServer.config.remote_ip;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleSerialNo),
                    id: 'partition_serial_number',
                    transform(safenetLunaServer: HSMSafenetLunaServerConfigItem) {
                        return safenetLunaServer.config.partition_serial_number;
                    },
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (safenetLunaServerList: HSMSafenetLunaServerConfigItem[]) => {
                    safenetLunaServerList
                        .forEach((safenetLunaServer: HSMSafenetLunaServerConfigItem) => {
                            this.removeSafenetLunaServer(safenetLunaServer);
                        });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (safenetLunaServer: HSMSafenetLunaServerConfigItem) => {
                    this.editSafenetLunaServer(safenetLunaServer);
                },
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (safenetLunaServer: HSMSafenetLunaServerConfigItem) => {
                    this.removeSafenetLunaServer(safenetLunaServer);
                },
            }],
        };
    }

    /**
     * Handler for Thales Luna HSM Server item add operation.
     */
    public addSafenetLunaServer(): void {
        this.onAddSafenetLunaServer.emit();
    }

    /**
     * Handler for Thales Luna HSM Server item remove operation.
     */
    private removeSafenetLunaServer(safenetLunaServer: HSMSafenetLunaServerConfigItem): void {
        this.onRemoveSafenetLunaServer.emit(safenetLunaServer);
    }

    /**
     * Handler for Thales Luna HSM Server item edit operation.
     */
    private editSafenetLunaServer(safenetLunaServer: HSMSafenetLunaServerConfigItem): void {
        this.onEditSafenetLunaServer.emit(safenetLunaServer);
    }
}
