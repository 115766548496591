/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import {
    SnmpTrapProfileItem,
    SnmpTrapServerConfigItem,
} from 'ajs/modules/alert/factories';
import * as l10n from './snmp-trap-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Snmp Trap Profile full-modal component.
 *
 * @author Guru Prasad
 */
@Component({
    selector: 'snmp-trap-profile-modal',
    templateUrl: './snmp-trap-profile-modal.component.html',
})
export class SnmpTrapProfileModalComponent implements OnInit {
    /**
     * SnmpTrapProfileItem ObjectTypeItem instance.
     */
    @Input()
    public editable: SnmpTrapProfileItem;

    /**
     * SnmpTrapProfile object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Snmp Trap Profile Body modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Trap Profile grid config.
     */
    public trapProfileListGridConfig: IAviDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Opens SnmpTrapProfileList modal.
     */
    public createSnmpTrapServer(): void {
        this.editable.addSnmpTrapServer();
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        const {
            l10nService,
        } = this;

        this.trapProfileListGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.ipAddressLabel),
                    id: 'ip-addr',
                    transform({ config }) {
                        return config.ip_addr?.address || '';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.versionLabel),
                    id: 'version',
                    transform({ config }) {
                        return config.version || '';
                    },
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                onClick: (trapServers: SnmpTrapServerConfigItem[]) => {
                    trapServers.forEach((trapPage: SnmpTrapServerConfigItem) => {
                        this.deleteSnmpTrapServer(trapPage);
                    });
                },
            }],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (trapPage: SnmpTrapServerConfigItem) => {
                        this.editSnmpTrapServer(trapPage);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (trapPage: SnmpTrapServerConfigItem) => {
                        this.deleteSnmpTrapServer(trapPage);
                    },
                },
            ],
        };
    }

    /**
     * Handler for Snmp Traps edit.
     */
    private editSnmpTrapServer(trapPage: SnmpTrapServerConfigItem): void {
        this.editable.editSnmpTrapServer(trapPage);
    }

    /**
     * Handler for Snmp Traps delete.
     */
    private deleteSnmpTrapServer(trapPage: SnmpTrapServerConfigItem): void {
        const { snmpTrapServers } = this.editable;

        snmpTrapServers.removeByMessageItem(trapPage);
    }
}
