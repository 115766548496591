/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    DataScriptSetCollection,
    TDataScriptSetCollection,
} from 'ajs/modules/scripts/factories/data-script-set/data-script-set.collection.factory';

import { IAviCollectionDataGridConfig }
    from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { DataScriptSet } from 'ajs/modules/scripts/factories/data-script-set';
import { IVSDataScript } from 'generated-types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './data-script.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description DataScriptSet list page.
 * @author sghare
 */
@Component({
    selector: 'data-script',
    templateUrl: './data-script.component.html',
})
export class DataScriptComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display DataScriptSet collection.
     */
    public dataScriptSetGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the DataScriptSet items.
     */
    private dataScriptSetCollection: DataScriptSetCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject('DataScriptSetCollection')
        private readonly DataScriptSetCollection: TDataScriptSetCollection,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.dataScriptSetCollection = new DataScriptSetCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.dataScriptSetCollection;

        this.dataScriptSetGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.dataScriptSetCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: DataScriptSet): string => row.getName(),
                },
                {
                    id: 'event-types',
                    label: this.l10nService.getMessage(this.l10nKeys.eventTypesLabel),
                    transform: (row: DataScriptSet): string => this.transformEventType(
                        row.config.datascript,
                    ),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.dataScriptSetCollection.destroy();
    }

    /**
     * Returns comma seperated string of event type.
     */
    private transformEventType(datascripts: IVSDataScript[] = []): string {
        return datascripts.map((datascript: IVSDataScript) => {
            return this.schemaService.getEnumValueLabel('VSDataScriptEvent', datascript.evt);
        }).join(', ');
    }
}
