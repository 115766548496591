/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    BgpPeerState,
    IBGPPeersState,
} from 'generated-types';

import {
    Observable,
    Subject,
} from 'rxjs';

import { StateParams } from '@uirouter/core';
import { L10nService } from '@vmw/ngx-vip';
import { ConnectedPosition } from '@angular/cdk/overlay';
import * as globalL10n from 'global-l10n';

import {
    SignpostPosition,
    signpostPositionMap,
    SIGNPOST_RIGHT_CONNECTED_POSITION,
} from 'ng/modules/tooltip/components/signpost/signpost.constants';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    CdsIconStatus,
    IExtendedBgpPeerState,
} from './bgp-peers-page.types';

import { BgpPeersStateService } from '../../services/bgp-peers-state.service';
import * as l10n from './bgp-peers-page.l10n';
import './bgp-peers-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH: globalDictionary, ...globalL10nKeys } = globalL10n;

/**
 * @description Bgp Peers list page to show Bgp Peers state grid.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'bgp-peers-page',
    templateUrl: './bgp-peers-page.component.html',
})
export class BgpPeersPageComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for status column.
     */
    @ViewChild('statusFieldTemplateRef')
    public readonly statusFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Holds Bgp Peers State list.
     */
    public bgpPeersStateList: IExtendedBgpPeerState[];

    /**
     * Bgp Peers grid config.
     */
    public bgpPeersGridConfig: IAviDataGridConfig;

    /**
     * Used to show loading spinner if true.
     */
    public isLoading = false;

    /**
     * Errors to be displayed if an error occurs.
     */
    public errors: string | null;

    /**
     * Used to show bgp peer status.
     */
    public readonly bgpPeerStatusHash = {
        [BgpPeerState.BGP_PEER_ESTABLISHED]: CdsIconStatus.SUCCESS,
        [BgpPeerState.BGP_PEER_NOT_ESTABLISHED]: CdsIconStatus.DANGER,
        [BgpPeerState.BGP_PEER_PREFIX_EXCEEDED]: CdsIconStatus.WARNING,
    };

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash for bgp peer states label and reason.
     */
    public readonly bgpPeersStatesLabelAndReasonHash = {
        [BgpPeerState.BGP_PEER_IDLE]: {
            label: l10nKeys.bgpPeerIdleStateLabel,
            reason: l10nKeys.bgpPeerIdleStateReason,
        },
        [BgpPeerState.BGP_PEER_NOT_APPLICABLE_TO_THIS_SE]: {
            label: l10nKeys.bgpPeerNotApplicableStateLabel,
            reason: l10nKeys.bgpPeerNotApplicableStateReason,
        },
        [BgpPeerState.BGP_PEER_PREFIX_EXCEEDED]: {
            label: l10nKeys.bgpPeerPrefixExceededStateLabel,
            reason: l10nKeys.bgpPeerPrefixExceededStateReason,
        },
        [BgpPeerState.BGP_PEER_NOT_ESTABLISHED]: {
            label: l10nKeys.bgpPeerNotEstablishedStateLabel,
            reason: l10nKeys.bgpPeerNotEstablishedStateReason,
        },
        [BgpPeerState.BGP_PEER_ESTABLISHED]: {
            label: l10nKeys.bgpPeerEstablishedStateLabel,
            reason: '-',
        },
    };

    /**
     * Signpost position priorities.
     */
    public readonly signpostPositionPriorities: ConnectedPosition[] = [
        SIGNPOST_RIGHT_CONNECTED_POSITION,
    ];

    /**
     * Signpost position.
     */
    public readonly signpostPosition: SignpostPosition = signpostPositionMap
        .get(SIGNPOST_RIGHT_CONNECTED_POSITION);

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Subject to control the hide/show signpost close button.
     */
    private signpostCloseControlSubject = new Subject<boolean>();

    constructor(
        private readonly l10nService: L10nService,
        private readonly bgpPeersStateService: BgpPeersStateService,
        @Inject('$stateParams')
        private readonly $stateParams: StateParams,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.loadBgpPeers();
        this.initializeBgpPeersGridConfig();
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.bgpPeersGridConfig = {
            ...this.bgpPeersGridConfig,
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.statusLabel),
                    id: 'status',
                    templateRef: this.statusFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.peerIpColumnHeader),
                    id: 'peer-ip',
                    transform: ({ peer_ip }: IExtendedBgpPeerState) => peer_ip,
                },
                {
                    label: l10nService.getMessage(l10nKeys.upOrDownTimeColumnHeader),
                    id: 'up-or-down-time',
                    transform: ({ upOrDownTime }: IExtendedBgpPeerState) => upOrDownTime,
                },
                {
                    label: l10nService.getMessage(l10nKeys.vrfColumnHeader),
                    id: 'vrf',
                    transform: ({ vrfName }: IExtendedBgpPeerState) => vrfName,
                },
            ],
        };
    }

    /**
     * Calls loadBgpPeers method of service engine item to load bgp peers
     * for specific service engine.
     */
    public async loadBgpPeers(): Promise<void> {
        this.isLoading = true;

        const { seId } = this.$stateParams;

        try {
            const { data } = await this.bgpPeersStateService.fetchBgpPeersState(seId);

            this.setBgpPeersStateList(data);
        } catch ({ data }) {
            this.errors = data;
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * Returns bgp peers count.
     */
    public get bgpPeersCount(): number {
        return this.bgpPeersStateList?.length ?? 0;
    }

    /**
     * Returns true if bgp peer state is BGP_PEER_IDLE or BGP_PEER_NOT_APPLICABLE_TO_THIS_SE.
     */
    public isIdleOrNotApplicableState({ state }: IExtendedBgpPeerState): boolean {
        return state === BgpPeerState.BGP_PEER_IDLE ||
            state === BgpPeerState.BGP_PEER_NOT_APPLICABLE_TO_THIS_SE;
    }

    /**
     * Returns an observable to allow hide/show signpost to close itself through subscription.
     */
    public get signpostCloseButtonControl$(): Observable<boolean> {
        return this.signpostCloseControlSubject.asObservable();
    }

    /**
     * Close the signpost.
     */
    public closeSignPost(): void {
        this.signpostCloseControlSubject.next(false);
    }

    /**
     * Method to set bgp peers grid config.
     */
    private initializeBgpPeersGridConfig(): void {
        this.bgpPeersGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /**
     * Method to set BgpPeersState of all vrfs into one array to show bgp peers state in one grid.
     */
    private setBgpPeersStateList(bgpPeersState: IBGPPeersState[]): void {
        this.bgpPeersStateList = bgpPeersState.reduce((bgpPeersStateList, bgpPeerStateItem) => {
            // add vrf_name in peers_state objects.
            const bgpPeersStatesWithVrf = bgpPeerStateItem.peers_state?.map(peerState => ({
                ...peerState,
                vrfName: bgpPeerStateItem.vrf_name,
            })) || [];

            bgpPeersStateList.push(...bgpPeersStatesWithVrf);

            return bgpPeersStateList;
        }, []);
    }
}
