/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SSLProfile } from 'ajs/modules/security';
import { ISSLRating } from 'generated-types';
import { DevLoggerService } from 'ng/modules/core';

export interface ISSLRatingState {
    loading?: boolean;
    sslRating?: ISSLRating;
}

/**
 * Empty SSL Rating.
 */
const EMPTY_SSLRATING: ISSLRating = {};

/**
 * Default SSL Rating State.
 */
const DEFAULT_SSLRATING_STATE: ISSLRatingState = {
    loading: true,
    sslRating: EMPTY_SSLRATING,
};

/**
 * @description
 *
 *      Service which is used for main modal & side-rail component communication.
 *
 *      On SSLProfileUpdate, It fetches latest rating from Backend and emits to
 *      its subscribers.
 *
 * @author Aravindh Nagarajan
 */
@Injectable()
export class SslProfileModalService extends ComponentStore<ISSLRatingState> {
    /**
     * Returns SSL Rating from state.
     */
    public sslRating$ = this.select(state => state.sslRating);

    /**
     * Returns loading status from state.
     */
    public sslRatingLoadingStatus$ = this.select(state => state.loading);

    /**
     * Updater for SSL Rating State.
     */
    private setSslRatingState = this.updater(
        (state: ISSLRatingState, newState: ISSLRatingState) => {
            return {
                ...state,
                ...newState,
                sslRating: {
                    ...state.sslRating,
                    ...newState.sslRating,
                },
            };
        },
    );

    constructor(
        private readonly devLoggerService: DevLoggerService,
    ) {
        super(DEFAULT_SSLRATING_STATE);
    }

    /**
     * Fires on SSL profile update event.
     * @param sslProfile - sslProfile configItem which is being edited.
     * @param getRatingFromProfile - If true, use rating from profile, dont fetch.
     */
    public async fetchSSLRating(
        sslProfile: SSLProfile,
        getRatingFromProfile = false,
    ): Promise<void> {
        if (getRatingFromProfile) {
            const sslRatingState = this.createSslRatingState(sslProfile.sslRating?.config);

            this.setSslRatingState(sslRatingState);
        } else if (sslProfile.hasAcceptedVersionAndType()) {
            try {
                const sslRating = await sslProfile.fetchSSLRating();
                const sslRatingState = this.createSslRatingState(sslRating);

                this.setSslRatingState(sslRatingState);
            } catch (e) {
                this.devLoggerService.error(e);
            }
        } else {
            // pass empty object for invalid state.
            const sslRatingState = this.createSslRatingState(EMPTY_SSLRATING);

            this.setSslRatingState(sslRatingState);
        }
    }

    /**
     * Creates SSL Rating State of type ISSLRatingState for Service.
     */
    private createSslRatingState(sslRating: ISSLRating, loading = false): ISSLRatingState {
        return {
            sslRating,
            loading,
        };
    }
}
