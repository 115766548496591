/**
 * @module BotModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { IAviCollectionDataGridConfig }
    from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    BotMappingCollection,
    TBotMappingCollection,
} from 'ajs/modules/bot-detection-policy/factories/bot-mapping.collection.factory';

import { BotMappingItem }
    from 'ajs/modules/bot-detection-policy/factories/bot-mapping.item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './bot-mapping-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description bot-mapping list page.
 * @author Nitesh Kesakar
 */
@Component({
    selector: 'bot-mapping-page',
    templateUrl: './bot-mapping-page.component.html',
})
export class BotMappingPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display botMapping grid.
     */
    public botMappingGridConfig: IAviCollectionDataGridConfig;

    /**
     * botMapping Collection instance.
     */
    public readonly botMappingCollection: BotMappingCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(BotMappingCollection)
        BotMappingCollection: TBotMappingCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.botMappingCollection = new BotMappingCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.botMappingCollection;

        this.botMappingGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.botMappingCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: BotMappingItem) => row.getName(),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.placeholderMessage),
                hideDelete: true,
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.botMappingCollection.destroy();
    }
}
