/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { IRightRailExpanderConfig } from 'ng/modules/right-rail/components';

import {
    Subject,
    Subscription,
} from 'rxjs';

import { ConnectedPosition } from '@angular/cdk/overlay';

import {
    SignpostPosition,
    signpostPositionMap,
    SIGNPOST_BOTTOM_LEFT_CONNECTED_POSITION,
    SIGNPOST_LEFT_CONNECTED_POSITION,
    SIGNPOST_TOP_LEFT_CONNECTED_POSITION,
} from 'ng/modules/tooltip/components/signpost/signpost.constants';

import { VirtualServiceStateService }
    from 'ng/modules/virtual-service/services/virtual-service-state.service';
import { take } from 'rxjs/operators';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { VsLogsSignpostStore } from '../vs-logs-signpost/vs-logs-signpost.store';
import { VsLogsApiRequestService } from '../../services/vs-logs-api-request.service';
import { VsLogsStore } from '../../services/vs-logs.store';
import { TStateFilters } from '../../vs-logs.types';

import * as l10n from './vs-logs-sidebar.l10n';
import './vs-logs-sidebar.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *
 *     Component for setting the side bar tree view.
 *
 * @author Zhiqian Liu, Alex Klyuev, Suraj Kumar
 */
@Component({
    selector: 'vs-logs-sidebar',
    templateUrl: './vs-logs-sidebar.component.html',
})
export class VsLogsSidebarComponent implements OnInit, OnDestroy {
    /**
     * Config for generating the side bar expander list view.
     */
    public expanderListConfig: IRightRailExpanderConfig[];

    /**
     * Signpost position priorities.
     */
    public signpostPositionPriorities: ConnectedPosition[] = [
        SIGNPOST_LEFT_CONNECTED_POSITION,
        SIGNPOST_TOP_LEFT_CONNECTED_POSITION,
        SIGNPOST_BOTTOM_LEFT_CONNECTED_POSITION,
    ];

    /**
     * Decide signpost position relative to its parent.
     */
    public signpostPosition: SignpostPosition;

    /**
     * Reference to the virtual service item instance.
     */
    public readonly vs: any; // TODO AV-159345 update type.

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subscription to close the signpost.
     */
    public closeSignpostSubscription: Subscription;

    /**
     * Subject to control the hide/show signpost close button.
     */
    public signpostCloseButtonControl$ = new Subject<boolean>();

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        vsLogsApiRequestService: VsLogsApiRequestService,
        public readonly vsLogsStore: VsLogsStore,
        vsStateService: VirtualServiceStateService,
        private l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.vs = vsStateService.virtualService;

        this.closeSignpostSubscription = vsLogsSignpostStore.closeSignpost$.subscribe(() => {
            // Cancel all the ongoing signpost requests before making new requests.
            vsLogsSignpostStore.selectedSignpostRequestIds$.pipe(take(1)).subscribe(
                requestIds => requestIds?.forEach(
                    requestId => vsLogsApiRequestService.cancelRequest(requestId),
                ),
            );

            this.closeSignpost();
        });
    }

    /** @override */
    public ngOnInit(): void {
        this.setExpanderListViewConfig();
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.signpostCloseButtonControl$.complete();
        this.closeSignpostSubscription.unsubscribe();
    }

    /**
     * Notify signpost to update postion when the connected position from avi-tooltip changes
     */
    public handleSignpostConnectedPositionChange(
        signpostConnectedPosition: ConnectedPosition,
    ): void {
        this.signpostPosition = signpostPositionMap.get(signpostConnectedPosition);
    }

    /**
     * Close the signpost.
     */
    public closeSignpost(): void {
        this.signpostCloseButtonControl$.next(false);
    }

    /**
     * Set default state when clicked outside signpost.
     */
    public handleSignpostClosed(isOpen: boolean): void {
        if (!isOpen) {
            this.vsLogsSignpostStore.closeSignpost();
            this.vsLogsSignpostStore.setDefaultState();
        }
    }

    /**
     * Add saved filters to the search bar.
     */
    public addSavedSearchFilters(queryStrings: TStateFilters): void {
        this.vsLogsStore.replaceFilterQueryStrings(queryStrings);
    }

    /**
     * Remove the given saved search from the store.
     */
    public removeSavedSearch(e: MouseEvent, name: string): void {
        // stop click events in parent components from running
        e.stopPropagation();
        this.vsLogsStore.removeSavedSearch(name);
    }

    /**
     * Set the config for the expander list view based on headers and children constants.
     */
    private setExpanderListViewConfig(): void {
        const { vs } = this;

        if (vs.isDNS()) {
            this.setDnsExpanderListViewConfig();
        } else {
            this.setNonDnsExpanderListViewConfig();
        }
    }

    /**
     * Set the config for the expander list view of DNS VS based on headers and children constants.
     */
    private setDnsExpanderListViewConfig(): void {
        const { vs } = this;

        this.expanderListConfig = [
            {
                icon: 'shield',
                headerText: this.l10nService.getMessage(l10nKeys.wafAnalyticsLabel),
                hidden: false,
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.tcpEndToEndLabel),
                        configKey: 'e2e-timing',
                        hidden: !(vs.hasPoolRef() && vs.hasTCPProxyNetProfile()),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.udpEndToEndLabel),
                        configKey: 'e2e-timing-udp',
                        hidden: !(vs.hasPoolRef() && vs.hasUDPNetProfile()),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.fqdnDomainNameLabel),
                        configKey: 'dns_fqdn',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.protocolLabel),
                        configKey: 'protocol',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(
                            globalL10nKeys.clientIpAddressLabel,
                        ),
                        configKey: 'ip-addr',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.serverIpAddressLabel),
                        configKey: 'server-ip-addr',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.locationLabel),
                        configKey: 'country',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.queryTypeLabel),
                        configKey: 'dns_qtype',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.recordSourceLabel),
                        configKey: 'dns_etype',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.significanceLabel),
                        configKey: 'significance',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.gslbServiceNameLabel),
                        configKey: 'gslbservice_name',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.serviceEngineLabel),
                        configKey: 'service_engine',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.vsIpAddressLabel),
                        configKey: 'vs_ip',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.requestLengthLabel),
                        configKey: 'rx_bytes',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(
                            globalL10nKeys.responseLengthLabel,
                        ),
                        configKey: 'tx_bytes',
                        hidden: false,
                    },
                ],
            },

        ];
    }

    /**
     * Set the config for the expander list view of non DNS VS based on headers and
     * children constants.
     */
    private setNonDnsExpanderListViewConfig(): void {
        const { vs } = this;

        this.expanderListConfig = [
            {
                icon: 'shield',
                headerText: this.l10nService.getMessage(l10nKeys.wafAnalyticsLabel),
                hidden: !vs.hasWAFPolicy(),
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.wafTagsLabel),
                        configKey: 'waf_log.rule_logs.tags',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.wafRulesLabel),
                        configKey: 'waf_log.rule_logs.rule_id',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.wafGroupsLabel),
                        configKey: 'waf-rule-groups',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.wafLatencyLabel),
                        configKey: 'waf-phase-latency',
                        hidden: !vs.isHTTP(),
                    },
                ],
            },
            {
                icon: 'user',
                headerText: this.l10nService.getMessage(l10nKeys.clientAnalyticsLabel),
                hidden: false,
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.browserLabel),
                        configKey: 'browser',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.clientOsLabel),
                        configKey: 'client_os',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.deviceLabel),
                        configKey: 'device',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                        configKey: 'ip-addr',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.locationLabel),
                        configKey: 'country',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.endToEndLabel),
                        configKey: 'e2e-timing',
                        hidden: vs.hasTCPFastPathNetProfile(true),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.userIdLabel),
                        configKey: 'user_id',
                        hidden: !vs.isHTTP(),
                    },
                ],
            },
            {
                icon: 'share',
                hidden: false,
                headerText: this.l10nService.getMessage(l10nKeys.loadBalancerAnalyticsLabel),
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.policiesLabel),
                        configKey: 'policy',
                        hidden: !(vs.isHTTP() && vs.policyEnabled()),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.vsIpAddressLabel),
                        configKey: 'vs_ip',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.serverIpAddressLabel),
                        configKey: 'server-ip-addr',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.serviceEngineLabel),
                        configKey: 'service_engine',
                        hidden: false,
                    },
                ],
            },
            {
                icon: 'switch',
                hidden: !(vs.isHTTP() || vs.appType() === 'ssl'),
                headerText: this.l10nService.getMessage(l10nKeys.requestAnalyticsLabel),
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.sslLabel),
                        configKey: 'ssl',
                        hidden: !vs.sslEnabled(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.refererLabel),
                        configKey: 'referrer',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.urlPathLabel),
                        configKey: 'uri_path',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.hostHeaderLabel),
                        configKey: 'host',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.httpVersionLabel),
                        configKey: 'http_version',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.requestLengthLabel),
                        configKey: 'request_length',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.requestTypeLabel),
                        configKey: 'request-type',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.responseCodeLabel),
                        configKey: 'response-code',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.responseContentTypeLabel),
                        configKey: 'response_content_type',
                        hidden: !vs.isHTTP(),
                    },
                    {
                        displayText: this.l10nService.getMessage(
                            globalL10nKeys.responseLengthLabel,
                        ),
                        configKey: 'response_length',
                        hidden: !vs.isHTTP(),
                    },
                ],
            },
            {
                icon: 'bot',
                hidden: !vs.hasBotDetectionPolicy(),
                headerText: this.l10nService.getMessage(l10nKeys.botAnalyticsLabel),
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.classificationsLabel),
                        configKey: 'bot_management_log.classification.type',
                        hidden: false,
                    },
                ],
            },
            {
                icon: 'shield',
                hidden: !vs.hasIcapProfile(),
                headerText: this.l10nService.getMessage(l10nKeys.icapAnalyticsLabel),
                children: [
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.actionLabel),
                        configKey: 'icap_log.action',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.latencyLabel),
                        configKey: 'icap_log.request_logs.latency',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.icapResponseCodeLabel),
                        configKey: 'icap_log.request_logs.icap_response_code',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.httpResponseCodeLabel),
                        configKey: 'icap_log.request_logs.http_response_code',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(globalL10nKeys.serverIpLabel),
                        configKey: 'icap_log.request_logs.icap_server_ip',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(
                            l10nKeys.violationResolutionsLabel,
                        ),
                        configKey: 'icap_log.request_logs.opswat_log.violations.resolution',
                        hidden: false,
                    },
                    {
                        displayText: this.l10nService.getMessage(
                            l10nKeys.violationThreatNamesLabel,
                        ),
                        configKey: 'icap_log.request_logs.opswat_log.violations.threat_name',
                        hidden: false,
                    },
                ],
            },
            {
                icon: 'exclamation-circle',
                hidden: false,
                headerText: this.l10nService.getMessage(l10nKeys.significanceAnalyticsLabel),
                children: [
                    {
                        displayText: this.l10nService.getMessage(l10nKeys.significanceLabel),
                        configKey: 'significance',
                        hidden: false,
                    },
                ],
            },
        ];
    }
}
