/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './logged-out-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias module:component/loggedOutPage
 * @private
 */
class LoggedOutPageComponent {
    // Login route provided in user logged-out page for SAML user.
    samlLoginRoute = 'login';

    constructor(
        initialDataService,
        $state,
        appStateConstants,
        l10nService,
    ) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        if (initialDataService.redirectUri) {
            this.redirectUrl = initialDataService.redirectUri;
        }

        // TODO AV-186450: verify if we still need this codition.
        if (initialDataService.isOauthRedirection) {
            const { LOGIN_STATE } = appStateConstants;

            $state.go(LOGIN_STATE);
        }
    }
}

LoggedOutPageComponent.$inject = [
    'initialDataService',
    '$state',
    'appStateConstants',
    'l10nService',
];

/**
 * @ngdoc component
 * @name LoggedOutPageComponent
 * @author Shanmukha Sarath Kondiparthi
 * @module components/loggedOutPage
 * @desc
 *
 *     Logged out page component. It checks for initial data of controller if
 *     user should be taken to SAML Login, if access token is present in cookies.
 */
angular.module('aviApp').component('loggedOutPage', {
    controller: LoggedOutPageComponent,
    templateUrl: 'src/components/pages/logged-out-page/logged-out-page.html',
});
