/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
    TStringRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { L10nService } from '@vmw/ngx-vip';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './infoblox-usable-domains-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for usable domains configuration in infoblox-dns profile.
 * *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'infoblox-usable-domains-grid',
    templateUrl: './infoblox-usable-domains-grid.component.html',
})
export class InfobloxUsableDomainsGridComponent implements OnInit, OnChanges, AfterViewInit {
    /**
     * Binding for ipamDnsInfobloxProfile::usable_domains
     */
    @Input()
    public usableDomains: TStringRow[];

    /**
     * True if credentials are connected.
     */
    @Input()
    public connected = false;

    /**
     * List of available domains.
     */
    @Input()
    public domainList: string[] = [];

    /**
     * Fires on add operation.
     */
    @Output()
    public onAddUsableDomain: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on delete/removeAll of usable domains.
     */
    @Output()
    public onDeleteUsableDomain: EventEmitter<TStringRow> = new EventEmitter();

    /**
     * TemplateRef for Usable domain dropdown.
     */
    @ViewChild('usableDomainTemplateRef')
    public usableDomainTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Usable Domains Grid config.
     */
    public infobloxUsableDomainsGridConfig: IAviDataGridConfig;

    /**
     * Prefix for Usable domain row (name & id).
     */
    public readonly usableDomainPrefix = 'ipv4_usable_subnet_';

    /**
     * Domain dropdown options.
     */
    public domainDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setUsableDomainDropdownOptions();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { domainList } = changes;

        if (domainList && !domainList.firstChange) {
            this.setUsableDomainDropdownOptions();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.infobloxUsableDomainsGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleUsableDomain),
                id: 'usable_domain',
                templateRef: this.usableDomainTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (usableDomains: TStringRow[]) => {
                    usableDomains.forEach((usableDomain: TStringRow) => {
                        this.deleteUsableDomain(usableDomain);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (usableDomain: TStringRow) => {
                    this.deleteUsableDomain(usableDomain);
                },
            }],
        };
    }

    /**
     * Emits Add Usable domain event.
     */
    public addUsableDomain(): void {
        this.onAddUsableDomain.emit();
    }

    /**
     * Emits Delete Usable domain event.
     */
    private deleteUsableDomain(domain: TStringRow): void {
        this.onDeleteUsableDomain.emit(domain);
    }

    /**
     * Sets domain dropdown options.
     */
    private setUsableDomainDropdownOptions(): void {
        this.domainDropdownOptions = this.domainList.map(domain => createDropdownOption(domain));
    }
}
