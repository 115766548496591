/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    IWafContentTypeMapping,
    StringOperation,
} from 'generated-types';

import { WafContentTypeMapping } from 'object-types';

interface IWafContentTypeMappingConfig extends IWafContentTypeMapping {
    regexMatch: boolean; // UI only.
}

/**
 * @description Waf Content Type Mapping config Item.
 * @author Hitesh Mandav
 */
export class WafContentTypeMappingConfigItem extends MessageItem<IWafContentTypeMappingConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: WafContentTypeMapping,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Set regexMatch value based on match_op.
     * regexMatch is used to set the checkbox status based on match_op.
     * @override
     */
    public modifyConfigDataAfterLoad(): void {
        const { match_op: matchOp } = this.config;

        super.modifyConfigDataAfterLoad();

        this.config.regexMatch = matchOp === StringOperation.REGEX_MATCH;
    }

    /**
     * Set match_op value based on regexMatch.
     * @override
     */
    public modifyConfigDataBeforeSave(): void {
        const { regexMatch } = this.config;

        super.modifyConfigDataBeforeSave();

        this.config.match_op = regexMatch ? StringOperation.REGEX_MATCH : StringOperation.EQUALS;

        delete this.config.regexMatch;
    }
}
