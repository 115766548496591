/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-signpost';
const prefix = `${moduleName}.${componentName}`;

export const defaultErrorMsgLabel = `${prefix}.defaultErrorMsgLabel`;
export const reloadPageLabel = `${prefix}.reloadPageLabel`;
export const emptyDataMessageLabel = `${prefix}.emptyDataMessageLabel`;
export const timeoutMessageLabel = `${prefix}.timeoutMessageLabel`;

export const ENGLISH = {
    [defaultErrorMsgLabel]: 'An error occurred while loading the signpost logs, please try again.',
    [reloadPageLabel]: 'Reload Page',
    [emptyDataMessageLabel]: 'No Data Available',
    [timeoutMessageLabel]: 'We were unable to fetch all the logs. Please reduce the timeframe or refresh logs.',
};
