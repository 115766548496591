/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Subject, Subscription } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import moment from 'moment';
import {
    IApplicationLog,
    IConnectionLog,
    IWafRuleLog,
} from 'generated-types';
import { DevLoggerService } from 'ng/modules/core';

import { VsLogsStore } from '../../services/vs-logs.store';
import {
    TLogEntrySignatureParams,
    TVsLog,
    TVsLogEntryResponseData,
    TVsLogStateParams,
    VsLogsType,
} from '../../vs-logs.types';
import { VsLogsEffectsService } from '../../services/vs-logs-effects.service';
import { convertLogEntryParamsToQueryStrings } from '../../utils/vs-logs-filters.utils';
import {
    IL4ConnectionInfo,
    TVsLogApplicationInfo,
    TVsLogHttpRequestHeaders,
    TVsLogHttpResponseHeaders,
} from './vs-log-cinematic.types';

const VS_LOG_ENTRY_REQUEST_ID = 'VS_LOG_ENTRY_REQUEST';

/**
 * State type for the store.
 * There can be either applicationLog or connectionLog set as they are mutually exlusive.
 * @param applicationLog - Exists only when the VS is of type L7.
 * @param connectionLog - Exists only when the VS is of type L4 or DNS.
 * @param isLoading - Flag to indicate whether a request is in progress.
 * @param errors - Errors when loading the log.
 * @param hasError - Needed because there can be no error message/object, eg. status code 500.
 */
interface IStateTypes {
    applicationLog: IApplicationLog;
    connectionLog: IConnectionLog;
    isLoading: boolean;
    errors: string | object;
    hasError: boolean;
}

const initialState: IStateTypes = {
    applicationLog: {},
    connectionLog: {},
    isLoading: false,
    errors: null,
    hasError: false,
};

/**
 * Needed since "isEmpty" from Underscore only detects emptiness for an object when there's no key.
 */
const isObjectEmpty = (obj: Record<string, any>): boolean => {
    if (!obj || Object.keys(obj).length === 0) {
        return true;
    }

    return Object.keys(obj).every(key => obj[key] === null || obj[key] === undefined);
};

/**
 * @description
 *     State and Effect management for VsLogCinematic and its children components.
 * @author Zhiqian Liu, Alex Klyuev
 */
@Injectable()
export class VsLogCinematicStore extends ComponentStore<IStateTypes> implements OnDestroy {
    public readonly applicationLog$ = this.select(state => state.applicationLog);

    public readonly connectionLog$ = this.select(state => state.connectionLog);

    /**
     * Select the log loaded.
     * If nothing loaded, an empty object is guaranteed to be returned.
     */
    public readonly vsLog$ = this.select<TVsLog>(
        ({ applicationLog, connectionLog }) => {
            return !isObjectEmpty(applicationLog) ? applicationLog : connectionLog;
        },
    );

    public readonly isLoading$ = this.select(state => state.isLoading);

    public readonly errors$ = this.select(state => state.errors);

    public readonly hasError$ = this.select(state => state.hasError);

    /**
     * Indicate whether a Log has Avi defined filters.
     */
    public readonly isSignificantLog$ = this.select(this.vsLog$, ({ adf }) => adf);

    // ******************************** Generic Log Selectors ************************************

    public readonly timestamp$ = this.select(
        this.vsLog$,
        ({ report_timestamp }) => report_timestamp,
    );
    public readonly clientRtt$ = this.select(this.vsLog$, ({ client_rtt }) => client_rtt);

    public readonly serverRtt$ = this.select(this.vsLog$, ({ server_rtt }) => server_rtt);

    public readonly totalTime$ = this.select(this.vsLog$, ({ total_time }) => (
        total_time ? Number(total_time) : total_time
    ));

    public readonly pool$ = this.select(this.vsLog$, ({ pool }) => pool);

    public readonly poolName$ = this.select(this.vsLog$, ({ pool_name }) => pool_name);

    public readonly clientIps$ = this.select(
        this.vsLog$, ({ client_ip6, client_ip }) => {
            return {
                client_ip,
                client_ip6,
            };
        },
    );

    public readonly clientSrcPort$ = this.select(
        this.vsLog$,
        ({ client_src_port }) => client_src_port,
    );

    public readonly clientDestPort$ = this.select(
        this.vsLog$,
        ({ client_dest_port }) => client_dest_port,
    );

    public readonly vsIps$ = this.select(
        this.vsLog$, ({ vs_ip, vs_ip6 }) => {
            return {
                vs_ip,
                vs_ip6,
            };
        },
    );

    public readonly serverName$ = this.select(
        this.vsLog$,
        ({ server_name }) => server_name,
    );

    public readonly serverIps$ = this.select(
        this.vsLog$, ({ server_ip, server_ip6 }) => {
            return {
                server_ip,
                server_ip6,
            };
        },
    );

    public readonly serverSrcPort$ = this.select(
        this.vsLog$, ({ server_src_port }) => server_src_port,
    );

    public readonly serverDestPort$ = this.select(
        this.vsLog$, ({ server_dest_port }) => server_dest_port,
    );

    public readonly serverConnectionSrcIps$ = this.select(
        this.vsLog$,
        ({ server_conn_src_ip, server_conn_src_ip6 }) => {
            return {
                server_conn_src_ip,
                server_conn_src_ip6,
            };
        },
    );

    public readonly serviceEngine$ = this.select(
        this.vsLog$, ({ service_engine }) => service_engine,
    );

    public readonly vcpuId$ = this.select(this.vsLog$, ({ vcpu_id }) => vcpu_id);

    public readonly significance$ = this.select(this.vsLog$, ({ significance }) => significance);

    // ****************************** Application Log Selectors **********************************

    public readonly responseCode$ = this.select(
        ({ applicationLog: { response_code } }) => response_code,
    );

    public readonly serverResponseCode$ = this.select(
        ({ applicationLog: { server_response_code } }) => server_response_code,
    );

    public readonly appResponseTime$ = this.select(
        ({ applicationLog: { app_response_time } }) => (
            app_response_time ? Number(app_response_time) : app_response_time
        ),
    );

    public readonly dataTransferTime$ = this.select(
        ({ applicationLog: { data_transfer_time } }) => (
            data_transfer_time ? Number(data_transfer_time) : data_transfer_time
        ),
    );

    // TODO: need to confirm what comes as source IP port
    public readonly sourceIps$ = this.select(
        ({ applicationLog: { source_ip, source_ip6 } }) => {
            return {
                source_ip,
                source_ip6,
            };
        },
    );

    public readonly l7ClientInfo$ = this.select(
        ({ applicationLog: {
            client_location,
            client_os,
            client_device,
            client_browser,
            report_timestamp,
            total_time,
            user_id,
        } }) => {
            return {
                client_location,
                client_os,
                client_device,
                client_browser,
                startTime: moment(report_timestamp).subtract(total_time, 'ms').toString(),
                endTime: report_timestamp,
                user_id,
            };
        },
    );

    public readonly ssl$ = this.select(
        ({ applicationLog: {
            ssl_version,
            sni_hostname,
            ocsp_status_resp_sent,
            ssl_cipher,
            client_fingerprints,
        } }) => {
            const ssl = {
                ssl_version,
                sni_hostname,
                ocsp_status_resp_sent,
                ssl_cipher,
                client_fingerprints,
            };

            if (isObjectEmpty(ssl)) {
                return null;
            }

            return ssl;
        },
    );

    public readonly policyRules$ = this.select(
        ({ applicationLog: {
            network_security_policy_rule_name,
            http_security_policy_rule_name,
            http_request_policy_rule_name,
            http_response_policy_rule_name,
        } }) => {
            return {
                networkSecurityPolicyRule: network_security_policy_rule_name,
                httpSecurityPolicyRule: http_security_policy_rule_name,
                httpRequestPolicyRule: http_request_policy_rule_name,
                httpResponsePolicyRule: http_response_policy_rule_name,
            };
        },
    );

    public readonly requestInfo$ = this.select(
        ({ applicationLog: {
            request_id,
            host,
            method,
            uri_path,
            uri_query,
            user_agent,
            http_version,
            request_length,
            request_content_type,
        } }) => {
            return {
                requestId: request_id,
                host,
                requestMethod: method,
                uri: uri_path,
                uriQuery: uri_query,
                userAgent: user_agent,
                http_version,
                request_length,
                request_content_type,
            };
        },
    );

    public readonly requestDetails$ = this.select(
        ({ applicationLog: {
            method,
            http_version,
            request_length,
            request_content_type,
        } }) => {
            const requestDetails = {
                method,
                http_version,
                request_length,
                request_content_type,
            };

            if (isObjectEmpty(requestDetails)) {
                return null;
            }

            return requestDetails;
        },
    );

    public readonly responseInfo$ = this.select(
        ({ applicationLog: {
            server_response_length,
            grpc_status,
        } }) => {
            return {
                responseLength: server_response_length ?
                    Number(server_response_length) :
                    server_response_length,
                grpcStatus: grpc_status === -1 ? undefined : grpc_status,
            };
        },
    );

    public readonly httpRequestHeaders$ = this.select<TVsLogHttpRequestHeaders>(
        ({ applicationLog: {
            all_request_headers,
            headers_sent_to_server,
        } }) => {
            const httpResponseHeaders = {
                all_request_headers,
                headers_sent_to_server,
            };

            if (isObjectEmpty(httpResponseHeaders)) {
                return null;
            }

            return httpResponseHeaders;
        },
    );

    public readonly httpResponseHeaders$ = this.select<TVsLogHttpResponseHeaders>(
        ({ applicationLog: {
            all_response_headers,
            headers_received_from_server,
        } }) => {
            const httpResponseHeaders = {
                all_response_headers,
                headers_received_from_server,
            };

            if (isObjectEmpty(httpResponseHeaders)) {
                return null;
            }

            return httpResponseHeaders;
        },
    );

    public readonly applicationInfo$ = this.select<TVsLogApplicationInfo>(
        ({ applicationLog: {
            http2_stream_id,
            server_push_initiated,
            server_pushed_request,
            cache_hit,
            compression,
            compression_percentage,
            servers_tried,
            method,
        } }) => {
            const applicationInfo = {
                http2_stream_id,
                server_push_initiated,
                server_pushed_request,
                cache_hit,
                compression,
                compression_percentage,
                servers_tried,
                method,
            };

            if (isObjectEmpty(applicationInfo)) {
                return null;
            }

            return applicationInfo;
        },
    );

    public readonly grpcInfo$ = this.select(
        ({ applicationLog: {
            grpc_service_name,
            grpc_method_name,
            grpc_status_reason_phrase,
        } }) => {
            const grpcInfo = {
                grpc_service_name,
                grpc_method_name,
                grpc_status_reason_phrase,
            };

            if (isObjectEmpty(grpcInfo)) {
                return null;
            }

            return grpcInfo;
        },
    );

    public readonly datascriptInfo$ = this.select(
        ({ applicationLog: {
            datascript_error_trace,
            datascript_log,
        } }) => {
            const datascriptInfo = {
                datascript_error_trace,
                datascript_log,
            };

            if (isObjectEmpty(datascriptInfo)) {
                return null;
            }

            return datascriptInfo;
        },
    );

    public readonly wafAllowListLogs$ = this.select(
        ({ applicationLog: { waf_log: wafLog } }) => wafLog?.allowlist_logs,
    );

    public readonly wafLog$ = this.select(
        ({ applicationLog: { waf_log: wafLog } }) => wafLog,
    );

    public readonly wafPsmLogs$ = this.select(
        ({ applicationLog: { waf_log: wafLog } }) => wafLog?.psm_logs,
    );

    public readonly wafStatus$ = this.select(
        ({ applicationLog: { waf_log: wafLog } }) => wafLog?.status,
    );

    /** Datascript request logs under out of band log */
    public readonly oobDsRequestLogs$ = this.select(
        ({ applicationLog: { oob_log } }) => oob_log?.ds_req_logs,
    );

    public readonly samlLog$ = this.select(
        ({ applicationLog: { saml_log } }) => saml_log,
    );

    public readonly oAuthLog$ = this.select(
        ({ applicationLog: { oauth_log } }) => oauth_log,
    );

    public readonly paaLog$ = this.select(
        ({ applicationLog: { paa_log } }) => paa_log,
    );

    public readonly icapLog$ = this.select(
        ({ applicationLog: { icap_log } }) => icap_log,
    );

    public readonly wafApplicationRuleLogs$ = this.select(
        ({ applicationLog: { waf_log: wafLog } }): IWafRuleLog[] => wafLog?.application_rule_logs,
    );

    public readonly wafRuleLogs$ = this.select(
        ({ applicationLog: { waf_log: wafLog } }): IWafRuleLog[] => wafLog?.rule_logs,
    );

    public readonly botManagementLog$ = this.select(
        ({ applicationLog: { bot_management_log: botManagementLog } }) => botManagementLog,
    );

    // ****************************** Connection Log Selectors **********************************
    public readonly dnsClientInfo$ = this.select(
        ({ connectionLog: {
            client_location,
            report_timestamp,
            total_time,
            dns_qtype,
            protocol,
        } }) => {
            return {
                client_location,
                startTime: moment(report_timestamp).subtract(total_time, 'ms').toString(),
                endTime: report_timestamp,
                dns_qtype,
                protocol,
            };
        },
    );

    public readonly dnsLoadBalancerInfo$ = this.select(
        ({ connectionLog: {
            gslbservice,
            gslbpool_name,
            dns_response,
        } }) => {
            let recursionAvailable: boolean;

            if (dns_response) {
                ({ recursion_available: recursionAvailable } = dns_response);
            }

            return {
                gslbservice,
                gslbpool_name,
                recursion_available: recursionAvailable,
            };
        },
    );

    public readonly dnsRequestInfo$ = this.select(
        ({ connectionLog: { dns_request } }) => dns_request,
    );

    public readonly dnsResponseInfo$ = this.select(
        ({ connectionLog: { dns_response } }) => dns_response,
    );

    // ************************************ L4 Log Selectors **************************************

    public readonly l4ClientConnectionInfo$ = this.select<IL4ConnectionInfo>(
        ({ connectionLog: {
            tx_bytes: transmittedBytes,
            tx_pkts: transmittedPackets,
            rx_bytes: recievedBytes,
            rx_pkts: recievedPackets,
            total_bytes: totalBytes,
            total_pkts: totalPackets,
            out_of_orders: outOfOrders,
            timeouts,
            retransmits,
        } }) => {
            const l4ClientConnectionInfo = {
                transmittedBytes,
                transmittedPackets,
                recievedBytes,
                recievedPackets,
                totalBytes,
                totalPackets,
                outOfOrders,
                timeouts,
                retransmits,
            };

            if (isObjectEmpty(l4ClientConnectionInfo)) {
                return null;
            }

            return l4ClientConnectionInfo;
        },
    );

    public readonly l4ServerConnectionInfo$ = this.select<IL4ConnectionInfo>(
        ({ connectionLog: {
            server_tx_bytes: transmittedBytes,
            server_tx_pkts: transmittedPackets,
            server_rx_bytes: recievedBytes,
            server_rx_pkts: recievedPackets,
            server_total_bytes: totalBytes,
            server_total_pkts: totalPackets,
            server_out_of_orders: outOfOrders,
            server_timeouts: timeouts,
            server_retransmits: retransmits,
        } }) => {
            const l4ServerConnectionInfo = {
                transmittedBytes,
                transmittedPackets,
                recievedBytes,
                recievedPackets,
                totalBytes,
                totalPackets,
                outOfOrders,
                timeouts,
                retransmits,
            };

            if (isObjectEmpty(l4ServerConnectionInfo)) {
                return null;
            }

            return l4ServerConnectionInfo;
        },
    );

    public readonly l4ClientInfo$ = this.select(
        ({ connectionLog: {
            client_location,
            report_timestamp,
            start_timestamp,
        } }) => {
            return {
                client_location,
                endTime: report_timestamp,
                startTime: start_timestamp,
            };
        },
    );

    // *************************************** Effects ****************************************

    /**
     * Request single log entry from API and update the state accordingly.
     */
    public readonly getVsLog: (logEntryParams: TLogEntrySignatureParams) => Subscription;

    /**
     * Subject to close the cinematic view.
     */
    public readonly closeCinematic$ = new Subject<void>();

    // *************************************** Updaters ****************************************

    public readonly setErrors = this.updater((state, errors: string | object) => ({
        ...state,
        errors,
    }));

    public readonly setErrorFlag = this.updater((state, hasError: boolean) => ({
        ...state,
        hasError,
    }));

    private readonly setApplicationLog = this.updater((state, applicationLog: IApplicationLog) => ({
        ...state,
        applicationLog,
    }));

    private readonly setConnectionLog = this.updater((state, connectionLog: IApplicationLog) => ({
        ...state,
        connectionLog,
    }));

    private readonly setLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading,
    }));

    /**
     * Update state with the log returned from the API, depending on the type of log.
     */
    private readonly setLogBasedOnType = this.effect<TVsLog>(
        log$ => log$.pipe(
            withLatestFrom(this.vsLogsStore.vsLogsType$),
            tap(([log, vsLogsType]: [TVsLog, VsLogsType]) => {
                switch (vsLogsType) {
                    case VsLogsType.APPLICATION:
                        this.setApplicationLog(log as IApplicationLog);
                        break;

                    case VsLogsType.CONNECTION:
                        this.setConnectionLog(log as IConnectionLog);
                        break;
                }
            }),
        ),
    );

    // ************************************* Constructor *************************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        private readonly devLoggerService: DevLoggerService,
        vsLogsEffectService: VsLogsEffectsService,
    ) {
        super(initialState);

        // Initialize request effect
        const vsLogRequestEffect = this.effect<TVsLogStateParams>(
            vsLogsEffectService.createVsLogsRequestEffect(
                VS_LOG_ENTRY_REQUEST_ID,
                this.startLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiComplete,
                this.endingCondition,
            ),
        );

        this.getVsLog = this.effect<TLogEntrySignatureParams>(
            logEntryParams$ => logEntryParams$.pipe(
                withLatestFrom(this.vsLogsStore.vsLogApiParams$),
                tap(([logEntryParams, params]: [TLogEntrySignatureParams, TVsLogStateParams]) => {
                    const vsLogApiParams: TVsLogStateParams = {
                        ...params,
                        filters: convertLogEntryParamsToQueryStrings(logEntryParams),
                    };

                    vsLogRequestEffect(vsLogApiParams);
                }),
            ),
        );
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeCinematic$.complete();
    }

    // ************************************* Methods *************************************

    /**
     * Close the cinematic view.
     */
    public closeCinematic(): void {
        this.closeCinematic$.next();
    }

    /**
     * Set isLoading to true upon initiating an API request.
     */
    private startLoading = (): void => {
        this.setErrors(null);
        this.setErrorFlag(false);
        this.setLoading(true);
    };

    /**
     * Handle data returned by API.
     */
    private handleApiData = (data: TVsLogEntryResponseData): void => {
        const log = data.results[0];

        this.setLogBasedOnType(log);
    };

    // TODO 146748 figure out 'type' of 'err', and action to take upon err
    /**
     * Handle API errors.
     */
    private handleApiError = (err: string | object): void => {
        this.setLoading(false);
        this.setErrorFlag(true);
        this.devLoggerService.error(err);
        this.setErrors(err);
    };

    /**
     * Set isLoading to false upon API request completion.
     */
    private handleApiComplete = (): void => {
        this.setLoading(false);
    };

    /**
     * End request loop when log is returned.
     *
     * This is necessary because backend will continue to search through all logs, even though
     * we are only looking for one log. Logs are never partially returned, so we can render
     * the log as soon as we have it.
     */
    private endingCondition = (data: TVsLogEntryResponseData): boolean => {
        return Boolean(data.results[0]);
    };
}
