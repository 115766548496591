/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { InfobloxSubnetConfigItem }
    from 'ajs/modules/ipam/factories/infoblox-subnet.config-item.factory';
import { IInfobloxUsableSubnetHash }
    from 'ajs/modules/ipam/factories/ipam-dns-infoblox-profile.config-item.factory';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { L10nService } from '@vmw/ngx-vip';
import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';
import * as l10n from './infoblox-usable-subnets-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for usable subnets.
 * *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'infoblox-usable-subnets-grid',
    templateUrl: './infoblox-usable-subnets-grid.component.html',
})
export class InfobloxUsableSubnetsGridComponent implements OnInit, OnChanges, AfterViewInit {
    /**
     * Binding for ipamDnsInfobloxProfile::usable_alloc_subnets.
     */
    @Input()
    public usableSubnets: RepeatedMessageItem<InfobloxSubnetConfigItem>;

    /**
     * True if credentials are connected.
     */
    @Input()
    public connected = false;

    /**
     * Hash of available subnets & types (v4/v6).
     */
    @Input()
    public availableSubnetHash: IInfobloxUsableSubnetHash;

    /**
     * Fires on new usable subnet add.
     */
    @Output()
    public onAddUsableSubnet: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on delete/removeAll of usable subnet.
     */
    @Output()
    public onDeleteUsableSubnet: EventEmitter<InfobloxSubnetConfigItem> = new EventEmitter();

    /**
     * Event Emitter to notify parent component to invalidate form in case
     * any usable subnet is empty.
     */
    @Output()
    public onUsableSubnetChange: EventEmitter<boolean> = new EventEmitter();

    /**
     * TemplateRef for IPV4 subnet dropdown.
     */
    @ViewChild('ipv4SubnetTemplateRef')
    public ipv4SubnetTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for IPV6 subnet dropdown.
     */
    @ViewChild('ipv6SubnetTemplateRef')
    public ipv6SubnetTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Usable Subnets Grid config.
     */
    public infobloxUsableSubnetsGridConfig: IAviDataGridConfig;

    /**
     * Prefix for IPV4 row (name & id).
     */
    public readonly ipv4UsableSubnetRowPrefix = 'ipv4_usable_subnet_';

    /**
     * Prefix for IPV6 row (name & id).
     */
    public readonly ipv6UsableSubnetRowPrefix = 'ipv6_usable_subnet_';

    /**
     * Ipv4 subnet dropdown options.
     */
    public ipv4SubnetOptions: IAviDropdownOption[] = [];

    /**
     * Ipv6 subnet dropdown options.
     */
    public ipv6SubnetOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setUsableSubnetDropdownOptions();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { availableSubnetHash } = changes;

        if (availableSubnetHash && !availableSubnetHash.firstChange) {
            this.setUsableSubnetDropdownOptions();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.infobloxUsableSubnetsGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleIpv4),
                id: 'subnet',
                templateRef: this.ipv4SubnetTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleIpv6),
                id: 'subnet6',
                templateRef: this.ipv6SubnetTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (usableSubnets: InfobloxSubnetConfigItem[]) => {
                    usableSubnets.forEach((usableSubnet: InfobloxSubnetConfigItem) => {
                        this.deleteUsableSubnet(usableSubnet);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (usableSubnet: InfobloxSubnetConfigItem) => {
                    this.deleteUsableSubnet(usableSubnet);
                },
            }],
        };
    }

    /**
     * Emits Add UsableSubnet event.
     */
    public addUsableSubnet(): void {
        this.onAddUsableSubnet.emit();
    }

    /**
     * Handle change in Usable Subnet Model value.
     * Emit the event to invalidate form in case both IPv4 and IPv6 fields are empty.
     */
    public handleUsableSubnetChange(): void {
        const { usableSubnets } = this;

        if (usableSubnets?.count) {
            const isUsableSubnetInvalid = usableSubnets.config.some(
                (usableAllocSubnet: InfobloxSubnetConfigItem) => {
                    const {
                        subnet,
                        subnet6,
                    } = usableAllocSubnet.config;

                    return !subnet.subnet && !subnet6.subnet;
                },
            );

            this.onUsableSubnetChange.emit(isUsableSubnetInvalid);
        } else {
            this.onUsableSubnetChange.emit(false);
        }
    }

    /**
     * Emits Delete UsableSubnet event.
     */
    private deleteUsableSubnet(usableSubnet: InfobloxSubnetConfigItem): void {
        this.onDeleteUsableSubnet.emit(usableSubnet);
    }

    /**
     * Sets subnet & subnet6 dropdown options.
     */
    private setUsableSubnetDropdownOptions(): void {
        if (!this.availableSubnetHash) {
            return;
        }

        const { v4 = [], v6 = [] } = this.availableSubnetHash;

        this.ipv4SubnetOptions = v4.map(ipAddrPrefix => {
            const label = getSubnetString(ipAddrPrefix);

            return createDropdownOption(label);
        });

        this.ipv6SubnetOptions = v6.map(ipAddrPrefix => {
            const label = getSubnetString(ipAddrPrefix);

            return createDropdownOption(label);
        });
    }
}
