/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './vs-log-cinematic-sub-section-dns-response.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';
import { DnsMessageType } from '../../vs-log-cinematic.types';
import './vs-log-cinematic-sub-section-dns-response.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the DNS Response sub-section in DNS section under VS Log cinematic
 *     view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-dns-response',
    templateUrl: './vs-log-cinematic-sub-section-dns-response.component.html',
})
export class VsLogCinematicSubSectionDnsResponseComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for the Domain Name field.
     */
    @ViewChild('domainNameFieldTemplateRef')
    public readonly domainNameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Type field.
     */
    @ViewChild('dnsRecordTypeFieldTemplateRef')
    public readonly dnsRecordTypeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Resolved Virtual Service Name field.
     */
    @ViewChild('resolvedVirtualServiceNameFieldTemplateRef')
    public readonly resolvedVirtualServiceNameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the expanded content of each resource record.
     */
    @ViewChild('expandedContentTemplateRef')
    public readonly expandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly dnsResponseInfo$ = this.vsLogCinematicStore.dnsResponseInfo$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Dns message type helps identifying the current component as dns response.
     */
    public readonly dnsMessageType = DnsMessageType.RESPONSE;

    /**
     * Hash from enum values to the corresponding translated labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('DnsRcode'),
        ...this.schemaService.getEnumValueLabelsHash('DnsOpcode'),
        ...this.schemaService.getEnumValueLabelsHash('DnsRecordType'),
    };

    public resourceRecordsGridConfig: IAviDataGridConfig;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.resourceRecordsGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.resourceRecordsGridConfig = {
            ...this.resourceRecordsGridConfig,
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.domainNameLabel),
                    id: 'domain-name',
                    templateRef: this.domainNameFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                    id: 'type',
                    templateRef: this.dnsRecordTypeFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.domainNameLabel),
                    id: 'resolved-vs-name',
                    templateRef: this.resolvedVirtualServiceNameFieldTemplateRef,
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
