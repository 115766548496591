/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import {
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import {
    Observable,
    Subject,
    Subscription,
} from 'rxjs';

import { DevLoggerService } from 'ng/modules/core';
import { VsLogsEffectsService } from '../../services/vs-logs-effects.service';

import {
    IVsLogsCombinedRequestUpdatableParams,
    IVsLogsDefaultSignpostData,
    IVsLogsEndToEndTimingSignpostData,
    IVsLogsGroupbySignificanceResultsData,
    IVsLogsGroupsSignpostData,
    IVsLogsSelectedSignpostConfig,
    IVsLogsSignpostUpdatableParams,
    IVsLogsUpdatableParams,
    IVsLogsWafLatencySignpostData,
    IVsLogsWafRuleGroupsSignpostData,
    TVsLogsCombinedRecursiveRequest,
    TVsLogsPageStateParams,
    TVsLogsSignpostApiResponseData,
    TVsLogsSignpostStoreData,
} from '../../vs-logs.types';

import { VsLogsStore } from '../../services/vs-logs.store';
import { transformSignpostData } from '../../utils/vs-logs-signpost.utils';

const VS_LOG_SIGNPOST_REQUEST_ID = 'VS_LOG_SIGNPOST_REQUEST';

/**
 * @param hasError - Inform when signpost has an error.
 * @param isTimedOut - Inform if one of the requests has timed out.
 */
type TErrorStates = {
    hasError: boolean;
    isTimedOut: boolean;
};

/**
 * @param vsLogsSignpostData - Response from backend transfomed to be utilised on UI for displaying.
 * @param selectedSignpostConfig - Selected signpost name and its respective config key for mapping.
 * @param selectedSignpostRequestIds - Selected signpost request ids, need it for
 *  cancelling ongoing request while closing signpost.
 * @param isLoading - Flag to indicate whether a request is in progress.
 * @param errorStates - state properties pertaining to errors and timeouts.
 */
interface IStateTypes {
    vsLogsSignpostData: TVsLogsSignpostStoreData;
    selectedSignpostConfig: IVsLogsSelectedSignpostConfig;
    selectedSignpostRequestIds: string[];
    isLoading: boolean;
    errorStates: TErrorStates;
}

/** Default state of the Signpost Store */
const initialState: IStateTypes = {
    vsLogsSignpostData: null,
    selectedSignpostConfig: null,
    selectedSignpostRequestIds: [],
    isLoading: false,
    errorStates: {
        hasError: false,
        isTimedOut: false,
    },
};

/**
 * @description State and Effect management for VsLogsSignpostComponent.
 * @author Suraj Kumar
 */
@Injectable()
export class VsLogsSignpostStore extends ComponentStore<IStateTypes> {
    // ********************************** Selectors **********************************

    public readonly vsLogsDefaultSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsDefaultSignpostData[],
    );

    public readonly vsLogsWafLatencySignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsWafLatencySignpostData,
    );

    public readonly vsLogsWafGroupsSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsWafRuleGroupsSignpostData[],
    );

    public readonly vsLogsGroupsSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsGroupsSignpostData[],
    );

    public readonly vsLogsSignificanceSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsGroupbySignificanceResultsData[],
    );

    public readonly vsLogsEndToEndTimingSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsEndToEndTimingSignpostData,
    );

    public readonly hasNoData$ = this.select(
        state => {
            // Signpost data after transformation can be either object or array.
            const hasNoData = state.vsLogsSignpostData ?
                !Object.keys(state.vsLogsSignpostData).length : true;

            return hasNoData && !state.isLoading;
        },
    );

    public readonly isLoading$ = this.select(state => state.isLoading);

    public readonly hasError$ = this.select(state => state.errorStates.hasError);

    public readonly isTimedOut$ = this.select(state => state.errorStates.isTimedOut);

    public readonly vsLogSelectedSignpostConfigKey$ = this.select(
        state => state.selectedSignpostConfig?.configKey,
    );

    public readonly selectedSignpostRequestIds$ = this.select(
        state => state.selectedSignpostRequestIds,
    );

    public readonly vsLogSelectedSignpost$ = this.select(state => state.selectedSignpostConfig);

    /**
     * Selector for circumstances under which to show the empty data message.
     */
    public readonly showEmptyDataMessage$ = this.select(
        this.hasError$,
        this.hasNoData$,
        (hasError, hasNoData) => {
            return hasNoData && !hasError;
        },
    );

    /**
     * Selector for circumstances under which to show the error message.
     */
    public readonly showErrorMessage$ = this.select(
        this.hasError$,
        this.isLoading$,
        (hasError, isLoading) => {
            return hasError && !isLoading;
        },
    );

    /**
     * Selector for circumstances under which to show the timeout message.
     */
    public readonly showTimeoutMessage$ = this.select(
        this.isTimedOut$,
        this.vsLogsStore.showTimeoutMessage$,
        this.isLoading$,
        this.hasError$,
        (isTimedOut, vsLogsPageShowTimeoutMessage, isLoading, hasError) => {
            return isTimedOut && !isLoading && !hasError && !vsLogsPageShowTimeoutMessage;
        },
    );

    // ********************************** Effects **********************************

    /**
     * Request selected signpost data and update the state accordingly.
     */
    public readonly getVsLogSignpostData: (
        signpostParams: IVsLogsSignpostUpdatableParams,
    ) => Subscription;

    /**
     * Request selected signpost data which depends on response from multiple requests and update
     * state accordingly.
     */
    public readonly getVsLogSignpostCombinedData: (
        signpostParams: IVsLogsCombinedRequestUpdatableParams[],
    ) => Subscription;

    /**
     * Subject to close the signpost.
     */
    public readonly closeSignpost$ = new Subject<void>();

    /**
     * Subject to reset placement of the signpost.
     */
    public readonly overlaySizeChangeSubject$ = new Subject<void>();

    // ********************************** Updaters **********************************

    public readonly setVsLogSignpostGroupby = this.updater(
        (state, selectedSignpostConfig: IVsLogsSelectedSignpostConfig) => ({
            ...state,
            selectedSignpostConfig,
        }),
    );

    public readonly setVsLogSelectedSignpostRequestIds = this.updater(
        (state, selectedSignpostRequestIds: string[]) => ({
            ...state,
            selectedSignpostRequestIds,
        }),
    );

    private readonly setDataIsLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading,
    }));

    private readonly setHasError = this.updater((state, hasError: boolean) => ({
        ...state,
        errorStates: {
            ...state.errorStates,
            hasError,
        },
    }));

    private readonly setSignpostData = this.updater((
        state,
        vsLogsSignpostData: TVsLogsSignpostStoreData,
    ) => ({
        ...state,
        vsLogsSignpostData,
    }));

    private readonly setIsTimedOut = this.updater((state, isTimedOut: boolean) => ({
        ...state,
        errorStates: {
            ...state.errorStates,
            isTimedOut,
        },
    }));

    // ********************************** Constructor **********************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        vsLogsEffectsService: VsLogsEffectsService,
        private readonly devLoggerService: DevLoggerService,
    ) {
        super(initialState);

        // Initialize request effect
        const requestVsLogSignpostDataEffect =
            vsLogsEffectsService.createVsLogsRecursiveRequestEffect(
                this,
                this.startLoading,
                this.stopLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiLoopComplete,
            );

        this.getVsLogSignpostData = this.effect<IVsLogsSignpostUpdatableParams>(
            signpostParams$ => signpostParams$.pipe(
                withLatestFrom(this.vsLogsStore.vsLogsPageParams$),
                tap(([signpostParams, logsPageparams]:
                [IVsLogsSignpostUpdatableParams, TVsLogsPageStateParams]) => {
                    const apiParams: IVsLogsUpdatableParams = {
                        ...logsPageparams,
                        ...signpostParams,
                    };

                    this.setVsLogSelectedSignpostRequestIds([VS_LOG_SIGNPOST_REQUEST_ID]);

                    requestVsLogSignpostDataEffect({
                        apiParams,
                        config: {
                            requestId: VS_LOG_SIGNPOST_REQUEST_ID,
                        },
                    });
                }),
            ),
        );

        // Initialize combined request effect
        const requestVsLogSignpostCombinedDataEffect =
            vsLogsEffectsService.createVsLogsCombinedRecursiveRequestEffect(
                this,
                this.startLoading,
                this.stopLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiLoopComplete,
            );

        this.getVsLogSignpostCombinedData = this.effect<IVsLogsCombinedRequestUpdatableParams[]>(
            signpostParams$ => signpostParams$.pipe(
                withLatestFrom(this.vsLogsStore.vsLogsPageParams$),
                tap(([signpostParams, logsPageparams]:
                [IVsLogsCombinedRequestUpdatableParams[], TVsLogsPageStateParams]) => {
                    const requestIds: string[] = [];
                    const requests = signpostParams.map(
                        (signpostParam: IVsLogsCombinedRequestUpdatableParams) => {
                            requestIds.push(signpostParam.requestId);

                            return {
                                requestId: signpostParam.requestId,
                                requestParams: {
                                    ...logsPageparams,
                                    ...signpostParam.requestParams,
                                },
                            };
                        },
                    );

                    this.setVsLogSelectedSignpostRequestIds(requestIds);

                    const combinedRequest: TVsLogsCombinedRecursiveRequest = {
                        requests,
                    };

                    requestVsLogSignpostCombinedDataEffect(combinedRequest);
                }),
            ),
        );
    }

    // ********************************** Methods **********************************

    /**
     * Set signpost state to default state.
     */
    public setDefaultState(): void {
        this.setState(initialState);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeSignpost$.complete();
    }

    /**
     * Close the signpost.
     */
    public closeSignpost(): void {
        this.closeSignpost$.next();
    }

    /**
     * Return an observable to inform subscriptions of a change in the data, will reset
     * placement of the signpost.
     */
    public get overlaySizeChange$(): Observable<void> {
        return this.overlaySizeChangeSubject$.asObservable();
    }

    /**
     * Set isLoading to true upon initiating API request.
     */
    private startLoading = (): void => {
        this.setDataIsLoading(true);
        this.setHasError(false);
        this.setIsTimedOut(false);
    };

    /**
     * Set isLoading to false.
     */
    private stopLoading = (): void => {
        this.setDataIsLoading(false);
    };

    /**
     * Update state with results once data is returned from API.
     */
    private handleApiData = (data: TVsLogsSignpostApiResponseData): void => {
        this.setState(state => {
            const vsLogsSignpostData =
                transformSignpostData(data, state.selectedSignpostConfig?.configKey);

            this.overlaySizeChangeSubject$.next();

            return {
                ...state,
                vsLogsSignpostData,
            };
        });
    };

    /**
     * Handle API errors.
     */
    private handleApiError = (err: string): void => {
        this.devLoggerService.error(err);
        this.setDataIsLoading(false);
        this.setSignpostData(initialState.vsLogsSignpostData);
        this.setIsTimedOut(false);
        this.setHasError(true);
    };

    /**
     * Set isTimedOut when API is timed out.
     */
    private handleApiLoopComplete = (isTimedOut: boolean): void => {
        if (isTimedOut) {
            this.setIsTimedOut(true);
        }
    };
}
