/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'dns-resolver-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const useManagementCheckboxLabel = `${prefix}.useManagementCheckboxLabel`;
export const fixedTtlInputLabel = `${prefix}.fixedTtlInputLabel`;
export const fixedTtlInputPlaceholderText = `${prefix}.fixedTtlInputPlaceholderText`;
export const minimumTtlInputLabel = `${prefix}.minimumTtlInputLabel`;
export const minimumTtlInputPlaceholderText = `${prefix}.minimumTtlInputPlaceholderText`;
export const nameServerHeaderWithCount = `${prefix}.nameServerHeaderWithCount`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit DNS Resolver',
    [modalTitleNew]: 'New DNS Resolver',
    [useManagementCheckboxLabel]: 'Use Management',
    [fixedTtlInputLabel]: 'Fixed TTL',
    [fixedTtlInputPlaceholderText]: 'Enter Fixed TTL',
    [minimumTtlInputLabel]: 'Minimum TTL',
    [minimumTtlInputPlaceholderText]: 'Enter Minimum TTL',
    [nameServerHeaderWithCount]: 'Name Servers ({0})',
};
