/**
 * @module VirtualServiceModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';

import { L10nService } from '@vmw/ngx-vip';
import { VirtualServiceStateService } from '../../services/virtual-service-state.service';

import './dns-records.component.less';
import * as l10n from './dns-records.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description DNS Records component.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'vs-detail-dns-records',
    templateUrl: './dns-records.component.html',
})
export class DnsRecordsComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Vitual Service object resolve from transition.
     */
    public virtualService: any;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get global keys from source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * MetricDataCollection instance.
     */
    public metricDataCollection: any;

    /**
     * Queries collection items.
     */
    public totalQueriesItems: any;

    /**
     * DNSQueryCollection instance.
     */
    public dnsQueryCollection: any;

    /**
     * DataGrid config for the object list.
     */
    public fqdnListGridConfig: IAviCollectionDataGridConfig;

    public constructor(
        virtualServiceStateService: VirtualServiceStateService,
        private readonly l10nService: L10nService,
        @Inject('DNSQueryCollection')
        private readonly DNSQueryCollection: any,
        @Inject('MetricDataCollection')
        private readonly MetricDataCollection: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.virtualService = virtualServiceStateService.virtualService;

        // Subscribe to changes in the currently selected VS.
        this.virtualService.on('itemSaveSuccess', this.onVirtualServiceSave);
    }

    /**
     * @override
     */
    public async ngOnInit(): Promise<void> {
        this.dnsQueryCollection = new this.DNSQueryCollection({
            params: {
                uuid: this.virtualService.id,
                metric_id: [
                        'dns_client.avg_udp_queries',
                        'dns_client.avg_errored_queries',
                ].join(),
                obj_id: '*',
            },
        });
        this.setGridConfig();
        this.metricDataCollection = new this.MetricDataCollection({
            params: {
                uuid: this.virtualService.id,
                dimension_limit: 1000,
                metric_id: [
                        'dns_client.avg_complete_queries',
                        'dns_client.avg_errored_queries',
                ].join(),
            },
        });

        await this.metricDataCollection.load();

        this.totalQueriesItems = this.metricDataCollection.itemList;
        this.virtualService.load(['config'])
            .then(() => {
                this.virtualService.addLoad(['health', 'alert']);
            });
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.fqdnListGridConfig.fields = [
            {
                label: this.l10nService.getMessage(this.l10nKeys.columnTitleFqdn),
                id: 'name',
                transform: (metricData: any) => metricData.data?.name,
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.columnTitleUdpQueries),
                id: 'udp',
                transform: (metricData: any) => {
                    return this.getAbbreviatedValue(
                        metricData.data?.metrics['dns_client.avg_udp_queries'].data[0]?.value,
                        2,
                    );
                },
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.columnTitleErrors),
                id: 'errors',
                transform: (metricData: any) => {
                    return this.getAbbreviatedValue(
                        metricData.data?.metrics['dns_client.avg_errored_queries']
                            .data[0]?.value,
                    );
                },
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.columnTitleSource),
                id: 'source',
                transform: (metricData: any) => metricData.data?.source,
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.columnTitleService),
                id: 'service',
                transform: (metricData: any) => metricData.data?.service,
            },
            {
                label: this.l10nService.getMessage(this.globalL10nKeys.tenantLabel),
                id: 'tenant',
                transform: (metricData: any) => metricData.data?.tenant,
            },
        ];
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.metricDataCollection.destroy();
        this.dnsQueryCollection.destroy();
        this.virtualService.unbind('itemSaveSuccess', this.onVirtualServiceSave);
    }

    /**
     * Returns the parsed value to display in UDP queries and Errors.
     */
    public getAbbreviatedValue(num: number, prec = 0): string {
        const regExp = /\.0+$/;
        let str;
        let postfix = '';

        if (num > 9999) {
            if (num < 1000000) {
                postfix = 'K';
                str = (num / 1000).toFixed(prec);
            } else {
                postfix = 'M';
                str = (num / 1000000).toFixed(prec);
            }
        } else {
            str = num.toFixed(prec);
        }

        return str.replace(regExp, '') + postfix;
    }

    /**
     * Returns value of avg_complete_queries from metrics.
     */
    public getAverageCompletedQueries(): number {
        if (this.totalQueriesItems) {
            return this.totalQueriesItems[0]?.data?.metrics['dns_client.avg_complete_queries']
                .data[0]?.value || 0;
        }

        return 0;
    }

    /**
     * Returns value of avg_errored_queries from metrics.
     */
    public getAverageErroredQueries(): number {
        if (this.totalQueriesItems) {
            return this.totalQueriesItems[0]?.data?.metrics['dns_client.avg_errored_queries']
                .data[0]?.value || 0;
        }

        return 0;
    }

    /**
     * Sets the config for FQD list grid.
     */
    private setGridConfig(): void {
        this.fqdnListGridConfig = {
            layout: {
                disableCreate: true,
                hideCreate: true,
                hideDelete: true,
                hideEdit: true,
            },
            id: 'fqdn-list-grid',
            collection: this.dnsQueryCollection,
            fields: [],
        };
    }

    /**
     * Called on Virtual service save success.
     */
    private onVirtualServiceSave = (): void => {
        this.dnsQueryCollection.load();
    };
}
