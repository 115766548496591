/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './async-file-submit.less';

/**
 * @typedef {('INITIAL_STATE'|'UPLOADING_STATE'|'SUCCESS_STATE'|'ERROR_STATE')} AsyncFileSubmitState
 */

/**
 * @ngdoc component
 * @name  asyncFileSubmit
 * @param {Function} onFileLoad - Function called when the user has selected a local file, likely
 *     a network request. An object containing a 'file' property is passed in as an argument. Return
 *     a promise and error.
 * @param {number=} percentage - Percentage number to be shown in the progress bar view.
 * @param {boolean} skipSuccess - True to skip the success view after the request has completed, so
 *     that the user is brought back to the initial file upload view.
 * @param {boolean=} [decodeFile=true] - True for file to be decoded to string. False to keep File
 *     object pointing to its original source without decoding. Set to true by default.
 * @param {string=} [buttonLabel='Select From Computer'] - Custom text for the submit button.
 * @param {number=} maxFileSize - Max size of file being uploaded with unit of MB. Unlimited by
 *     default.
 * @param {string=} successMessage - Custom message showing when upload succeeds.
 * @param {string=} errorMessage - Custom message showing when upload fails.
 * @param {string=} progressMessage - Custom message showing when upload is in progress.
 * @description
 *
 *     Component used to load a file. The component displays four states:
 *         a) a button prompting the user to select a local file,
 *         b) a progress bar for the request, and
 *         c) an error message if a failure has occurred,
 *         d) a success message if upload succeeds.
 *     The percentage shown can be managed by the parent, or simulated if not passed in as a
 *     binding. The function passed into onFileLoad controls the state - a promise that resolves
 *     will show the success state, and a rejected promise will show the error state.
 *
 * @author Alex Malitsky, Zhiqian Liu
 */
const INITIAL_STATE = 'INITIAL_STATE';
const UPLOADING_STATE = 'UPLOADING_STATE';
const SUCCESS_STATE = 'SUCCESS_STATE';
const ERROR_STATE = 'ERROR_STATE';

class AsyncFileSubmitController {
    constructor(AsyncFileSubmitService) {
        this.asyncFileSubmitService_ = new AsyncFileSubmitService();
        this.state = INITIAL_STATE;
        this.error = '';
    }

    /** @override */
    $onInit() {
        /**
         * File object that represents the file to be upload.
         * @type {File|null}
         */
        this.file = null;

        /**
         * Message to be shown under progress bar.
         * @type {string}
         */
        this.progressMessage = this.progressMessage || 'Uploading file';

        /**
         * Message to be shown in success notification.
         * @type {string}
         */
        this.successMessage = this.successMessage || 'The file was successfully uploaded.';

        this.buttonText = this.buttonText ? this.buttonText : 'Select From Computer';
    }

    /**
     * Handler for after the file has been selected by the user, passing the file to
     * this.onFileLoad. If successful, the onSuccess function, if it exists, is called
     * and the user is shown either the initial state or the success notification. On failure,
     * the error notification will be shown and the onError function will be called.
     * @param {string} file - File as a string.
     * @return {ng.$q.promise}
     */
    handleSelectFile(file) {
        /**
         * @type {AsyncFileSubmitService}
         */
        this.asyncFileSubmitService_.startProgress();

        /**
         * @type {AsyncFileSubmitState}
         */
        this.state = UPLOADING_STATE;

        /**
         * @type {string}
         */
        this.error = '';

        return this.onFileLoad({ file })
            .then(() => {
                this.asyncFileSubmitService_.stopProgress();
                this.asyncFileSubmitService_.setPercentage(100);
                // to make progress bar stay for 2s right after jumping from 90% to 100% before
                // success notification shows up
                setTimeout(() => {
                    this.state = this.skipSuccess ? INITIAL_STATE : SUCCESS_STATE;
                }, 2000);
            })
            .catch(error => {
                this.asyncFileSubmitService_.stopProgress();
                this.state = ERROR_STATE;

                if (this.errorMessage) {
                    this.error = this.errorMessage;
                } else {
                    // for certain exceptions when error object does not have data object inside
                    // we show generic upload failed message.
                    this.error = error.data ? error.data.error : 'File upload failed.';
                }
            });
    }

    /**
     * Returns a percentage number to be used in the progress view.
     */
    getPercentage() {
        return angular.isNumber(this.percentage) ?
            this.percentage :
            this.asyncFileSubmitService_.percentage;
    }

    /**
     * Called when the close button of the error message has been clicked.
     */
    handleCloseNotification() {
        this.state = INITIAL_STATE;
        this.file = null;
        this.error = '';
    }

    $onDestroy() {
        this.asyncFileSubmitService_.reset();
    }
}

AsyncFileSubmitController.$inject = [
    'AsyncFileSubmitService',
];

angular.module('aviApp').component('asyncFileSubmit', {
    bindings: {
        onFileLoad: '&',
        percentage: '<?',
        skipSuccess: '<?',
        decodeFile: '<?',
        buttonLabel: '@?',
        maxFileSize: '@?',
        successMessage: '@?',
        errorMessage: '@?',
        progressMessage: '@?',
    },
    controller: AsyncFileSubmitController,
    template: require('./async-file-submit.partial.html'),
});
