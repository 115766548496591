/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing network related components.
 * @module NetworkModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VIPModule } from '@vmw/ngx-vip';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { RbacModule } from 'ng/modules/rbac/rbac.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    ConfiguredNetworkSubnetExpanderComponent,
    NetworkAndSubnetComponent,
    NetworkListExpanderComponent,
    NetworkListPageComponent,
    NetworkModalComponent,
    NetworkProfilesListPageComponent,
    NetworkSubnetModalComponent,
} from '.';

import {
    configuredNetworkCollectionProvider,
    configuredNetworkProvider,
    networkProfileCollectionProvider,
} from './ajs-upgraded-providers';

const networkComponents = [
    NetworkListExpanderComponent,
    ConfiguredNetworkSubnetExpanderComponent,
    NetworkAndSubnetComponent,
    NetworkListPageComponent,
    NetworkModalComponent,
    NetworkProfilesListPageComponent,
    NetworkSubnetModalComponent,
];

@NgModule({
    declarations: [
        ...networkComponents,
    ],
    imports: [
        AviFormsModule,
        DataGridModule,
        FormsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        CommonModule,
        SharedModule,
        VIPModule,
        RbacModule,
    ],
    exports: [
        ...networkComponents,
    ],
    providers: [
        configuredNetworkCollectionProvider,
        configuredNetworkProvider,
        networkProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NetworkModule { }
