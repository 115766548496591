/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    Case,
    CportalService,
} from 'ajs/modules/cportal';

import {
    AFFECTED_VERSION,
    ASSIGNEE,
    REPORTER,
    systemTagTypes,
    systemTypeLabels,
    TARGET_VERSION,
    USERS,
    VERSION,
} from 'ajs/modules/core/constants';

import {
    IAviDropdownOption,
} from 'ng/shared/components';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { pluck } from 'underscore';
import { IPromise } from 'angular';
import { ALBServiceMode } from 'generated-types';
import { createDropdownOption } from 'ng/shared/utils';
import { StringService } from 'ajs/modules/core/services/string-service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './case-custom-tags-grid.l10n';

import {
    IJiraMetaData,
    ITagRow,
    TSystemTagsMetaData,
} from './case-custom-tags-grid.types';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for case system and custom tags.
 *
 * @author Rohit Gaikwad, Rajawant Prajapati
 */
@Component({
    selector: 'case-custom-tags-grid',
    templateUrl: './case-custom-tags-grid.component.html',
})
export class CaseCustomTagsGridComponent implements AfterViewInit, OnInit {
    /**
     * Case Item.
     */
    @Input()
    public editable: Case;

    /**
     * Defines the portal modes.
     * PORTAL_MODES = SYSTEST is for system tags used for jira.
     * PORTAL_MODES = SALESFORCE is for custom tags used for salesforce.
     */
    @Input()
    public portalMode: ALBServiceMode;

    /**
     * If true, disable grid fields, Add Tag button and hide Remove button in rows of the grid.
     */
    @Input()
    public disableGridControls = false;

    /**
     * TemplateRef for system tag type field.
     */
    @ViewChild('systemTagTypeFieldTemplateRef')
    public systemTagTypeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for system tag value field.
     */
    @ViewChild('systemTagValueFieldTemplateRef')
    public systemTagValueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for custom tag type field.
     */
    @ViewChild('customTagTypeFieldTemplateRef')
    public customTagTypeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for custom tag value field.
     */
    @ViewChild('customTagValueFieldTemplateRef')
    public customTagValueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * System tags type dropdown options.
     */
    public systemTagsTypeDropdownOptions: IAviDropdownOption[] = [];

    /**
     * DataGrid config for System Tags.
     */
    public caseCustomTagsGridConfig: IAviDataGridConfig;

    /**
     * Hash of system tag value dropdown options with system tag type as key.
     */
    private systemTagsValueDropdownHash: Record<string, IAviDropdownOption[]> = {};

    /**
     * Stores the system/jira tag types.
     */
    private systemTagsMetaData: TSystemTagsMetaData;

    /**
     * List of system/jira tag keys.
     */
    private systemTypes: string[];

    constructor(
        private readonly stringService: StringService,
        private readonly cportalService: CportalService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.portalMode === ALBServiceMode.SYSTEST) {
            this.getMetadataForSystemTags();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, portalMode } = this;

        this.caseCustomTagsGridConfig = {
            fields: [],
        };

        if (portalMode === ALBServiceMode.SYSTEST) {
            this.caseCustomTagsGridConfig.fields.push(
                {
                    label: l10nService.getMessage(l10nKeys.typeLabel),
                    id: 'type',
                    templateRef: this.systemTagTypeFieldTemplateRef,
                }, {
                    label: l10nService.getMessage(l10nKeys.valueLabel),
                    id: 'value',
                    templateRef: this.systemTagValueFieldTemplateRef,
                },
            );
        } else if (portalMode === ALBServiceMode.SALESFORCE) {
            this.caseCustomTagsGridConfig.fields.push(
                {
                    label: l10nService.getMessage(l10nKeys.typeLabel),
                    id: 'type',
                    templateRef: this.customTagTypeFieldTemplateRef,
                }, {
                    label: l10nService.getMessage(l10nKeys.valueLabel),
                    id: 'value',
                    templateRef: this.customTagValueFieldTemplateRef,
                },
            );
        }

        if (!this.disableGridControls) {
            this.caseCustomTagsGridConfig.singleactions = [
                {
                    label: l10nService.getMessage(l10nKeys.removeActionLabel),
                    shape: 'trash',
                    onClick: (tag: ITagRow) => {
                        this.removeTagRow(tag);
                    },
                },
            ];
        }
    }

    /**
     * Add new tag row.
     */
    public addTagRow(): void {
        this.editable.addTagRow();
    }

    /**
     * Handle system and custom tag remove events.
     */
    public removeTagRow(row: ITagRow): void {
        const index = this.tagRows.indexOf(row);

        this.editable.removeTagRow(index);

        this.updateCustomTagConfigField();
    }

    /**
     * Handle on system tag type change.
     */
    public onSystemTagTypeChange(row: ITagRow): void {
        const { type } = row;

        row.value = '';

        this.updateCustomTagConfigField();

        this.createSystemTagValuesDropdown(type);
    }

    /**
     * Returns tag rows data.
     */
    public get tagRows(): ITagRow[] {
        const { tagRows } = this.editable.getConfig();

        return tagRows;
    }

    /**
     * Returns the count of custom tags.
     */
    public get customTagsCount(): number {
        return this.tagRows.length;
    }

    /**
     * Returns selected system tags types.
     * Used to hide the already selected system tags.
     */
    public get selectedSystemTags(): string[] {
        return pluck(this.tagRows, 'type');
    }

    /**
     * Returns System tag value dropdown options for selected system tag type.
     */
    public getSystemTagValueDropdownOptions(selectedTagType: string): IAviDropdownOption[] {
        return this.systemTagsValueDropdownHash[selectedTagType] || [];
    }

    /**
     * Update custom_tag field value.
     */
    public updateCustomTagConfigField(): void {
        this.editable.updateCustomTag();
    }

    /**
     * Get meta data for system/jira tag types.
     * Meta data is used to build the system tag types dropdown.
     * This meta data is required in case of SYSTEST portal mode.
     */
    private getMetadataForSystemTags(): IPromise<void> {
        return this.cportalService.getMetaData()
            .then((data: TSystemTagsMetaData) => {
                this.systemTagsMetaData = data;

                const metaDataKeys = Object.keys(this.systemTagsMetaData)
                    .filter((type: string) => type !== USERS && type !== VERSION);

                this.systemTypes = [
                    ...metaDataKeys,
                    ...systemTagTypes,
                ];

                this.createSystemTagsRows();
            });
    }

    /**
     * Create system tag type dropdown options.
     */
    private createSystemTagTypeDropdownOptions(): void {
        this.systemTagsTypeDropdownOptions = this.systemTypes
            .map((key: string) => {
                const dropdownLabel = systemTypeLabels[key] || key;

                return createDropdownOption(
                    key,
                    this.stringService.enumeration(dropdownLabel, ''),
                );
            });
    }

    /**
     * Create tag row data from tagRows array.
     */
    private createSystemTagsRows(): void {
        this.createSystemTagTypeDropdownOptions();

        this.tagRows.forEach((tagRow: ITagRow) => {
            const { type } = tagRow;

            this.createSystemTagValuesDropdown(type);
        });
    }

    /**
     * Set systemTagsValueDropdownHash with system tag value dropdown options
     * for given system tag type(users/version).
     */
    private setSystemTagsValueDropdownHashWithDropdownOptions(
        selectedTagType: string,
        tagTypeNameInMetaData: string = selectedTagType,
    ): void {
        this.systemTagsValueDropdownHash[selectedTagType] =
            Object.values(this.systemTagsMetaData[tagTypeNameInMetaData])
                .map(({ name, description }: IJiraMetaData) => createDropdownOption(
                    name,
                    name,
                    description || name,
                ));
    }

    /**
     * Create system tag values dropdown for provided system tag type.
     */
    private createSystemTagValuesDropdown(type: string): void {
        switch (type) {
            case ASSIGNEE:
            case REPORTER:
                this.setSystemTagsValueDropdownHashWithDropdownOptions(type, USERS);
                break;
            case AFFECTED_VERSION:
            case TARGET_VERSION:
                this.setSystemTagsValueDropdownHashWithDropdownOptions(type, VERSION);
                break;
            default:
                this.setSystemTagsValueDropdownHashWithDropdownOptions(type);
        }
    }
}
