/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NetworkModule
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import type {
    ConfiguredNetwork,
    IConfiguredNetworkSubnet,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    InfraCloudState,
} from 'ajs/modules/vrf-context/services/infra-cloud-state.service';

import {
    VRFContextCollection,
} from 'ajs/modules/vrf-context/factories/vrf-context.collection.factory';

import { Network } from 'object-types';
import * as globalL10n from 'global-l10n';
import { FullModalService } from 'ng/modules/core';
import { cloneDeep } from 'lodash';

import { pluck } from 'underscore';

import './network-modal.component.less';
import * as l10n from './network-modal.l10n';

import {
    NetworkSubnetModalComponent,
} from './network-subnet-modal/network-subnet-modal.component';

type TVRFContextCollection = typeof VRFContextCollection;

interface INetworkSubnetWithDiscovery extends IConfiguredNetworkSubnet {
    isDiscovered?: boolean,
}

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Network Modal Component.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'network-modal',
    templateUrl: './network-modal.component.html',
})
export class NetworkModalComponent implements OnInit {
    /**
     * Configured Network Item.
     */
    @Input()
    public editable: ConfiguredNetwork;

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = Network;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * VRFContextCollection instance.
     */
    public vrfContextCollection: VRFContextCollection;

    /**
     * Subnets Grid config.
     */
    public subnetsGridConfig: IAviDataGridConfig;

    /**
     * List to show or hide discovered subnets based on excludeDiscovered
     * flag value.
     */
    public filteredData: INetworkSubnetWithDiscovery[];

    /**
     * Boolean to check if Custom VRF context is set.
     */
    public hasCustomVrfContext = false;

    /**
     * Layout for Network Modal.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        private readonly l10nService: L10nService,
        private readonly fullModalService: FullModalService,
        @Inject(VRFContextCollection)
        VrfContextCollection: TVRFContextCollection,
        private readonly infraCloudState: InfraCloudState,
    ) {
        this.vrfContextCollection = new VrfContextCollection();
        l10nService.registerSourceBundles(dictionary);

        const cloud = this.infraCloudState.getCloud();
        const { id: cloudId } = cloud;

        this.vrfContextCollection.setParams({
            'cloud_ref.uuid': cloudId,
            fields: 'name,tenant_ref',
        });

        this.vrfContextCollection.load().then(() => {
            this.hasCustomVrfContext = this.vrfContextCollection.hasCustomVRFContext();
        });
    }

    /** @override */
    public ngOnInit(): void {
        this.subnetsGridConfig = {
            fields: [{
                label: this.l10nService.getMessage(l10nKeys.subnetPrefixColumnTitle),
                id: 'network_ref',
                transform: (network: INetworkSubnetWithDiscovery) => {
                    return network.subnet;
                },
            }, {
                label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                id: 'type',
                transform: (network: INetworkSubnetWithDiscovery) => {
                    return network.isDiscovered ?
                        this.l10nService.getMessage(l10nKeys.discoveredLabel) :
                        this.l10nService.getMessage(l10nKeys.configuredLabel);
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.ipAddressPoolColumnTitle),
                id: 'subnet6',
                transform: (network: INetworkSubnetWithDiscovery) => {
                    const { static_ipaddr_tmp: staticIpAddrTmp } = network;

                    return staticIpAddrTmp ? pluck(staticIpAddrTmp, 'range').join(', ') : '';
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (network: IConfiguredNetworkSubnet) => {
                    this.onEditSubnet(network);
                },
            }, {
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (network: IConfiguredNetworkSubnet) => {
                    const index = this.editable.configuredSubnets.indexOf(network);

                    this.editable.configuredSubnets.splice(index, 1);

                    this.filterData();
                },
                disabled: (network: INetworkSubnetWithDiscovery) => {
                    return network.isDiscovered;
                },
            }],
            multipleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (staticIpRanges: IConfiguredNetworkSubnet[]) => {
                    staticIpRanges.forEach((network: IConfiguredNetworkSubnet) => {
                        const index = this.editable.configuredSubnets.indexOf(network);

                        this.editable.configuredSubnets.splice(index, 1);
                    });
                    this.filterData();
                },
                disabled: (networks: INetworkSubnetWithDiscovery[]) => {
                    return networks.some(row => row.isDiscovered);
                },
            }],
        };

        this.filterData();
    }

    /**
     * Resulting list has to show or hide discovered subnets based on excludeDiscovered
     * flag value. Also if we have discovered and configured we will render configured only.
     */
    public filterData(): void {
        const list = this.editable.configuredSubnets.concat();

        if (!this.editable.data?.config?.exclude_discovered_subnets) {
            const configuredSubnetDict = {};

            this.editable.configuredSubnets?.forEach(({ subnet }) =>
                configuredSubnetDict[subnet] = true);

            // don't show discovered subnet if we have same subnet as configured
            const discoveredSubnetsWoConfigured = this.editable.data.discovery?.ip_subnet?.filter(
                ({ subnet }: {subnet: string}) => !(subnet in configuredSubnetDict),
            ) || [];

            discoveredSubnetsWoConfigured.forEach((subnet: INetworkSubnetWithDiscovery) =>
                subnet.isDiscovered = true);

            list.push(
                ...discoveredSubnetsWoConfigured,
            );
        }

        this.filteredData = list;
    }

    /**
     * Return number of configured subnets.
     */
    public get configuredSubnetsCount(): number {
        return this.editable.configuredSubnets.filter((subnet: INetworkSubnetWithDiscovery) => {
            return !subnet.isDiscovered;
        }).length;
    }

    /**
     * Returns number of discovered subnets.
     */
    public get discoveredSubnetsCount(): number {
        return this.editable.discoveredSubnets.filter((subnet: INetworkSubnetWithDiscovery) => {
            return subnet.isDiscovered;
        }).length;
    }

    /**
     * Add Subnet Event Handler.
     */
    public addSubnet(): void {
        this.fullModalService.addModal({
            component: NetworkSubnetModalComponent as Type<Component>,
            componentProps: {
                editable: {
                    asString: '',
                    static_ipaddr_tmp: [],
                    subnet: '',
                    useStaticIpForSeAndVip: true,
                },
                isEditing: false,
                onSubmit: (network: IConfiguredNetworkSubnet) => {
                    this.editable.config.configured_subnets.push(network);
                    this.fullModalService.removeModalByComponent(
                        NetworkSubnetModalComponent as Type<Component>,
                    );
                    this.filterData();
                },
                onSubnetModalDismiss: () => {
                    this.fullModalService.removeModalByComponent(
                        NetworkSubnetModalComponent as Type<Component>,
                    );
                },
            },
            getName: () => this.l10nService.getMessage(this.globalL10nKeys.subnetLabel),
            getDescription: () => '',
        });
    }

    /**
     * Method invoked on edit of Subnet.
     * Opens Subnet modal to Edit.
     */
    private onEditSubnet(network: INetworkSubnetWithDiscovery): void {
        const index = this.editable.configuredSubnets.indexOf(network);

        const { isDiscovered } = network;

        this.fullModalService.addModal({
            component: NetworkSubnetModalComponent as Type<Component>,
            componentProps: {
                editable: {
                    static_ipaddr_tmp: [],
                    ...cloneDeep(network),
                },
                isEditing: true,
                onSubmit: (updatedNetwork: INetworkSubnetWithDiscovery) => {
                    if (index >= 0) {
                        this.editable.configuredSubnets[index] = updatedNetwork;
                    } else if (index < 0 && isDiscovered) {
                        this.editable.config.configured_subnets.push(updatedNetwork);
                    }

                    this.fullModalService.removeModalByComponent(
                        NetworkSubnetModalComponent as Type<Component>,
                    );
                    this.filterData();
                },
                onSubnetModalDismiss: () => {
                    this.editable.configuredSubnets[index] = network;
                    this.fullModalService.removeModalByComponent(
                        NetworkSubnetModalComponent as Type<Component>,
                    );
                },
            },
            getName: () => this.l10nService.getMessage(this.globalL10nKeys.subnetLabel),
            getDescription: () => '',
        });
    }
}
