/**
 * @module InfrastructureModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { ICustomTag } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import './custom-tags.component.less';
import * as globalL10n from 'global-l10n';
import * as l10n from './custom-tags.component.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Custom Tags section.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'custom-tags',
    templateUrl: './custom-tags.component.html',
})
export class CustomTagsComponent implements AfterViewInit {
    /**
     * TemplateRef for Custom Tags Key field.
     */
    @ViewChild('keyTemplateRef')
    public keyTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for Custom Tags Value field.
     */
    @ViewChild('valueTemplateRef')
    public valueTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Custom Tags passed as an input from Component invoking this Component.
     */
    @Input()
    public customTags: RepeatedMessageItem<MessageItem<ICustomTag>>;

    @Input()
    public showOverlay = false;

    /**
     * Emits an event when user adds a custom tag message item from UI.
     */
    @Output()
    public onAddCustomTag = new EventEmitter<void>();

    /**
     * Emits an event when user removes a custom tag message item from UI.
     */
    @Output()
    public onRemoveCustomTag = new EventEmitter<MessageItem<ICustomTag>>();

    /**
     * DataGrid config for Tags.
     */
    public tagsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from gloabl source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Sets the Grid config.
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            keyTemplateRef,
            valueTemplateRef,
        } = this;

        this.tagsGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.keyLabel),
                    id: 'key',
                    templateRef: keyTemplateRef,
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.valueLabel),
                    id: 'value',
                    templateRef: valueTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: (customTags: Array<MessageItem<ICustomTag>>) => {
                    customTags.forEach((customTag: MessageItem<ICustomTag>) => {
                        this.removeCustomTag(customTag);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (customTag: MessageItem<ICustomTag>) => {
                    this.removeCustomTag(customTag);
                },
            }],
        };
    }

    /**
     * Triggered on click of Add button.
     */
    public addCustomTags(): void {
        const { onAddCustomTag } = this;

        onAddCustomTag.emit();
    }

    /**
     * Triggered when delete action is selected.
     */
    public removeCustomTag(customTag: MessageItem<ICustomTag>): void {
        const { onRemoveCustomTag } = this;

        onRemoveCustomTag.emit(customTag);
    }
}
