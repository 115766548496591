/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module IpamModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import { CustomIpamSubnetConfigItem }
    from 'ajs/modules/ipam/factories/custom-ipam-subnet.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './custom-usable-subnets-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Grid component for usable subnets.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'custom-usable-subnets-grid',
    templateUrl: './custom-usable-subnets-grid.component.html',
})
export class CustomUsableSubnetsGridComponent implements AfterViewInit {
    @Input()
    public usableSubnets: RepeatedMessageItem<CustomIpamSubnetConfigItem>;

    @Input()
    public connected = false;

    @Output()
    public readonly onAddUsableSubnet = new EventEmitter<void>();

    @Output()
    public readonly onTestLogin = new EventEmitter<void>();

    @Output()
    public readonly onDeleteUsableSubnet = new EventEmitter<CustomIpamSubnetConfigItem>();

    @Output()
    public readonly onEditUsableAllocSubnet = new EventEmitter<CustomIpamSubnetConfigItem>();

    public customUsableSubnetsGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.customUsableSubnetsGridConfig = {
            fields: [{
                label: l10nService.getMessage(globalL10nKeys.ipv4Label),
                id: 'subnet',
                transform: (usableAllocSubnets: CustomIpamSubnetConfigItem) => {
                    const { subnet: ipAddrPrefixConfigItem } = usableAllocSubnets.config;

                    return ipAddrPrefixConfigItem.isValid() ? ipAddrPrefixConfigItem.toString() :
                        '-';
                },
            }, {
                label: l10nService.getMessage(globalL10nKeys.ipv6Label),
                id: 'subnet6',
                transform: (usableAllocSubnets: CustomIpamSubnetConfigItem) => {
                    const { subnet6: ip6AddrPrefixConfigItem } = usableAllocSubnets.config;

                    return ip6AddrPrefixConfigItem.isValid() ? ip6AddrPrefixConfigItem.toString() :
                        '-';
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (usableSubnets: CustomIpamSubnetConfigItem[]) => {
                    usableSubnets.forEach((usableSubnet: CustomIpamSubnetConfigItem) => {
                        this.deleteUsableSubnet(usableSubnet);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (usableAllocSubnet: CustomIpamSubnetConfigItem) => {
                    this.editUsableAllocSubnet(usableAllocSubnet);
                },
            }, {
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (usableSubnet: CustomIpamSubnetConfigItem) => {
                    this.deleteUsableSubnet(usableSubnet);
                },
            }],
        };
    }

    /**
     * Emit Add UsableSubnet event.
     */
    public addUsableSubnet(): void {
        this.onAddUsableSubnet.emit();
    }

    /**
     * Emit test Login event.
     */
    public testLogin(): void {
        this.onTestLogin.emit();
    }

    /**
     * Emit Delete UsableSubnet event.
     */
    private deleteUsableSubnet(usableSubnet: CustomIpamSubnetConfigItem): void {
        this.onDeleteUsableSubnet.emit(usableSubnet);
    }

    /**
     * Emit Edit UsableSubnet event.
     */
    private editUsableAllocSubnet(usableSubnet: CustomIpamSubnetConfigItem): void {
        this.onEditUsableAllocSubnet.emit(usableSubnet);
    }
}
