/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsModule
 */

import { Inject } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    IOAuthResourceServer,
    IOAuthSettings,
    IOAuthVSConfig,
} from 'generated-types';

import { AUTH_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/security';
import { SSOPolicyTypes } from 'ajs/modules/sso-policy/factories';

import {
    SsoPolicyCollection,
} from 'ajs/modules/sso-policy/factories/sso-policy.collection.factory';

import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';

import {
    AuthProfileCollection,
} from 'ajs/modules/security/factories/auth-profile/auth-profile.collection.factory';

import './oauth-vs-config.component.less';

import {
    AuthProfile,
    JWTValidationParams,
    OAuthAppSettings,
    OAuthResourceServer,
    OAuthVSConfig,
    OpaqueTokenValidationParams,
    VirtualService,
} from 'object-types';

import * as globalL10n from 'global-l10n';
import * as l10n from './oauth-vs-config.l10n';
import template from './oauth-vs-config.component.html';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAuthProfileCollection = typeof AuthProfileCollection;
type TSsoPolicyCollection = typeof SsoPolicyCollection;

enum AccessTokenType {
    JWT = 'ACCESS_TOKEN_TYPE_JWT',
    OPAQUE = 'ACCESS_TOKEN_TYPE_OPAQUE',
}
/**
 * @desc Component for Oauth VS Config.
 */

class OauthVsConfigController {
    public readonly authProfileCollection: AuthProfileCollection;

    public readonly ssoPolicyCollection: SsoPolicyCollection;

    public oauthVsConfig: IOAuthVSConfig;

    public accessTokenType = AccessTokenType;

    public oauthSetting: IOAuthSettings;

    public resourceServer: IOAuthResourceServer;

    public scopes: string[];

    public readonly authProfileObjType = AuthProfile;

    public readonly virtualServiceObjType = VirtualService;

    public readonly oAuthAppSettingsObjType = OAuthAppSettings;

    public readonly oAuthResourceServerObjType = OAuthResourceServer;

    public readonly jWTValidationParamsObjType = JWTValidationParams;

    public readonly opaqueTokenValidationParams = OpaqueTokenValidationParams;

    public readonly oAuthVSConfigObjType = OAuthVSConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
    @Inject(AUTH_PROFILE_COLLECTION_TOKEN)
    AuthProfileCollection: TAuthProfileCollection,
    @Inject('SsoPolicyCollection')
    SsoPolicyCollection: TSsoPolicyCollection,
    l10nService: L10nService,
    ) {
        /**
         * @type {SsoPolicyCollection}
         */
        this.ssoPolicyCollection = new SsoPolicyCollection({
            params: {
                type: SSOPolicyTypes.OAUTH,
            },
            defaults: {
                type: SSOPolicyTypes.OAUTH,
            },
        });

        this.authProfileCollection = new AuthProfileCollection();
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Update app setting properties on OIDC change.
     */
    public handleOidcChange(): void {
        const { app_settings: appSettings } = this.oauthVsConfig.oauth_settings[0];

        appSettings.oidc_config.profile = false;
        appSettings.oidc_config.userinfo = false;

        if (appSettings?.oidc_config.oidc_enable) {
            appSettings.oidc_config.profile = true;
        }
    }

    /**
     * Add new scope to the oauthSettings object.
     */
    public addScope(): void {
        const { app_settings: appSettings } = this.oauthVsConfig.oauth_settings[0];

        appSettings.scopes.push('');
    }

    /**
     * Remove scope from the oauthSettings object.
     */
    public removeScope(index: number): void {
        this.oauthVsConfig.oauth_settings[0].app_settings.scopes.splice(index, 1);
    }

    /** @override */
    public $onInit(): void {
        this.oauthSetting = this.oauthVsConfig.oauth_settings[0];
        this.resourceServer = this.oauthSetting.resource_server;

        const { app_settings: appSettings } = this.oauthSetting;

        this.scopes = appSettings.scopes?.length ? appSettings.scopes : appSettings.scopes = [];
    }

    /** @override */
    public $onDestroy(): void {
        this.authProfileCollection.destroy();
        this.ssoPolicyCollection.destroy();
    }
}

OauthVsConfigController.$inject = [
    AUTH_PROFILE_COLLECTION_TOKEN,
    SSO_POLICY_COLLECTION_TOKEN,
    'l10nService',
];

export const OauthVsConfigOptions = {
    bindings: {
        authProfile: '=',
        oauthVsConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: OauthVsConfigController,
    template,
};
