/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NetworkModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    ConfiguredNetwork,
    ConfiguredNetworkCollection,
    NetworkProfile,
    NetworkProfileCollection,
    NetworkProfileUnionConfigItem,
    staticIpPoolConfigurationComponentOptions,
    SubnetListNetwork,
    SubnetListNetworkCollection,
    SUBNET_LIST_NETWORK_TOKEN,
    TCPFastPathProfileConfigItem,
    UDPFastPathProfileConfigItem,
} from '.';

import {
    NETWORK_PROFILE_COLLECTION_TOKEN,
    NETWORK_PROFILE_ITEM_TOKEN,
    NETWORK_PROFILE_UNION_CONFIG_ITEM_TOKEN,
    TCP_FAST_PATH_CONFIG_ITEM_TOKEN,
    UDP_FAST_PATH_CONFIG_ITEM_TOKEN,
} from './factories/network-profile/network-profile.tokens';

const networkModule = angular.module('avi/network');

const factories = [
    {
        name: 'ConfiguredNetwork',
        factory: ConfiguredNetwork,
    },
    {
        name: 'ConfiguredNetworkCollection',
        factory: ConfiguredNetworkCollection,
    },
    {
        factory: SubnetListNetworkCollection,
        name: 'SubnetListNetworkCollection',
    },
    {
        factory: NetworkProfile,
        name: NETWORK_PROFILE_ITEM_TOKEN,
    },
    {
        factory: NetworkProfileCollection,
        name: NETWORK_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: SubnetListNetwork,
        name: SUBNET_LIST_NETWORK_TOKEN,
    },
    {
        factory: TCPFastPathProfileConfigItem,
        name: TCP_FAST_PATH_CONFIG_ITEM_TOKEN,
    },
    {
        factory: UDPFastPathProfileConfigItem,
        name: UDP_FAST_PATH_CONFIG_ITEM_TOKEN,
    },
    {
        factory: NetworkProfileUnionConfigItem,
        name: NETWORK_PROFILE_UNION_CONFIG_ITEM_TOKEN,
    },
];

const components = [{
    name: 'staticIpPoolConfiguration',
    options: staticIpPoolConfigurationComponentOptions,
}];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        networkModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => networkModule.component(name, options));
