/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { SECRET_STUB_STR } from 'ng/modules/avi-forms/directives/password-input';
import { MessageItem, RepeatedMessageItem } from 'ajs/modules/data-model/factories';

import * as globalL10n from 'global-l10n';
import * as l10n from './custom-params-grid.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description
 *      Grid component for customParams instances with editable grid cells.
 *
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
@Component({
    selector: 'custom-params-grid',
    templateUrl: './custom-params-grid.component.html',
})
export class CustomParamsGridComponent {
    /**
     * List of CustomParams ConfigItems.
     */
    @Input()
    public paramsList: RepeatedMessageItem<MessageItem>;

    /**
     * Add button label.
     */
    @Input()
    public addButtonLabel?: string = 'Add';

    /**
     * If true, Name field will be editable else readonly.
     */
    @Input()
    public nameFieldEditable = true;

    /**
     * If true, Sensitive checkbox will be editable else disabled.
     */
    @Input()
    public sensitiveFieldEditable = true;

    /**
     * If true, Delete operation is enabled.
     */
    @Input()
    public allowDelete = true;

    /**
     * If true, Add new row is enabled.
     */
    @Input()
    public allowCreate = true;

    /**
     *  Grid Title to be passed to datagrid.
     */
    @Input()
    public gridTitle = '';

    /**
     * If true, sensitive checkbox column will be displayed.
     */
    @Input('hasSensitiveColumn')
    private set setSenstiveColumn(hasSensitiveColumn: boolean | '') {
        this.hasSensitiveColumn = hasSensitiveColumn === '' || Boolean(hasSensitiveColumn);
    }

    /**
     * If true, dynamic checkbox column will be displayed.
     */
    @Input('hasDynamicColumn')
    private set setDynamicColumn(hasDynamicColumn: boolean | '') {
        this.hasDynamicColumn = hasDynamicColumn === '' || Boolean(hasDynamicColumn);
    }

    /**
     * TemplateRef for name input field.
     */
    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value input field.
     */
    @ViewChild('valueFieldTemplateRef')
    public valueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for sensitive checkbox field.
     */
    @ViewChild('sensitiveFieldTemplateRef')
    public sensitiveFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for dynamic checkbox field.
     */
    @ViewChild('dynamicFieldTemplateRef')
    public dynamicFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * If true, dynamic checkbox column will be displayed.
     */
    public hasDynamicColumn?: boolean;

    /**
     * If true, sensitive checkbox column will be displayed.
     */
    public hasSensitiveColumn?: boolean;

    /**
     * Custom params grid config.
     */
    public customParamsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(ENGLISH);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.customParamsGridConfig = this.getCustomParamsGridConfig();
    }

    /**
     * Adds a custom paran.
     */
    public addCustomParam(): void {
        this.paramsList.add();
    }

    /**
     * Returns true if a value is sensitive.
     */
    public isSensitiveValue(value: string): boolean {
        return value === SECRET_STUB_STR;
    }

    /**
     * Returns custom params grid config.
     */
    private getCustomParamsGridConfig(): IAviDataGridConfig {
        const customParamsGridConfig: IAviDataGridConfig = {
            layout: {
                hideCheckboxes: !this.allowDelete,
            },
            fields: [],
        };

        if (this.allowDelete) {
            customParamsGridConfig.multipleactions = [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: () => this.paramsList.removeAll(),
            }];

            customParamsGridConfig.singleactions = [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (item: MessageItem) => {
                    this.paramsList.removeByMessageItem(item);
                },
            }];
        }

        if (this.nameFieldEditable) {
            customParamsGridConfig.fields.push({
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                id: 'name',
                templateRef: this.nameFieldTemplateRef,
            });
        } else {
            customParamsGridConfig.fields.push({
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                id: 'name',
                transform(param: MessageItem) {
                    return param.config.name;
                },
            });
        }

        customParamsGridConfig.fields.push({
            label: this.l10nService.getMessage(globalL10nKeys.valueLabel),
            id: 'value',
            templateRef: this.valueFieldTemplateRef,
        });

        if (this.hasSensitiveColumn) {
            customParamsGridConfig.fields.push({
                label: this.l10nService.getMessage(l10nKeys.columntitleSensitive),
                id: 'is_sensitive',
                templateRef: this.sensitiveFieldTemplateRef,
            });
        }

        if (this.hasDynamicColumn) {
            customParamsGridConfig.fields.push({
                label: this.l10nService.getMessage(l10nKeys.columntitleDynamic),
                id: 'is_dynamic',
                templateRef: this.dynamicFieldTemplateRef,
            });
        }

        return customParamsGridConfig;
    }
}
