/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    BackupCollection,
    BackupItem,
    Scheduler,
} from 'ajs/modules/system/factories';
import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';
import {
    AviDataGridFieldVisibility,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './backup-page.l10n';
import './backup-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TBackupCollection = typeof BackupCollection;

/**
 * @description Component for the Configuration Backup page
 * @author Alex Klyuev
 */
@Component({
    selector: 'backup-page',
    templateUrl: './backup-page.component.html',
})
export class BackupPageComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Default Scheduler item, resolved by UI Router.
     */
    @Input('resolveScheduler')
    public scheduler: Scheduler;

    /**
     * TemplateRef for local backup field.
     */
    @ViewChild('localBackupFieldTemplateRef')
    public localBackupFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * BackupCollection instance.
     */
    public backupCollection: BackupCollection;

    /**
     * Collection Data Grid config for backups.
     */
    public backupsGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(BackupCollection)
        private readonly BackupCollection: TBackupCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.backupCollection = new BackupCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { backupCollection } = this;
        const { objectName } = backupCollection;

        this.backupsGridConfig = {
            id: `${objectName}-list-page`,
            collection: backupCollection,
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    l10nKeys.backupsGridPlaceholderLabel,
                ),
                hideCreate: true,
                hideEdit: true,
                hideDelete: true,
                hideSearch: true,
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.backupsGridConfig = {
            ...this.backupsGridConfig,
            fields: [
                {
                    id: 'timestamp',
                    label: l10nService.getMessage(l10nKeys.columnTitleTimestamp),
                    transform(row: BackupItem): string {
                        return row.getConfig().timestamp;
                    },
                    visibility: AviDataGridFieldVisibility.MANDATORY,
                },
                {
                    id: 'local-file-url',
                    label: l10nService.getMessage(l10nKeys.columnTitleLocalFile),
                    templateRef: this.localBackupFieldTemplateRef,
                    visibility: AviDataGridFieldVisibility.MANDATORY,
                },
                {
                    id: 'remote-file-url',
                    label: l10nService.getMessage(l10nKeys.columnTitleRemoteFile),
                    transform(row: BackupItem): string {
                        return row.getConfig().remote_file_url ??
                            l10nService.getMessage(l10nKeys.emptyDataObject);
                    },
                    visibility: AviDataGridFieldVisibility.MANDATORY,
                },
            ],
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.backupCollection.destroy();
        this.scheduler.destroy();
    }
}
