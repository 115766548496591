/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    CREDENTIALS_TYPE,
    getTypeLabel,
} from 'ng/modules/cloud-connector-user';

import {
    TaskType,
    VerifyCloudConnectorUserHostModalService,
} from 'ng/modules/cloud/services/verify-cloud-connector-user-host-modal.service';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    CloudConnectorUser,
    CloudConnectorUserCollection,
} from 'ajs/modules/cloud-connector-user/factories';

import * as globalL10n from 'global-l10n';
import { DownloadFileService } from 'ng/modules/core';
import * as l10n from './user-credentials-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type CloudConnectorUserCollectionType = typeof CloudConnectorUserCollection;

/**
 * @description User Credential Page.
 *
 * @author Shahab Hashmi
 */
@Component({
    selector: 'user-credentials-list-page',
    templateUrl: './user-credentials-page.component.html',
})
export class UserCredentialsListPageComponent implements OnInit, OnDestroy {
    /**
     * User Credentials Page grid config.
     */
    public userCredentialsPageGridConfig: IAviCollectionDataGridConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * CloudConnectorUserCollection instance.
     */
    private readonly cloudConnectorUserCollection: CloudConnectorUserCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(CloudConnectorUserCollection)
        CloudConnectorUserCollection: CloudConnectorUserCollectionType,
        private readonly verifyCloudConnectorUserHostModalService
        : VerifyCloudConnectorUserHostModalService,
        private readonly downloadFileService: DownloadFileService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.cloudConnectorUserCollection = new CloudConnectorUserCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.cloudConnectorUserCollection;

        this.userCredentialsPageGridConfig = {
            id: `${objectName}-page`,
            collection: this.cloudConnectorUserCollection,
            fields: [{
                id: 'name',
                sortBy: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: CloudConnectorUser) => row.getName(),
            }, {
                id: 'type',
                label: this.l10nService.getMessage(l10nKeys.columnTitleCredentialType),
                transform: (row: CloudConnectorUser) => getTypeLabel(row.getType()),
            }],
            singleactions: [
                {
                    id: 'verify',
                    shape: 'details',
                    label: this.l10nService.getMessage(l10nKeys.verifyHostBtnLabel),
                    onClick: (row: CloudConnectorUser) => {
                        this.verifyCloudConnectorUserHostModalService.openModal(
                            TaskType.VERIFY, row.id,
                        );
                    },
                    hidden: (row: CloudConnectorUser) => row.hasCredentials(CREDENTIALS_TYPE.GCP),
                },
                {
                    id: 'download',
                    shape: 'download',
                    label: this.l10nService.getMessage(l10nKeys.downloadPublicKeyBtnLabel),
                    onClick: (row: CloudConnectorUser) => {
                        this.downloadFileService.downloadFile(
                            encodeURIComponent(row.getConfig().public_key),
                            `${row.getName()}_pub_key.pub`,
                            'application/x-pem-file',
                        );
                    },
                    hidden: (row: CloudConnectorUser) => !row.getConfig().public_key,
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudConnectorUserCollection.destroy();
    }
}
