/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid';
import * as globalL10n from 'global-l10n';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import { VsLogCinematicStore } from '../../vs-log-cinematic.store';
import * as l10n from './vs-log-cinematic-section-security-bot.l10n';
import './vs-log-cinematic-section-security-bot.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying Security's Bot sub-section in a VS log cinematic view.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'vs-log-cinematic-section-security-bot',
    templateUrl: 'vs-log-cinematic-section-security-bot.component.html',
})
export class VsLogCinematicSectionSecurityBotComponent {
    /**
     * Template ref for Bot management result notes.
     */
    @ViewChild('notesContentTemplateRef')
    public readonly notesContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for Bot management components.
     */
    @ViewChild('componentFieldTemplateRef')
    public readonly componentFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for Bot client type.
     */
    @ViewChild('botClientTypeFieldTemplateRef')
    public readonly botClientTypeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for Bot client class.
     */
    @ViewChild('botClientClassFieldTemplateRef')
    public readonly botClientClassFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for Bot confidence level.
     */
    @ViewChild('confidenceFieldTemplateRef')
    public readonly confidenceFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for Bot identifier.
     */
    @ViewChild('identifierFieldTemplateRef')
    public readonly identifierFieldTemplateRef: TemplateRef<HTMLElement>;

    public isBotGridShown = true;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly botManagementLog$ = this.vsLogCinematicStore.botManagementLog$;

    public securityBotGridConfig: IAviDataGridConfig;

    /**
     * Key-Value hash for enums and its corresponding labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('BotDecisionComponent'),
        ...this.schemaService.getEnumValueLabelsHash('BotConfidence'),
        ...this.schemaService.getEnumValueLabelsHash('BotClientClass'),
        ...this.schemaService.getEnumValueLabelsHash('BotClientType'),
        ...this.schemaService.getEnumValueLabelsHash('BotClassificationTypes'),
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        public readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngAfterViewInit(): void {
        this.securityBotGridConfig = {
            layout: {
                hideCheckboxes: true,
                hidePagination: true,
                showFooter: false,
            },
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.componentGridColumnLabel),
                    id: 'component',
                    templateRef: this.componentFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.botClientTypeLabel),
                    id: 'bot-client-type',
                    templateRef: this.botClientTypeFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.botClientClassGridColumnLabel),
                    id: 'bot-client-class',
                    templateRef: this.botClientClassFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.confidenceGridColumnLabel),
                    id: 'confidence',
                    templateRef: this.confidenceFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.identifierLabel),
                    id: 'identifier',
                    templateRef: this.identifierFieldTemplateRef,
                },
            ],
            expandedContentTemplateRef: this.notesContentTemplateRef,
        };
    }

    public trackByIndex(index: number): number {
        return index;
    }

    public toggleBotGridVisibility(): void {
        this.isBotGridShown = !this.isBotGridShown;
    }
}
