/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services';
import { AuthProfileCollection } from 'ajs/modules/security';

import {
    AuthProfileType,
    AuthSamlEntityType,
} from 'generated-types';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid';

import {
    AuthProfileVerifyLdapModalService,
    AuthProfileVerifySamlModalService,
} from '../../services';

import * as l10n from './auth-profile-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAuthProfileCollection = typeof AuthProfileCollection;

/**
 * @description Auth Profile's list page.
 *
 * @author Suraj Kumar
 */
@Component({
    selector: 'auth-profile-page',
    templateUrl: './auth-profile-page.component.html',
    providers: [
        AuthProfileVerifyLdapModalService,
        AuthProfileVerifySamlModalService,
    ],
})
export class AuthProfileListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the auth profile collection.
     */
    public authProfileCollection: AuthProfileCollection;

    /**
     * Configuration object to display auth profile collection.
     */
    public authProfilePageGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        private readonly authProfileVerifyLdapModalService: AuthProfileVerifyLdapModalService,
        private readonly authProfileVerifySamlModalService: AuthProfileVerifySamlModalService,
        public readonly schemaService: SchemaService,
        @Inject(AuthProfileCollection)
        private readonly AuthProfileCollection: TAuthProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.authProfileCollection = new AuthProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.authProfileCollection;

        this.authProfilePageGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.authProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                transform: row => row.getName(),
            }, {
                id: 'type',
                label: this.l10nService.getMessage(l10nKeys.columnTitleType),
                transform: row => this.schemaService.getEnumValueLabel(
                    'AuthProfileType',
                    row.data.config.type,
                ),
            }],
            singleactions: [{
                id: 'verify-auth-profile',
                label: this.l10nService.getMessage(l10nKeys.actionBtnVerify),
                shape: 'check',
                onClick: row => this.openVerifyModal(row),
                hidden: row => this.isVerifySingleActionHidden(row),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.placeholderMessage),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.authProfileCollection.destroy();
    }

    /**
     * Opens respective verify modal depending on type.
     */
    private openVerifyModal(row: IAviCollectionDataGridRow): void {
        const config = row.getConfig();

        switch (config.type) {
            case AuthProfileType.AUTH_PROFILE_SAML: {
                this.authProfileVerifySamlModalService.openModal(row.data.config);
                break;
            }

            case AuthProfileType.AUTH_PROFILE_LDAP: {
                this.authProfileVerifyLdapModalService.openModal(row.data.config);
                break;
            }
        }
    }

    /**
     * Checks if the verify singleaction is hidden or not.
     */
    private isVerifySingleActionHidden(row: IAviCollectionDataGridRow): boolean {
        const config = row.getConfig();
        let hidden = true;

        /**
         * Hide the verification icon for SAML profile
         * with entity type as User-provided-entity and LDAP profile
         */
        switch (config.type) {
            case AuthProfileType.AUTH_PROFILE_SAML: {
                const { sp } = config.saml.config;

                hidden = sp.config.saml_entity_type === AuthSamlEntityType.AUTH_SAML_APP_VS;
                break;
            }

            case AuthProfileType.AUTH_PROFILE_LDAP: {
                hidden = false;
                break;
            }
        }

        return hidden;
    }
}
