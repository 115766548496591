/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud-connector-user';
const componentName = 'nsxt-credentials';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const credentialsTypeFieldLabel = `${prefix}.credentialsTypeFieldLabel`;
export const sshCredentialsHeader = `${prefix}.sshCredentialsHeader`;
export const sshKeyFieldLabel = `${prefix}.sshKeyFieldLabel`;
export const keysFieldLabel = `${prefix}.keysFieldLabel`;
export const sshKeyValuePairFieldLabel = `${prefix}.sshKeyValuePairFieldLabel`;
export const importPrivateKeyFieldLabel = `${prefix}.importPrivateKeyFieldLabel`;
export const importPrivateKeyFieldTooltip = `${prefix}.importPrivateKeyFieldTooltip`;
export const publicKeyFieldLabel = `${prefix}.publicKeyFieldLabel`;
export const publicKeyFieldPlaceholder = `${prefix}.publicKeyFieldPlaceholder`;
export const privateKeyFieldLabel = `${prefix}.privateKeyFieldLabel`;
export const privateKeyPassphraseFieldLabel = `${prefix}.privateKeyPassphraseFieldLabel`;
export const passphrasePlaceholder = `${prefix}.passphrasePlaceholder`;
export const azureCredentialsHeader = `${prefix}.azureCredentialsHeader`;
export const usernamePasswordFieldLabel = `${prefix}.usernamePasswordFieldLabel`;
export const applicationIdFieldLabel = `${prefix}.applicationIdFieldLabel`;
export const activeDirectoryFieldLabel = `${prefix}.activeDirectoryFieldLabel`;
export const authenticationTokenFieldLabel = `${prefix}.authenticationTokenFieldLabel`;
export const tenantIdFieldLabel = `${prefix}.tenantIdFieldLabel`;
export const gcpCredentialsHeader = `${prefix}.gcpCredentialsHeader`;
export const nsxtCredentialsHeader = `${prefix}.nsxtCredentialsHeader`;
export const vcenterCredentialsHeader = `${prefix}.vcenterCredentialsHeader`;
export const generateAndSaveLabel = `${prefix}.generateAndSaveLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit User Credentials',
    [modalTitleNew]: 'New User Credentials',
    [credentialsTypeFieldLabel]: 'Credentials Type',
    [sshCredentialsHeader]: 'SSH Credentials',
    [sshKeyFieldLabel]: 'SSH Key',
    [keysFieldLabel]: 'Keys',
    [sshKeyValuePairFieldLabel]: 'Generate SSH Key Value Pair',
    [importPrivateKeyFieldLabel]: 'Import Private Key',
    [importPrivateKeyFieldTooltip]: 'Copy-paste your Private Key into the field below or load it from a file',
    [publicKeyFieldLabel]: 'Public Key',
    [publicKeyFieldPlaceholder]: 'Enter Public Key',
    [privateKeyFieldLabel]: 'Paste Private Key',
    [privateKeyPassphraseFieldLabel]: 'Private Key Passphrase',
    [passphrasePlaceholder]: 'Passphrase',
    [azureCredentialsHeader]: 'Azure Credentials',
    [usernamePasswordFieldLabel]: 'Username/Password',
    [applicationIdFieldLabel]: 'Application ID',
    [activeDirectoryFieldLabel]: 'Active Directory',
    [authenticationTokenFieldLabel]: 'Authentication Token',
    [tenantIdFieldLabel]: 'Tenant ID',
    [gcpCredentialsHeader]: 'GCP Credentials',
    [nsxtCredentialsHeader]: 'NSX Credentials',
    [vcenterCredentialsHeader]: 'vCenter Credentials',
    [generateAndSaveLabel]: 'Generate & Save',
};
