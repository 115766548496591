/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { CRLConfigItem } from 'ajs/modules/security/factories/pki-profile/crl.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './pki-profile-certificate-revocation-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Certificate Revocation List (CRL) Grid component for PKI Profile.
 *
 * @author Satish Pednekar
 */

@Component({
    selector: 'pki-profile-certificate-revocation-list-grid',
    templateUrl: './pki-profile-certificate-revocation-list-grid.component.html',
})
export class PkiProfileCertificateRevocationListGridComponent implements AfterViewInit {
    /**
     * CRL RepeatedMessageItem instance.
     */
    @Input()
    public pkiCertificateRevocationList: RepeatedMessageItem<CRLConfigItem>;

    /**
     * Fires on add Certificate Revocation List(CRL).
     */
    @Output()
    public onAddCertificateRevocationList = new EventEmitter();

    /**
     * Fires on remove Certificate Revocation List.
     */
    @Output()
    public onRemoveCertificateRevocationList = new EventEmitter<CRLConfigItem>();

    /**
     * PKI Certificate Revocation List grid config.
     */
    public pkiCertificateRevocationListGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.pkiCertificateRevocationListGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    transform(crl: CRLConfigItem) {
                        return crl.name || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.certRevocationListColTitleExpDate),
                    id: 'expiration_date',
                    transform(crl: CRLConfigItem) {
                        return crl.config.next_update || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.certRevocationListColTitleRefresh),
                    id: 'refresh',
                    transform(crl: CRLConfigItem) {
                        const { update_interval: updateInterval } = crl.config;

                        return updateInterval ?
                            l10nService.getMessage(l10nKeys.crlMinutesLabel, [updateInterval]) :
                            l10nService.getMessage(globalL10nKeys.emptyLabel);
                    },
                },

            ],
            multipleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (certificateRevocationList: CRLConfigItem[]) => {
                    certificateRevocationList
                        .forEach((certificateRevocationListItem: CRLConfigItem) => {
                            this.removeCertificateRevocationList(certificateRevocationListItem);
                        });
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (certificateRevocationListItem: CRLConfigItem) => {
                    this.removeCertificateRevocationList(certificateRevocationListItem);
                },
            }],
        };
    }

    /**
     * Handler for 'Add CRL' button.
     */
    public addCertificateRevocationList(): void {
        this.onAddCertificateRevocationList.emit();
    }

    /**
     * Handler for Certificate Revocation List remove operation.
     */
    private removeCertificateRevocationList(certificateRevocationListItem: CRLConfigItem): void {
        this.onRemoveCertificateRevocationList.emit(certificateRevocationListItem);
    }
}
