/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IDNSConfiguration,
    IIpAddr,
    INTPAuthenticationKey,
    INTPConfiguration,
    INTPServer,
    IpAddrType,
} from 'generated-types';

import {
    dnsName,
    ip,
    ipv6,
} from 'ng/utils/regex.utils';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { DNSConfiguration } from 'object-types';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';

import * as l10n from './system-settings-dns-ntp-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for DNS/NTP configuration in System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-dns-ntp-config',
    templateUrl: './system-settings-dns-ntp-config.component.html',
})
export class SystemSettingsDnsNtpConfigComponent implements OnInit, AfterViewInit {
    /**
     * Dns config.
     */
    @Input()
    public dnsConfig: IDNSConfiguration;

    /**
     * Ntp config.
     */
    @Input()
    public ntpConfig: INTPConfiguration;

    /**
     * Eventemitter for adding ntp authentication keys.
     */
    @Output()
    public onNtpAuthenticationKeyAdd = new EventEmitter<void>();

    /**
     * Eventemitter for adding ntp server keys.
     */
    @Output()
    public onNtpServerAdd = new EventEmitter<void>();

    /**
     * Template reference for ntpAuthKeyNumberTemplateRef.
     */
    @ViewChild('ntpAuthKeyNumberTemplateRef')
    public readonly ntpAuthKeyNumberTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template reference for algorithmTemplateRef.
     */
    @ViewChild('algorithmTemplateRef')
    public readonly algorithmTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template reference for keyTemplateRef.
     */
    @ViewChild('keyTemplateRef')
    public readonly keyTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template reference for ntpServersKeyNumberTemplateRef.
     */
    @ViewChild('ntpServersKeyNumberTemplateRef')
    public readonly ntpServersKeyNumberTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template reference for serversTemplateRef.
     */
    @ViewChild('serversTemplateRef')
    public readonly serversTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Ip addresses list for dns resolvers.
     */
    public dnsResolvers: string[];

    /**
     * Grid config for ntp authentication keys grid.
     */
    public ntpAuthenticationKeysConfig: IAviDataGridConfig;

    /**
     * Grid config for ntp servers grid.
     */
    public ntpServersConfig: IAviDataGridConfig;

    /**
     * Dropdown options list for all keynumbers added in NTP auth keys grid.
     */
    public allKeyNumbersDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * object type for template usage.
     */
    public readonly objectType = DNSConfiguration;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Checks if address passed is a IPV4, IPV6 or a hostname.
     */
    private static getIpAddrType(addr: string): IpAddrType {
        if (ip.test(addr)) {
            return IpAddrType.V4;
        } else if (ipv6.test(addr)) {
            return IpAddrType.V6;
        } else if (dnsName.test(addr)) {
            return IpAddrType.DNS;
        }
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.dnsResolvers = this.dnsConfig.server_list?.map(dnsResolver => dnsResolver.addr);
    }

    /**
     * @override
     * Setting NTP auth keys grid config and ntp servers grid config.
     */
    public ngAfterViewInit(): void {
        this.ntpAuthenticationKeysConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.keyNumberLabel),
                    id: 'ntp-auth-key-number',
                    templateRef: this.ntpAuthKeyNumberTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.l10nKeys.algorithmLabel),
                    id: 'algorithm',
                    templateRef: this.algorithmTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.keyLabel),
                    id: 'key',
                    templateRef: this.keyTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.removeLabel),
                    disabled: (rows: INTPAuthenticationKey[]): boolean => {
                        return rows.some(row => this.isAuthKeyBeingUsed(row));
                    },
                    onClick: (rows: INTPAuthenticationKey[]): void => {
                        rows.map(row => this.removeNtpAuthenticationKeys(row));
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.removeLabel),
                    shape: 'trash',
                    hidden: (row: INTPAuthenticationKey): boolean => {
                        return this.isAuthKeyBeingUsed(row);
                    },
                    onClick: (row: INTPAuthenticationKey) => {
                        this.removeNtpAuthenticationKeys(row);
                    },
                },
            ],
            layout: {
                hidePagination: true,
            },
        };

        this.ntpServersConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.keyNumberLabel),
                    id: 'ntp-servers-key-number',
                    templateRef: this.ntpServersKeyNumberTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.serversLabel),
                    id: 'server',
                    templateRef: this.serversTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.removeLabel),
                    onClick: (rows: INTPServer[]) => {
                        rows.map(row => this.removeNtpServers(row));
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (row: INTPServer) => {
                        this.removeNtpServers(row);
                    },
                },
            ],
            layout: {
                hidePagination: true,
            },
        };
    }

    /**
     * DNS servers list is updated as user updates DNS resolvers.
     */
    public onDnsResolversUpdate(dnsServerList: string[] = []): void {
        this.dnsConfig.server_list = dnsServerList.reduce(
            (dnsServerList: IIpAddr[], dnsServer: string) => {
                const dnsList = dnsServer.split(/[, ]+/);

                const serverList = dnsList.reduce((serverList: IIpAddr[], addr: string) => {
                    if (addr) {
                        const type = SystemSettingsDnsNtpConfigComponent.getIpAddrType(addr);

                        serverList.push({
                            addr,
                            type,
                        });
                    }

                    return serverList;
                }, []);

                return dnsServerList.concat(serverList);
            }, [],
        );

        this.dnsResolvers = this.dnsConfig.server_list.map(dnsResolver => dnsResolver.addr);
    }

    /**
     * NTP servers are updated as user updates servers in NTP servers grid.
     */
    public onNtpServersUpdate(addr: string, index: number): void {
        const type = SystemSettingsDnsNtpConfigComponent.getIpAddrType(addr);
        const { ntp_servers: ntpServers } = this.ntpConfig;

        ntpServers[index].server = {
            addr,
            type,
        };
    }

    /**
     * Emits while adding NTP authentication keys.
     */
    public addNtpAuthenticationKey(): void {
        this.onNtpAuthenticationKeyAdd.emit();
    }

    /**
     * Emits while adding NTP servers.
     */
    public addNtpServer(): void {
        this.onNtpServerAdd.emit();
    }

    /**
     * Sets dropdown options from all the keynumbers added in NTP auth keys grid.
     */
    public setAllKeyNumbersDropdownOptions(): void {
        const { ntp_authentication_keys: ntpAuthKeys } = this.ntpConfig;
        const processedKeys = new Set();

        this.allKeyNumbersDropdownOptions = ntpAuthKeys.reduce(
            (
                dropdownOptions: IAviDropdownOption[],
                authKey: INTPAuthenticationKey,
            ): IAviDropdownOption[] => {
                const { key_number: keyNumber } = authKey;

                if (keyNumber && !processedKeys.has(keyNumber)) {
                    dropdownOptions.push(createDropdownOption(keyNumber));
                    processedKeys.add(keyNumber);
                }

                return dropdownOptions;
            }, [],
        );
    }

    /**
     * Check if defined non-empty ntpauth key is being used.
     */
    private isAuthKeyBeingUsed(ntpAuthKey: INTPAuthenticationKey): boolean {
        const { ntp_servers: ntpServers } = this.ntpConfig;

        return ntpServers.some(
            ntpServer => ntpServer.key_number && ntpServer.key_number === ntpAuthKey.key_number,
        );
    }

    /**
     * Removes NTP servers from config.
     */
    private removeNtpServers(server: INTPServer): void {
        const { ntp_servers: ntpServers } = this.ntpConfig;
        const index = ntpServers.indexOf(server);

        ntpServers.splice(index, 1);
    }

    /**
     * Removes NTP auth keys from config.
     */
    private removeNtpAuthenticationKeys(authKey: INTPAuthenticationKey): void {
        const { ntp_authentication_keys: ntpAuthKeys } = this.ntpConfig;
        const index = ntpAuthKeys.indexOf(authKey);

        ntpAuthKeys.splice(index, 1);
    }
}
