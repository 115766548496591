/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './vs-log-cinematic-sub-section-paa.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';

import './vs-log-cinematic-sub-section-paa.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the PAA sub-section in the Authentication section under VS Log
 *     cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-paa',
    templateUrl: './vs-log-cinematic-sub-section-paa.component.html',
})
export class VsLogCinematicSubSectionPaaComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for the PAA log name field.
     */
    @ViewChild('paaLogFieldTemplateRef')
    public readonly paaLogFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the expanded content of each PAA request log.
     */
    @ViewChild('expandedContentTemplateRef')
    public readonly expandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly paaLog$ = this.vsLogCinematicStore.paaLog$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public paaRequestLogsGridConfig: IAviDataGridConfig;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.paaRequestLogsGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.paaRequestLogsGridConfig = {
            ...this.paaRequestLogsGridConfig,
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.paaLogLabel),
                    id: 'paa-log',
                    templateRef: this.paaLogFieldTemplateRef,
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
