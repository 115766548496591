/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid/components/avi-data-grid';
import * as globalL10n from 'global-l10n';

import * as l10n from './vs-log-cinematic-section-out-of-band.l10n';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import './vs-log-cinematic-section-out-of-band.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying the Out of Band section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-out-of-band',
    templateUrl: './vs-log-cinematic-section-out-of-band.component.html',
})
export class VsLogCinematicSectionOutOfBandComponent {
    @ViewChild('dsNameFieldTemplateRef')
    public dsNameFieldTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('vsDsEventFieldTemplateRef')
    public vsDsEventFieldTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public readonly oobDsRequestLogs$ = this.vsLogCinematicStore.oobDsRequestLogs$;

    public oobDsRequestLogGridConfig: IAviDataGridConfig;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            datascriptNameLabel,
            vsDatascriptEventLabel,
        } = this.l10nKeys;

        this.oobDsRequestLogGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(datascriptNameLabel),
                    id: 'name',
                    templateRef: this.dsNameFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(vsDatascriptEventLabel),
                    id: 'event',
                    templateRef: this.vsDsEventFieldTemplateRef,
                },
            ],
            layout: {
                hideCheckboxes: true,
                hideSearch: true,
            },
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
